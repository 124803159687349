<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-alert type="error" v-if="show_dueRenewal">{{ t('banner_dueRenewal') }}</v-alert>
			<v-alert type="info"  v-if="show_unreadNotes">{{ t('banner_unreadNotes') }}</v-alert>
			<v-alert type="info"  v-if="show_hasAnimalsToValidate">
				<v-row align="center">
					<v-col class="grow">{{ t('banner_hasAnimalsToValidate.title') }}</v-col>
					<v-col class="shrink"> <v-btn @click="dismissAnimalsToValidate()">{{ t('banner_hasAnimalsToValidate.btn') }}</v-btn> </v-col>
				</v-row>
				
			</v-alert>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="pk" disabled /> </v-col>
				<v-col cols="12"> <br-field-db :model="model" field="title" as="radioGroup" light/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="first_name" @change="on_firstName_blur" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="last_name" @change="on_lastName_blur" /> </v-col>
				<v-col cols="12">
					<v-row>
						<v-col cols="3"><br-field-db :model="model" field="civic_number" /></v-col>
						<v-col cols="3"><br-field-db :model="model" field="street"  readonly />      </v-col>
						<v-col cols="4"><br-field-db :model="model" field="municipality_street_fk" picker="municipalityStreetList" :pickerOptions="street_pickerOptions" /> </v-col>
						<v-col cols="2"><br-field-db :model="model" field="appt_number" /> </v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="municipality_fk" as="select" items="municipalityList" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="postal_code" v-facade="postalCode_mask" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="email_address" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="phone_1" v-facade="phone_mask" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="phone_2" v-facade="phone_mask" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="contact_method" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="identity_proof"  @change="on_identityProof_blur"  hint="Ex FFFPaammjj01"    persistent-hint /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="residency_proof" @change="on_residencyProof_blur" hint="Ex A9999-jjmmaa-01" persistent-hint /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="birth_date" /> </v-col>	
				
				<v-col cols="12">
					<v-expansion-panels v-model="openPanels" accordion>
						
						<!-- files -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.files.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.files">
							<br-field-file :model="model" field="files" @change="model_isNew?undefined:awaitUnsavedChangesSaved_filesOnly()" />
						</v-expansion-panel-content> </v-expansion-panel>
						
						<!-- notes -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.notes.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.notes">
							<notes-list :note-list-field="model.select('notes')" />
						</v-expansion-panel-content> </v-expansion-panel>
						
						<!-- invoices -->
						<br-generic-form-base-sub-model-list :form="self" route-names="citizen>invoice" :accordion-title="t('tabs.invoices.title')">
							<template #accordion-after-list>
								<v-card class="mt-2 mr-0 mb-4 ml-16" flat>
									<v-card-text>
										<v-row no-gutters>
											<v-col cols="6">{{ t("tabs.invoices.totalSold") }}</v-col><v-col cols="6" class="text-right">{{ $bREST.utils.number_format(calc_billed,2) }} $</v-col>
											<v-col cols="6">{{ t("tabs.invoices.totalPaid") }}</v-col><v-col cols="6" class="text-right">{{ $bREST.utils.number_format(calc_paid,2) }} $</v-col>
											<v-col cols="12"> <v-divider/> </v-col>
											<v-col cols="6">{{ t("tabs.invoices.totalOwed") }}</v-col><v-col cols="6" class="text-right">{{ $bREST.utils.number_format(calc_balance,2) }} $</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</template>
						</br-generic-form-base-sub-model-list>
						
						<!-- animals -->
						<br-generic-form-base-sub-model-list :form="self" route-names="citizen>animal" :accordion-title="t('tabs.animals.title')" />
						
						<!-- licenses -->
						<br-generic-form-base-sub-model-list :form="self" route-names="citizen>license" :accordion-title="t('tabs.licenses.title')" />
						
						<!-- contracts -->
						<br-generic-form-base-sub-model-list :form="self" route-names="citizen>contract" :accordion-title="t('tabs.contracts.title')" />
						
						<!-- interventions -->
						<br-generic-form-base-sub-model-list :form="self" route-names="citizen>intervention" :accordion-title="t('tabs.interventions.title')" />
						
						<!-- history -->
						<v-expansion-panel :disabled="model.isNew"> <v-expansion-panel-header>{{ t("tabs.history.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.history">
							<div class="font-italic text-caption">À venir après la présentation actuelle</div>
						</v-expansion-panel-content> </v-expansion-panel>
						
					</v-expansion-panels>
				</v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	
	//If when model is new-ed or done loading (existing pk), if we should also load the tabs content or defer until we open them for the 1st time
	const LOAD_ON_MOUNT_TABS = {
		files:   true,  //Because it's a subModelList, so will be loaded auto anyways (and can use even if isNew)
		notes:   true,  //Because it's a subModelList, so will be loaded auto anyways (and can use even if isNew)
		history: false, //Don't know yet, but should be disabled when isNew because we won't have any past action yet
	};
	
	
	
	
	
	
	export default {
		name: "citizenForm",
		components: {
			NotesList: () => import("@/custom/components/NotesList.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "Citizen",
			apiBaseUrl:         "/citizens/",
			showValidationErrs: true,
			showSkeletonLoader: true,
		  //autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()                               { },
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		data()
		{
			return {
				LOAD_ON_MOUNT_TABS,
				openPanels: [],
			};
		},
		computed: {
			show_dueRenewal()           { return this.model.select('calc_hasDueRenewal').val; },
			show_unreadNotes()          { return this.model.select('calc_hasUnreadNotes').val; },
			show_hasAnimalsToValidate() { return this.model.select('calc_hasAnimalsToValidate').val; },
			phone_mask()                { return '(###) ###-####'; },
			postalCode_mask()           { return 'A#A #A#'; },
			calc_billed()               { return this.model.select("calc_billed").val; },
			calc_balance()              { return this.model.select("calc_balance").val; },
			calc_paid()                 { return this.calc_billed-this.calc_balance; },
			street_pickerOptions()
			{
				return {
					onPrompt_setFilters: ({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions}) =>
					{
						picker_modelList_searchOptions.f_equalOrIN("municipality_fk",/*throwIfExists*/false).valOrArr = this.model.select("municipality_fk").val;
							//IMPORTANT: Won't work if the bound BrGenericList has a filter pointing on the same fieldNamePath; the picker's filter val in the UI will overwrite the above
							//Also, sometimes doesn't work on the 1st shot and we have to close the picker & reopen. Prolly a B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING bug
					},
				};
			},
		},
		methods: {
			// capitalize names on blur 
			on_firstName_blur() { this.capitalize_firstLetter('first_name'); },
			on_lastName_blur() { this.capitalize_firstLetter('last_name'); },
			capitalize_firstLetter(fieldName) { this.model.select(fieldName).val = this.$bREST.utils.string_ucFirst(this.model.select(fieldName).val) },
			/*
			Preuve d'identité, on assume carte assurance-maladie (RAMQ)
			on blur, utiliser le # d'assurance-maladie pour trouver la date de naissance
			https://fr.wikipedia.org/wiki/R%C3%A9gie_de_l'assurance_maladie_du_Qu%C3%A9bec#La_carte_d.27assurance_maladie
			ABCD 1234 5678, 12=> année de naissance, 34=> mois, 56=> jour, accepting spaces between sets of 4 characters
			*/
			on_identityProof_blur() { this._on_birthDateProofField_blur("identity_proof", /[A-Z]{4} ?(\d{2})(\d{2}) ?(\d{2})\d{2}/i, 1,2,3); },
			/*
			Preuve de résidence, on assume permis de conduire
			on blur, utiliser le # de permis pour trouver la date de naissance
			https://www.sonnet.ca/faq/soumission/bonne-facon-entrer-numero-permis-conduire#:~:text=ann%C3%A9e%20de%20naissance-,Qu%C3%A9bec,-Format%20du%20permis
			A####DDMMYY##
			*/
			on_residencyProof_blur() { this._on_birthDateProofField_blur("residency_proof", /[A-Z]\d{4}[- ]?(\d{2})(\d{2})(\d{2})[- ]?\d{2}/i, 3,2,1); },
				_on_birthDateProofField_blur(fieldName, regex, idx_year,idx_month,idx_day)
				{
					// Date de naissance doit être vide
					if (this.model.select('birth_date').isEmpty)
					{
						// Match the regex against the identity proof
						const birthDate = this.model.select(fieldName).val.match(regex);
						// If the regex matches the value entered as identity proof
						if (birthDate!==null)
						{
							// See regex for groupings
							let year  = parseInt(birthDate[idx_year]);
							let month = parseInt(birthDate[idx_month]);
							let day   = parseInt(birthDate[idx_day]);
							
							//Years are as 2 digits, so convert to 4
							const thisYear2Digits = new Date().getFullYear()-2000;
							year += year>thisYear2Digits ? 1900 : 2000;
							
							//For RAMQ, woman get +50 here
							if (month>50) { month-=50; }
							
							//Pad zeros
							if (month<10) {month=`0${month}`;}
							if (day<10)   {day=`0${day}`;}
							
							// Use this as the birthdate
							this.model.select('birth_date').val = this.$bREST.utils.dt_fromYmd(`${year}-${month}-${day}`);
						}
					}
				},
			dismissAnimalsToValidate()
			{
				this.model.select('calc_hasAnimalsToValidate').val = false;
				this.awaitUnsavedChangesSaved();
			},
		},
	};
	
</script>