<!--
	Usage ex in a User BrGenericForm der:
		Template:
			<v-col cols="12" md="6"> <br-field-db-recovery-email :model="model" /> </v-col>
			<v-col cols="12" md="6"> <br-field-db-confirmation :model="model" field="recoveryEmail" ref="recoveryEmailConfirmation" @change="recalcRecoveryEmailConfirmationValidation" /> </v-col>
			
			<v-col cols="12" md="6"> <br-field-db :model="model" field="pwd_dbHash" /> </v-col>
			<v-col cols="12" md="6"> <br-field-db-confirmation :model="model" field="pwd_dbHash" ref="pwdConfirmation" @change="recalcPwdConfirmationValidation" /> </v-col>
		Script:
			{
				mixins: B_REST_Vuetify_GenericForm.createMixin({
					async customValidator()
					{
						this.recalcRecoveryEmailConfirmationValidation();
						this.recalcPwdConfirmationValidation();
					},
				}),
				methods: {
					recalcRecoveryEmailConfirmationValidation() { this.customErrorList.fast_if(this.$refs.recoveryEmailConfirmation.checkShouldWarn(),"recoveryEmailMismatch"); },
					recalcPwdConfirmationValidation()           { this.customErrorList.fast_if(this.$refs.pwdConfirmation.checkShouldWarn(),          "pwdMismatch");           },
					//WARNING: $refs aren't reactive, so don't end up using this in some computed - use $emit instead
				},
			};
-->
<template>
	<br-field-db :field="confirm_field" @input="on_input" @change="on_change" :lazy="false" :pwd-strength-bar="false" :disabled="!confirm_field" />
</template>

<script>
	
	import { B_REST_Utils, B_REST_Model, B_REST_FieldDescriptors, B_REST_ModelFields } from "../../../../classes";
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	
	
	
	const COMPONENT_NAME         = "BrFieldDbConfirmation";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	const B_REST_FieldDescriptor_DB = B_REST_FieldDescriptors.DB;
	const B_REST_ModelField_DB      = B_REST_ModelFields.DB;
	
	
	
	export default {
		name: COMPONENT_NAME,
		//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			//Check BrFieldDb.vue docs for the same things. These are to indicate we want to make a confirmation for which field
			field: {type:undefined,    required:false, default:null, validator(val){ return val===null || val instanceof B_REST_ModelField_DB || B_REST_Utils.string_is(val); }}, //Either NULL, an instance of B_REST_ModelField_DB, or a fieldNamePath for model.select(<fieldNamePath>)
			model: {type:B_REST_Model, required:false, default:null},
			label: {type:String,       required:false, default:null},
		},
		data()
		{
			return {
				src_field:      null,  //Check BrFieldDb.vue docs for final_field. The field we want to make a confirmation of
				confirm_field:  null,  //A B_REST_ModelField_DB instance, being a copy of src_field
				hasEverBlurred: false, //For on_input()
				onChange_hook: (confirm_field,newVal,oldVal) => //For _checkReinit_field()
				{
					
					//If we decide to clear the src field, also clear the confirmation, otherwise it's weird
					if (this.src_field.isEmpty) { this.confirm_field.unSet(); }
					
					this._recalcValidation(/*emitChanged*/this.hasEverBlurred);
				},
			};
		},
		watch: {
			//Check BrFieldDb.vue docs for the same thing
			field() { this._checkReinit_field(); },
			model() { this._checkReinit_field(); },
			"src_field.userTouch_has"(newVal,oldVal)
			{
				//When we save pwd, userTouch will become false, so we should also revert for the confirmation field as well
				if (!newVal) { this.confirm_field.userTouch_toggle(false); }
			},
		},
		beforeMount()   { this._checkReinit_field();                   }, //Check BrFieldDb.vue docs for the same thing
		beforeDestroy() { if(this.confirm_field){this._unbindField();} },
		computed: {
			//Check BrFieldDb.vue docs for final_field_x computeds
				src_field_descriptor() { return this.src_field?.fieldDescriptor;                             },
				src_field_name()       { return this.src_field_descriptor?.name || "[noname]";               },
				src_field_quotedName() { return `<br-field-db-confirmation field="${this.src_field_name}">`; },
			//Custom stuff
				//Doesn't take into account if it's filled / blurred or not
				isValid() { return this.confirm_field?.validation_isValid; },
				//Sometimes the field isn't valid but we don't care about it. Here we say we should do something about it when the src field IS valid and has unsaved changes
				shouldWarn() { return this.src_field.unsavedChanges_has && this.src_field.isNotEmpty_andValid && !this.isValid; },
		},
		methods: {
			//Check BrFieldDb.vue docs for the same thing
			_throwField(msg) { B_REST_Utils.throwEx(`${this.src_field_quotedName}: ${msg}`); },
			//Check BrFieldDb.vue docs for the same thing, except we changed final_field_x to src_field_x
			_checkReinit_field()
			{
				if (this.confirm_field) { this._unbindField(); }
				
				let src_field = null;
				
				if (this.field)
				{
					if (this.field instanceof B_REST_ModelField_DB)
					{
						if (this.model) { this.src_field=src_field; this._throwField(`Not supposed to pass the B_REST_Model itself, when we already know the field as a B_REST_ModelField_DB instance`); }
						
						src_field = this.field;
					}
					else if (this.model)
					{
						src_field = this.model.select(this.field);
						
						if (!src_field && B_REST_Utils.string_is(this.field)) { this._throwField(`BrModelField got no field / field "${this.field}" not found on that model`); }
						if (!(src_field instanceof B_REST_ModelField_DB)) { this.src_field=src_field; this._throwField(`BrModelField can only work with B_REST_ModelField_DB type fields`); }
					}
				}
				
				this.src_field = src_field;
				
				if (this.src_field)
				{
					const src_field_descriptor = this.src_field_descriptor;
					
					const loc = B_REST_Utils.object_copy(src_field_descriptor.loc, true);
					if (this.label)
					{
						loc.label = loc.shortLabel = this.label;
					}
					else
					{
						if (B_REST_Utils.object_hasPropName(loc,"label"))      { loc.label     +=` - ${this.t_alt("labelSuffix")}`; }
						if (B_REST_Utils.object_hasPropName(loc,"shortLabel")) { loc.shortLabel+=` - ${this.t_alt("labelSuffix")}`; }
					}
					
					const confirm_fieldName = `${src_field_descriptor.name}_confirmation`;
					
					const confirm_isRequired = src_field_descriptor.isRequired || this.src_field.forceIsRequired;
					const confirm_fieldDescriptorOptions = {
						isRequired:    confirm_isRequired,
						isNullable:    src_field_descriptor.isNullable && !confirm_isRequired,
						wCustomSetter: src_field_descriptor.wCustomSetter,
						setOnce:       src_field_descriptor.setOnce,
						optionalVal:   src_field_descriptor.optionalVal,
						enum_members:  src_field_descriptor.enum_members,
						min:           src_field_descriptor.min,
						max:           src_field_descriptor.max,
						decimals:      src_field_descriptor.decimals,
						isPKField:     false,
						loc,
					};
					if (src_field_descriptor.type_is_pwd) { confirm_fieldDescriptorOptions.pwd_evalStrength = false; }
					if (src_field_descriptor.lookup_is)   { confirm_fieldDescriptorOptions.lookupInfo       = {modelName:src_field_descriptor.lookup_modelName,fieldName:src_field_descriptor.lookup_fieldName}; }
					
					this.confirm_field = B_REST_ModelFields.DB.createStandalone_x(confirm_fieldName, src_field_descriptor.type, confirm_fieldDescriptorOptions);
					this.confirm_field.validation_custom_errorList.baseLocPath = this.src_field.validation_custom_errorList.baseLocPath;
					/*
					IMPORTANT:
						Don't call _recalcValidation() here, as we want to leave field "ok" until we start messing w it for the first time.
						If we really want to see a possible err appear, just call checkShouldWarn() from outside
					*/
					
					this.src_field.onChange_hooks_add(this.onChange_hook);
				}
				else
				{
					B_REST_Utils.console_warn(`Initiated a <br-field-db> that has no B_REST_ModelField_DB selected yet; possible that either we've got no :model nor :field prop, or that they're null, for ${this.final_debugFieldNamePath}`);
				}
			},
				_unbindField()
				{
					this.src_field.onChange_hooks_remove(this.onChange_hook);
					this.src_field = null;
					
					this.confirm_field = null;
				},
			//Called when we update the confirmation field, or when the src model gets updated, via B_REST_FieldModel_DB::onChange_hooks_add()
			_recalcValidation(emitChanged)
			{
				this.confirm_field.validation_custom_errorList.fast_if(this.src_field.val!==this.confirm_field.val, "notMatching", {fieldName:this.src_field.label});
				
				if (emitChanged) { this.$emit("change"); }
			},
			//Like shouldWarn() computed, except that it also recalcs validation and blurs the field (otherwise by design error msgs don't appear)
			checkShouldWarn()
			{
				this._recalcValidation(/*emitChanged*/false);
				
				if (!this.shouldWarn) { return false; }
				
				this.confirm_field.userTouch_toggle(true);
				this.hasEverBlurred = true;
				return true;
			},
			//Anytime we blur the field, recalc validation
			on_change()
			{
				this._recalcValidation(/*emitChanged*/true);
				this.hasEverBlurred = true;
			},
			//After having blurred the field at least once, recalc validation each character we type in, even if we don't leave the field
			on_input()
			{
				if (this.hasEverBlurred) { this._recalcValidation(/*emitChanged*/true); }
			},
		},
	};
	
</script>