	
import { B_REST_Utils } from "@/bREST/core/classes";

//IMPORTANT: If we ever change something here, do so in server's CCUtils.php & frontend's CC_Utils.js



export default class CC_Utils
{
	static get FIRST_LAST_DIGITS_LENGTH() { return 4; } //For firstLastDigits(); how many digits to show when we want to mask the card, on each side, ex 3 would yield "000X XXXX XXXX X000"
	
	static get ISSUERS()               { return CC_Utils._ISSUERS; }
	static get ISSUER_TAG_UNKNOWN()    { return "unknown";         }
	static get ISSUER_TAG_VISA()       { return "visa";            }
	static get ISSUER_TAG_AMEX()       { return "amex";            }
	static get ISSUER_TAG_MASTERCARD() { return "mastercard";      }
	static get ISSUER_TAG_DISCOVER()   { return "discover";        }
	static get ISSUER_TAG_DINERSCLUB() { return "dinersclub";      }
		/*
		IMPORTANT:
			Check server's CCUtils.php for docs and for other issuers
			If ever we add new ones, also need to add them in ./icons/issuers/*.png
		*/
		static _ISSUERS = {
			unknown: { //IMPORTANT: Must equal CC_Utils::ISSUER_TAG_UNKNOWN
				tag:               CC_Utils.ISSUER_TAG_UNKNOWN,
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^\d{16}$/,
				regex_firstDigits: null,
				ex:                "0000 0000 0000 0000",
			},
			visa: {
				tag:               CC_Utils.ISSUER_TAG_VISA,
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^4\d{12}(\d{3})?$/,
				regex_firstDigits: /^4\d{3}/,
				ex:                "4111 1111 1111 1111",
			},
			amex: {
				tag:               CC_Utils.ISSUER_TAG_AMEX,
				cvvLength:         4,
				mask:              "#### ###### #####",
				regex_full:        /^3[47]\d{13}$/,
				regex_firstDigits: /^3[47]\d{2}/,
				ex:                "3493 700386 56069",
			},
			mastercard: {
				tag:               CC_Utils.ISSUER_TAG_MASTERCARD,
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^(5[1-5]\d{4}|677189)\d{10}$/,
				regex_firstDigits: /^(5[1-5]\d{2}|6771)/,
				ex:                "5500 0000 0000 0004",
			},
			discover: {
				tag:               CC_Utils.ISSUER_TAG_DISCOVER,
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^6(?:011|5\d{2})\d{12}$/,
				regex_firstDigits: /^6(?:011|5\d{2})/,
				ex:                "6011 0000 0000 0004",
			},
			dinersclub: {
				tag:               CC_Utils.ISSUER_TAG_DINERSCLUB,
				cvvLength:         3,
				mask:              "#### ###### ####",
				regex_full:        /^3(0[0-5]|[68]\d)\d{11,16}/,
				regex_firstDigits: /^3(0[0-5]|[68]\d)\d/,
				ex:                "3852 000002 3237",
			},
		};
	
	
	
	/*
	Converts all manner of card number inputs to strings w no spaces nor invalid chars. NULL becomes "".
	Ex "   123 5678 000000 00 AA " becomes "123567800000000" and 12345 becomes "12345".
	Always rets a string.
	*/
	static cleanup(cardNumber) { return (cardNumber??"").toString().replace(/\D/g,""); }
	
	/*
	Blindly rets for prog-friendly [cardFirstDigits,cardLastDigits] of 4 digits each, no matter if it makes sense w the issuer's format
	If we got less than 8 digits, then firstDigits will get filled first, then lastDigits w what's remaining, if any. Always ret 2 strings.
	*/
	static firstLastDigits(cardNumber)
	{
		const spaceless   = CC_Utils.cleanup(cardNumber);
		const length      = spaceless.length;
		const cardFirstDigits = spaceless.substr(0,CC_Utils.FIRST_LAST_DIGITS_LENGTH);
		const cardLastDigits  = length>=CC_Utils.FIRST_LAST_DIGITS_LENGTH*2 ? spaceless.slice(-CC_Utils.FIRST_LAST_DIGITS_LENGTH) : spaceless.substr(CC_Utils.FIRST_LAST_DIGITS_LENGTH);
		return [cardFirstDigits, cardLastDigits];
	}
	
	/*
	Performs the Luhn algo on card number to check if it makes sense. https://en.wikipedia.org/wiki/Luhn_algorithm
	Note that the check doesn't include validation for CVV, because it's a random security number that's not a checksum of the cardNumber + expiration date.
	Rets bool.
	Check cleanup() for accepted cardNumber vals.
	*/
	static validateLuhn(cardNumber)
	{
		const spaceless = CC_Utils.cleanup(cardNumber);
		const lastIdx   = spaceless.length-1;
		
		let checksum = 0;
		for (let i=0; i<=lastIdx; i++)
		{
			const loop_digit = parseInt(spaceless[lastIdx-i]);
			checksum += loop_digit;
			if (i%2==1) { checksum += loop_digit<5 ? loop_digit : loop_digit-9; }
		}
		
		return (checksum%10)===0;
	}
	
	/*
	Checks that CVV is 3~4 chars, optionally against a ISSUERS::X.cvvLength.
	Accepts ints & strings.
	Rets bool.
	*/
	static validateCVV(cvv, expectedCVVLength=null)
	{
		if (cvv===null) { return false; }
		const length = cvv.toString().length;
		
		if (expectedCVVLength) { return length===expectedCVVLength; }
		return length===3 || length===4;
	}
	
	//Rets all consts & tags
	static get issuerTags() { return Object.keys(CC_Utils._ISSUERS); }
	static get issuerTags_woUnknown()
	{
		const tags = CC_Utils.issuerTags;
		B_REST_Utils.array_remove_byVal(tags, CC_Utils.ISSUER_TAG_UNKNOWN);
		return tags;
	}
	static issuer_get(tag) { return CC_Utils._ISSUERS[tag] ?? B_REST_Utils.throwEx(`Unknown issuer tag "${tag}"`); }
	
	/*
	Rets one of ISSUERS::X, using their regex_firstDigits search, or ISSUERS::ISSUER_TAG_UNKNOWN when nothing matches.
	Always rets a string.
	Check cleanup() for accepted cardNumber vals.
	*/
	static issuer_eval(cardNumber_orFirstDigits)
	{
		const spaceless = CC_Utils.cleanup(cardNumber_orFirstDigits);
		
		if (spaceless.length>=4) //All card issuer regex_firstDigits reqs to have at least 4 chars filled
		{
			for (const loop_issuerTag in CC_Utils._ISSUERS)
			{
				if (loop_issuerTag===CC_Utils.ISSUER_TAG_UNKNOWN) { continue; }
				const loop_issuer = CC_Utils._ISSUERS[loop_issuerTag];
				if (spaceless.match(loop_issuer.regex_firstDigits)) { return loop_issuer; }
			}
		}
		
		return CC_Utils._ISSUERS[CC_Utils.ISSUER_TAG_UNKNOWN];
	}
	
	static _issuer_assert(issuer) { if(!B_REST_Utils.object_is(issuer)||!issuer.regex_full){B_REST_Utils.throwEx(`Expected a CC_Utils::ISSUERS::X`);} }
	
	/*
	Checks if whole card number perfectly matches a issuer's regex. Doesn't perform validateLuhn() check though.
	Check cleanup() for accepted cardNumber vals.
	*/
	static issuer_validate(issuer, cardNumber)
	{
		CC_Utils._issuer_assert(issuer);
		return CC_Utils.cleanup(cardNumber).match(issuer.regex_full);
	}
	
	/*
	Converts a card like "4111111111111111" into "4111 1111 1111 1111", against one of ISSUERS::X's mask.
	If getting partial cc like "411111", would yield "4111 11", if ISSUERS::visa.
	Always rets a string.
	Check cleanup() for accepted cardNumber vals.
	*/
	static issuer_format(issuer, cardNumber)
	{
		CC_Utils._issuer_assert(issuer);
		const spaceless          = CC_Utils.cleanup(cardNumber);
		const issuer_mask        = issuer.mask;
		const issuer_mask_length = issuer_mask.length;
		
		let formatted = spaceless;
		for (let i=0; i<Math.min(formatted.length,issuer_mask_length); i++)
		{
			const loop_issuer_char = issuer_mask[i];
			if (loop_issuer_char===" ") { formatted = formatted.substr(0,i)+" "+formatted.substr(i,issuer_mask_length-i-1); }
		}
		return formatted.trimEnd();
	}
	
	/*
	If issuer's mask is like "#### #### #### ####" and we inputted "123456" so far, will yield "1234 56## #### ####".
	Always rets a string.
	Check cleanup() for accepted cardNumber vals.
	*/
	static issuer_mask_progress(issuer, cardNumber)
	{
		CC_Utils._issuer_assert(issuer);
		const issuer_mask        = issuer.mask;
		const issuer_mask_length = issuer_mask.length;
		const formatted          = CC_Utils.issuer_format(issuer,cardNumber);
		const formatted_length   = formatted.length;
		
		let masked = "";
		for (let i=0; i<issuer_mask_length; i++) { masked += formatted_length>i?formatted[i]:issuer_mask[i]; }
		return masked;
	}
	/*
	Alt when we already have -some- of the first & last digits, but not what's in the middle
	NOTE: Blindly replaces chars in place wo respecting initial mask, so if mask was like "### ### ### ###" and we provide 1234 & 5678,
			would yield "1234### ###5678" instead of "123 4## ##5 678", but for now, all have at least 4 #### on both sides, and only one like "###### ####### ######", so not a prob
	*/
	static issuer_mask_firstLastDigits(issuer, cardFirstDigits,cardLastDigits)
	{
		CC_Utils._issuer_assert(issuer);
		cardFirstDigits = cardFirstDigits.toString();
		cardLastDigits  = cardLastDigits.toString();
		const issuer_mask            = issuer.mask;
		const issuer_mask_length     = issuer_mask.length;
		const cardFirstDigits_length = cardFirstDigits.length;
		const middleMask             = issuer_mask.substr(cardFirstDigits_length, issuer_mask_length-cardLastDigits.length-cardFirstDigits_length);
		return `${cardFirstDigits}${middleMask}${cardLastDigits}`;
	}
	
	//So we can directly do <img :src="CC_Utils.issuer_iconPath(issuer)" />. Rets NULL though if it was for CARD_ISSUER::ISSUER_TAG_UNKNOWN, as we don't have an icon for it yet
	static issuer_iconPath(issuer)
	{
		CC_Utils._issuer_assert(issuer);
		if (issuer.tag===CC_Utils.ISSUER_TAG_UNKNOWN) { return null; }
		const path = require(`@/bREST/core/implementations/vue/vuetifyComponents/creditCard/icons/issuers/${issuer.tag}.png`);
		return path.default || path;
	}
};
