<template>
	<v-main class="public-layout">
		<v-container fluid class="fill-height align-start pa-0 pa-sm-16 full-bg-img" :class="main_fullBg_class">
			<div style="width:100%; max-width:600px;" class="mx-auto">
				<router-view />
			</div>
		</v-container>
	</v-main>
</template>

<script>
	
	export default {
		computed: {
			main_fullBg_class()
			{
				return this.$bREST.uiBreakpoint.name==="xs" ? "full-bg-image--vertical" : "full-bg-image--horizontal"; //NOTE: We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
			},
		},
	}
	
</script>

<style scoped>
	
	.full-bg-img {
		background-repeat: no-repeat;
		background-size: cover;
	}
		.full-bg-image--vertical   { background-image: url("/_businessFiles/bgs/mobile.jpg");  }
		.full-bg-image--horizontal { background-image: url("/_businessFiles/bgs/desktop.jpg"); }
	
</style>