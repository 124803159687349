<template>

	<v-snackbar v-model="instance.isShowing" transition="slide-y-reverse-transition" :color="current_color" :timeout="current_duration" :top="current_pos==='top'" :right="current_pos==='right'" :bottom="current_pos==='bottom'" :left="current_pos==='left'">
		<div v-html="current_msg" />
		<template #action="{ attrs }"> <v-btn color="white" text v-bind="attrs" @click.stop.prevent="instance.isShowing = false">OK</v-btn> </template>
	</v-snackbar>
	
</template>

<script>
	
	import { B_REST_Vuetify_ToasterManager } from "./B_REST_Vuetify_ToasterManager.js";
	
	
	
	export default
	{
		props: {
			instance: {type:B_REST_Vuetify_ToasterManager, required:true},
		},
		computed: {
			current_color()    { return this.instance.current?.color    ?? null; },
			current_pos()      { return this.instance.current?.pos      ?? null; },
			current_duration() { return this.instance.current?.duration ?? -1;   }, //-1 === infinite
			current_msg()      { return this.instance.current ? this.$bREST.utils.string_toHTML_pre(this.instance.current.msg) : null; },
		},
	};
	
</script>