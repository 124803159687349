//From @vue-router(3.2)/dist/vue-router.common.js

function pathToRegexp (path, keys, options) {
	if (!isarray(keys)) {
		options = /** @type {!Object} */ (keys || options);
		keys = [];
	}

	options = options || {};

	return stringToRegexp(/** @type {string} */ (path), /** @type {!Array} */ (keys), options)
}

var isarray = Array.isArray || function (arr) {
	return Object.prototype.toString.call(arr) == '[object Array]';
};

function stringToRegexp (path, keys, options) {
	return tokensToRegExp(parse(path, options), keys, options)
}

function parse (str, options) {
	var tokens = [];
	var key = 0;
	var index = 0;
	var path = '';
	var defaultDelimiter = options && options.delimiter || '/';
	var res;

	while ((res = PATH_REGEXP.exec(str)) != null) {
		var m = res[0];
		var escaped = res[1];
		var offset = res.index;
		path += str.slice(index, offset);
		index = offset + m.length;

		// Ignore already escaped sequences.
		if (escaped) {
			path += escaped[1];
			continue
		}

		var next = str[index];
		var prefix = res[2];
		var name = res[3];
		var capture = res[4];
		var group = res[5];
		var modifier = res[6];
		var asterisk = res[7];

		// Push the current path onto the tokens.
		if (path) {
			tokens.push(path);
			path = '';
		}

		var partial = prefix != null && next != null && next !== prefix;
		var repeat = modifier === '+' || modifier === '*';
		var optional = modifier === '?' || modifier === '*';
		var delimiter = res[2] || defaultDelimiter;
		var pattern = capture || group;

		tokens.push({
			name: name || key++,
			prefix: prefix || '',
			delimiter: delimiter,
			optional: optional,
			repeat: repeat,
			partial: partial,
			asterisk: !!asterisk,
			pattern: pattern ? escapeGroup(pattern) : (asterisk ? '.*' : '[^' + escapeString(delimiter) + ']+?')
		});
	}

	// Match any characters still remaining.
	if (index < str.length) {
		path += str.substr(index);
	}

	// If the path exists, push it onto the end.
	if (path) {
		tokens.push(path);
	}

	return tokens
}

var PATH_REGEXP = new RegExp([
	// Match escaped characters that would otherwise appear in future matches.
	// This allows the user to escape special characters that won't transform.
	'(\\\\.)',
	// Match Express-style parameters and un-named parameters with a prefix
	// and optional suffixes. Matches appear as:
	//
	// "/:test(\\d+)?" => ["/", "test", "\d+", undefined, "?", undefined]
	// "/route(\\d+)"  => [undefined, undefined, undefined, "\d+", undefined, undefined]
	// "/*"            => ["/", undefined, undefined, undefined, undefined, "*"]
	'([\\/.])?(?:(?:\\:(\\w+)(?:\\(((?:\\\\.|[^\\\\()])+)\\))?|\\(((?:\\\\.|[^\\\\()])+)\\))([+*?])?|(\\*))'
].join('|'), 'g');

function escapeGroup (group) {
	return group.replace(/([=!:$\/()])/g, '\\$1')
}

function escapeString (str) {
	return str.replace(/([.+*?=^!:${}()[\]|\/\\])/g, '\\$1')
}

function tokensToRegExp (tokens, keys, options) {
	if (!isarray(keys)) {
		options = /** @type {!Object} */ (keys || options);
		keys = [];
	}

	options = options || {};

	var strict = options.strict;
	var end = options.end !== false;
	var route = '';

	// Iterate over the tokens and create our regexp string.
	for (var i = 0; i < tokens.length; i++) {
		var token = tokens[i];

		if (typeof token === 'string') {
			route += escapeString(token);
		} else {
			var prefix = escapeString(token.prefix);
			var capture = '(?:' + token.pattern + ')';

			keys.push(token);

			if (token.repeat) {
				capture += '(?:' + prefix + capture + ')*';
			}

			if (token.optional) {
				if (!token.partial) {
					capture = '(?:' + prefix + '(' + capture + '))?';
				} else {
					capture = prefix + '(' + capture + ')?';
				}
			} else {
				capture = prefix + '(' + capture + ')';
			}

			route += capture;
		}
	}

	var delimiter = escapeString(options.delimiter || '/');
	var endsWithDelimiter = route.slice(-delimiter.length) === delimiter;

	// In non-strict mode we allow a slash at the end of match. If the path to
	// match already ends with a slash, we remove it for consistency. The slash
	// is valid at the end of a path match, not in the middle. This is important
	// in non-ending mode, where "/test/" shouldn't match "/test//route".
	if (!strict) {
		route = (endsWithDelimiter ? route.slice(0, -delimiter.length) : route) + '(?:' + delimiter + '(?=$))?';
	}

	if (end) {
		route += '$';
	} else {
		// In non-ending mode, we need the capturing groups to match as much as
		// possible by using a positive lookahead to the end or next path segment.
		route += strict && endsWithDelimiter ? '' : '(?=' + delimiter + '|$)';
	}

	return attachKeys(new RegExp('^' + route, flags(options)), keys)
}

function attachKeys (re, keys) {
	re.keys = keys;
	return re
}

function flags (options) {
	return options && options.sensitive ? '' : 'i'
}



export default pathToRegexp;
