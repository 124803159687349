<template>
	<br-generic-list-base :derived-component="_self">
		<!--
			🚀❓
				3 ways to get col's data, where the 3rd is an ex of if we want to make it out of multiple things:
					<template #item.someFieldOrColumnName="{ rowInfo, colInfo, modelField }">
						<span>{{ modelField.val }}</span>
						<span>{{ rowInfo.model.select("someFieldName").val }}</span>
						<span>{{ rowInfo.model.select_nonEmptyValsConcatenated("first_name|last_name") }}</span>
					</template>
			🚀❓
		-->
		
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "municipalityStreetList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			modelName: "MunicipalityStreet",
			fromLoader: {
				apiBaseUrl: "/municipalityStreets/",
			},
			cols: {
				name: {
					fieldNamePaths: "name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				municipality: {
					fieldNamePaths: "municipality.<toLabel>",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
			},
			row: {
				checkbox: {isEnabled:true},
			},
			filters: {
				name: {fieldNamePath:"name"},
				/*
				IMPORTANT: Can't define the following filter, or forms' x_pickerOptions()::onPrompt_setFilters() computed hooks forcing filters in pickers
					will get overwritten by the filters in the UI. Instead of HC filters this way, should change the apiBaseUrl in the list s:
						OK:  GET /someList/filteredBySomeParentLookup/{pkTag}
						Bad: GET /someList?so_filters=f~parent_fk~eq_in~<pkTag>
					Can't define:
						municipality: {fieldNamePath:"municipality_fk", items:"municipalityList"},
				*/
			},
		}),
	};
	
</script>