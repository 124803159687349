<template>
	<br-error-page-base :msg="t_alt('msg')" :leave-text="t_alt('leave')" :leave-track-intended-route="false" />
		<!--
			NOTE:
				Initially thought about persisting where we wanted to go, so after login back we could get to same place,
					but ex if we're not admin and trying to go to an admin place, shouldn't send back there after login back
					as non admin, or will get endless loop... Could add a QSA so we either set this to true/false when we routes_go_403()
			WARNING: Logic must be consistent between B_REST_App_base::reboot(), B_REST_App_base::_routes_beforeNavigationChange() & BrErrorPage403::leave-track-intended-route
		-->
</template>

<script>
	
	import { createErrorPage } from "./BrErrorPageBase.vue";
	
	export default createErrorPage(403);
	
</script>