
import B_REST_Utils       from "../B_REST_Utils.js";
import B_REST_Model       from "./B_REST_Model.js";
import B_REST_ModelFields from "./B_REST_ModelFields.js";



export default class B_REST_Model_ValidationError
{
	target = null; //A B_REST_ModelFields.x or B_REST_Model instance
	msg    = null;
	
	
	constructor(target, msg)
	{
		B_REST_Model_ValidationError._assertGoodTarget(target);
		
		this.target = target;
		this.msg    = msg;
	}
	
	
	static _assertGoodTarget(target)
	{
		if (!(target instanceof B_REST_ModelFields.base || target instanceof B_REST_Model)) { B_REST_Utils.throwEx(`Expected a B_REST_ModelField_x or B_REST_Model instance`); }
	}
	
	
	//Helper for B_REST_Model::validation_getErrors() & B_REST_ModelField_base::validation_getErrors()
	static getCustomValidationErrors(target, detailed, onlyOne, includeAsyncCustomErrors)
	{
		B_REST_Model_ValidationError._assertGoodTarget(target);
		
		const errors = [];
		
		for (const loop_customError of target.validation_custom_errorList.fast_errors)
		{
			errors.push(detailed ? new B_REST_Model_ValidationError(target,loop_customError.msg) : loop_customError.msg);
			if (onlyOne) { return errors; }
		}
		
		if (includeAsyncCustomErrors)
		{
			for (const loop_customError of target.validation_custom_errorList.async_errors)
			{
				errors.push(detailed ? new B_REST_Model_ValidationError(target,loop_customError.msg) : loop_customError.msg);
				if (onlyOne) { return errors; }
			}
		}
		
		return errors;
	}
};
