<!--
	WARNING:
		Don't assume it's always used in form when B_REST_VueApp_base::_routes_define_genericListFormSubModule() sets form to isCurrentRouteMainComponent=true
		Think that it could be a sandbox page w a <v-dialog> containing a BrGenericFormBase, so current route info could not be related
-->
<template>
	<v-skeleton-loader v-if="!isCreated" height="200" type="list-item-avatar" />
	
	<v-expansion-panel v-else-if="accordion" :disabled="parent_form_model_isNew">
		<v-expansion-panel-header>{{ accordionTitle }}</v-expansion-panel-header>
		<v-expansion-panel-content v-if="!parent_form_model_isNew" :eager="accordionPreload">
			<slot name="accordion-before-list" />
			<component :is="sub_component" v-bind="sub_vBind" />
			<slot name="accordion-after-list" />
		</v-expansion-panel-content>
	</v-expansion-panel>
	
	<v-alert v-else-if="parent_form_model_isNew" type="info" outlined border="left">{{ t_alt("unusableWhenParentIsNew") }}</v-alert>
	
	<component v-else :is="sub_component" v-bind="sub_vBind" />
	
</template>

<script>
	
	import { B_REST_Utils } from "../../../../../classes";
	import { B_REST_Vuetify_GenericListBase_getMixinOptions } from "../list/BrGenericListBase.vue";
	import B_REST_VueApp_CreateCoreMixin from "../../../B_REST_VueApp_CreateCoreMixin.js";
	
	const CORE_ALT_BASE_LOC_PATH = "app.components.BrGenericFormBaseSubModelList";
	
	
	
	export default {
		name: "brGenericFormBaseSubModelList",
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			form:       {type:Object,  required:true}, //Parent BrGenericFormBase instance
			routeNames: {type:String,  required:true}, //Against the above ex, here would be "citizen|animal", so we could use as B_REST_App_base::routes_goBlank_subModuleList("citizen",123,"animal")
			accordion:  {type:Boolean, default:true},  //By default we'll wrap the list in a <v-expansion-panel> and have it disabled when hosting model isNew
			//Stuff only when in accordion mode
				accordionTitle:   {type:String,  default:null},
				accordionPreload: {type:Boolean, default:false}, //If we should boot the component even when panel hasn't been opened yet
		},
		watch: {
			form()                 { B_REST_Utils.throwEx(`Can't change form prop`);            },
			routeNames()           { B_REST_Utils.throwEx(`Can't change routeNames prop`);      },
			accordion()            { B_REST_Utils.throwEx(`Can't change accordion prop`);       },
			parent_form_model()    { if(!this.isCreated){return;} this.checkPrepSubModelList(); },
			parent_form_model_pk() { if(!this.isCreated){return;} this.checkPrepSubModelList(); },
		},
		data()
		{
			return {
				isCreated: false,
				//Static stuff
				parent_routeName:           null, //Ex if routeNames="citizen>animal", would be "citizen". Helps for when we want to add/edit and redirect w B_REST_App_base::routes_go_subModuleForm_x()
				sub_component:              null, //Ptr on a BrGenericListBase ()=>import()
				sub_component_mixinOptions: null, //Its B_REST_Vuetify_GenericListBase_createMixin() options
				sub_apiBaseUrl:             null, //Ex if parent is "/my/citizens/" and usually sub was "/some/animals/", then will yield "/my/citizens/{pkTag}/some/animals". Req to fetch list models
				sub_routeName:              null, //Ex if routeNames="citizen>animal", would be "animal". Helps for when we want to add/edit and redirect w B_REST_App_base::routes_go_subModuleForm_x()
				//Dynamic stuff
				sub_vBind: null, //Only filled when we can actually show the BrGenericListBase der. Will contain a fromLoader:{} to boot it
			};
		},
		async created()
		{
			if (!this.form.isGenericFormBase) { B_REST_Utils.throwEx(`Not bound to a BrGenericFormBase derived`); }
			
			//Try to split routeNames into parent_routeName & sub_routeName
			{
				const routeNameParts = this.routeNames.split(">");
				if (routeNameParts.length!==2) { B_REST_Utils.throwEx(`route-names must be like "citizen>animal". Got "${this.routeNames}"`); }
				
				this.parent_routeName = routeNameParts[0];
				this.sub_routeName    = routeNameParts[1];
				if (!this.parent_routeName || !this.sub_routeName) { B_REST_Utils.throwEx(`route-names must be non-empty on both sides. Got "${this.routeNames}"`); }
			}
			
			//Get B_REST_VueApp_RouteDef::viewComponent of the BrGenericListBase + its B_REST_Vuetify_GenericListBase_createMixin() options
			{
				this.sub_component = this.$bREST.routeDefs_get_moduleList(this.sub_routeName).viewComponent;
				
				this.sub_component_mixinOptions = await B_REST_Vuetify_GenericListBase_getMixinOptions(this.sub_component);
				if (!this.sub_component_mixinOptions) { B_REST_Utils.throwEx(`Couldn't get list mixinOptions for "${this.sub_routeName}" list component`); }
			}
			
			//Make static base API URL for sub list. Ex if parent is "/my/citizens/" and usually sub was "/some/animals/", then will yield "/my/citizens/{pkTag}/some/animals"
			{
				const sub_normal_apiBaseUrl = this.sub_component_mixinOptions.mixinOptions?.fromLoader?.apiBaseUrl ?? null;
				if (!sub_normal_apiBaseUrl) { B_REST_Utils.throwEx(`Couldn't get "fromLoader.apiBaseUrl" in mixinOptions for "${this.sub_routeName}" list component`); } //Ex "/animals/"
				const sub_normal_apiBaseUrl_woLeadingSlashes = sub_normal_apiBaseUrl.replace(/^\/|\/$/g, "");
			
				this.sub_apiBaseUrl = `${this.form.apiBaseUrl_pk}/${sub_normal_apiBaseUrl_woLeadingSlashes}`;
			}
			
			this.checkPrepSubModelList();
			
			this.isCreated = true;
		},
		computed: {
			//NOTE: Since they're in another component, not sure if it's reactive. If not, will need to pass :model here
			parent_form_model()       { return this.form?.model       ?? null; },
			parent_form_model_pk()    { return this.form?.model.pk    ?? null; },
			parent_form_model_isNew() { return this.form?.model.isNew ?? null; },
		},
		methods: {
			async checkPrepSubModelList()
			{
				if (this.parent_form_model_isNew) { this.sub_vBind=null; }
				else
				{
					//WARNING: If we want to change algo, consider complex CPA case where we have 3 models at the same time, ex we're in a EventForm, we want to choose a FranchiseePark, so open FranchiseeParkList picker, click [+], and transfer a franchisee_fk that has nothing to do w the parent EventForm
					
					this.sub_vBind = {
						fromLoader: {
							apiBaseUrl:           this.sub_apiBaseUrl,
							apiBaseUrl_path_vars: {pkTag:this.parent_form_model_pk},
						},
						parent_pkTag:     this.form.model.pk,
						parent_modelName: this.form.modelName,
						subModelListOptions: {
							parent_routeName: this.parent_routeName,
							parent_form:      this.form,
							sub_routeName:    this.sub_routeName,
						},
						elevation: 0,
						showTitle: false,
					};
				}
			},
		},
	};
	
</script>