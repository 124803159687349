
import B_REST_Utils              from "../../classes/B_REST_Utils.js";
import B_REST_App_RouteInfo_base from "../../classes/app/B_REST_App_RouteInfo_base.js";
import B_REST_VueApp_base        from "./B_REST_VueApp_base.js";
import B_REST_VueApp_RouteDef    from "./B_REST_VueApp_RouteDef.js";



export default class B_REST_VueApp_RouteInfo extends B_REST_App_RouteInfo_base
{
	constructor(fullPath, routeDef=null, pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		super(fullPath, routeDef, pathVars, qsa, hashTag, lang);
	}
	
	
	_abstract_toObj(obj) {}
	
	
	/*
	Converts a Vue Router navigation info to something uniform for us. Check vars docs for info on all props we can infer
	If the route doesn't contain a bREST_routeDef meta info, we'll just ret what we can and leave that prop NULL
		We can use isUn/Known() to know
	Check B_REST_VueApp_RouteDef::convertToVueRouteObj()
	IMPORTANT:
		Doesn't follow catch-all routes here (B_REST_VueApp_base::routes_404)
	WARNING about reactivity:
		Check B_REST_VueApp_base::_routes_define_genericListFormModule() docs
		There's a ref to Vue Router deep inside a route obj, so if router's current route changes, it'll think -that other route- changed deep inside
	*/
	static fromVueRouterObj(vueRouterObj)
	{
		B_REST_Utils.object_assert(vueRouterObj);
		
		const routeDef = B_REST_VueApp_RouteDef.getRouteDefFromVueRouterObj(vueRouterObj);  //Can ret NULL
		const fullPath = vueRouterObj.fullPath;                                             //Points to the actual path, even if it doesn't exist
		const lang     = B_REST_VueApp_base.instance.locale_lang;                           //Cheating for now, but if we later allow to hold Vue routes in multiple langs at the same time, will need to refactor
		
		return new B_REST_VueApp_RouteInfo(fullPath, routeDef, vueRouterObj.params, vueRouterObj.query, vueRouterObj.hash, lang);
	}
};
