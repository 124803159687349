<template>
	<br-error-page-base :msg="t_alt('msg')" :leave-text="t_alt('leave')" :leave-track-intended-route="false" />
		<!-- NOTE: Should always be false, as route just doesn't exist, so don't want to go back to non existing page after login back -->
</template>

<script>
	
	import { createErrorPage } from "./BrErrorPageBase.vue";
	
	export default createErrorPage(404);
	
</script>