<template>
	
	<v-alert type="info" colored-border border="left" color="accent">
		<div class="text-body-1">{{ msg }}:</div>
		<div class="text-body-2 mt-4">{{ $bREST.routes_current_qsa_intendedFullPath }}</div>
		<div class="text-right"> <v-btn color="accent" @click="leave()">{{ leaveText }}</v-btn> </div>
	</v-alert>
	
</template>

<script>
	
	import B_REST_VueApp_base            from "../../B_REST_VueApp_base.js";
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	
	
	
	export default {
		props: {
			msg:                     {String,  required:true},
			leaveText:               {String,  required:true},
			leaveTrackIntendedRoute: {Boolean, required:true},
		},
		methods: {
			leave()
			{
				//NOTE: B_REST_App_base::reboot() & Vue implementation's BrErrorPageBase::leave() has similar code to go back to login page and then redirect to prev page
				
				const qsa = {};
				if (this.leaveTrackIntendedRoute) { qsa[B_REST_VueApp_base.ROUTES_QSA_INTENDED_FULL_PATH]=this.$bREST.routes_current_qsa_intendedFullPath; };
				
				this.$bREST.routes_go_root(qsa);
			},
		},
	};
	
	export function createErrorPage(code)
	{
		const COMPONENT_NAME         = `BrErrorPage${code}`;
		const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
		
		return {
			name: COMPONENT_NAME,
			mixins: [
				B_REST_VueApp_CreateCoreMixin({
					coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH, //Only if we want to use t_alt()
				}),
			],
		};
	}
	
</script>