<template>
	<v-progress-linear height="6" v-bind="attrs" :absolute="absolute" :top="absolute" />
</template>

<script>
	
	import { B_REST_FileControlItem } from "../../../../classes";
	
	
	
	export default {
		props: {
			item:     {type:B_REST_FileControlItem, required:true},
			absolute: {type:Boolean,                required:false, default:false},
		},
		computed: {
			attrs()
			{
				let color   = "red";
				let value   = 100;
				let striped = false;
				
				switch (this.item.status)
				{
					case B_REST_FileControlItem.STATUS_NEW_PREPARING:
						//Red + progression
						value = this.item.ifNewPreparing_progression;
					break;
					case B_REST_FileControlItem.STATUS_NEW_PREPARING_FAILED:
						//Red 100%
					break;
					case B_REST_FileControlItem.STATUS_NEW_PREPARED:
						//Green + special style, depending on if we're doing an API call or not
						color = "success";
						if (!this.item.control.ifModelFiles_isParentModelSaving) { striped=true; }
					break;
					case B_REST_FileControlItem.STATUS_STORED:
						if (!this.item.ifStored_toDelete)
						{
							//Success 100%
							color = "success";
						}
						//Else is to delete, so put red + special style, depending on if we're doing an API call or not
						else if (!this.item.control.ifModelFiles_isParentModelSaving) { striped=true; }
					break;
					default:
						this.$bREST.throwEx(`Unhandled item status "${this.item.status}"`);
					break;
				}
				
				return {color, value, indeterminate:this.item.status_isNewPreparing, striped};
			}
		},
	};
	
</script>