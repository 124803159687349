
export default class B_REST_CustomFilterDescriptor
{
	_name = null; //Name of the filter in the -host- model
	
	
	constructor(name)
	{
		this._name = name;
	}
	
	
	get name() { return this._name; }
};
