
import { B_REST_Utils } from "../../../../classes";
import B_REST_VueApp_base from "../../B_REST_VueApp_base.js";

B_REST_Utils.console_todo([
	"Check how to implement displaying it's loading (maybe in dialog top + disable action buttons",
	"Allow setupping possible actions (colors, on click do stuff. Should maybe do slot with a {selectAction}",
	`Should maybe check if interceptor fails, to display why, if we received something, or if the user should handle it himself
		-> Think it makes more sense that we handle it here, since dialog is started in JS and not Vue`,
]);



export class B_REST_Vuetify_Prompt
{
	_isModal     = null;
	_title       = null;
	_body        = null;   //Text w newlines that will be converted to divs, or HTML that will be put in a v-html
	_body_isHTML = false;
	_actions     = [];     //Arr of B_REST_Vuetify_Prompt_Action instances or null (for spacers)
	_isActive    = false;
	_color       = null;
	
	//Vars only set when shown
	_shownPromise_resolver = null;
	_shownPromise_rejecter = null;
	_interceptor           = null;  //User func as (selectedAction, resolve, reject), optionally called when we choose an action not being NULL. Rejecter must ret as {inlineErrorMsg:string|null,keepOpened:bool}
	_isIntercepting        = false; //Whether or not we've just called the above interceptor and are waiting for a resolve / reject
	_interceptor_retDialog = null;
	
	
	
	constructor(title, isModal)
	{
		this._isModal = isModal;
		this._title   = title;
	}
	
	static _throwEx(sMsg) { B_REST_Utils.throwEx(`B_REST_Vuetify_Prompt: ${sMsg}`); }
	
	
	get isModal() { return this._isModal; }
	set isModal(val) { this._isModal=val; }
	
	get title() { return this._title; }
	set title(val) { this._title=val; }
	
	get body() { return this._body; }
	set body(val) { this._body=val; }
	
	get body_isHTML() { return this._body_isHTML; }
	set body_isHTML(val) { this._body_isHTML=val; }
	
	get actions() { return this._actions; }
	set actions(val) { this._actions = val; }
	
	get isActive() { return this._isActive; }
	
	get color() { return this._color; }
	set color(val) { this._color = val; }
	
	get isIntercepting() { return this._isIntercepting; }
	get interceptor_retDialog() { return this._interceptor_retDialog; }
	
	
	
	async show(interceptor=null)
	{
		if (this.isActive) { B_REST_Vuetify_Prompt._throwEx(`Trying to show when already active. Finish action first`); }
		if (interceptor!==null && typeof interceptor!=="function") { B_REST_Vuetify_Prompt._throwEx(`Interceptor must be as f(selectedAction,resolve,reject)`); }
		
		this._interceptor = interceptor;
		
		return new Promise((s,f) =>
		{
			this._shownPromise_resolver = s;
			this._shownPromise_rejecter = f;
			
			this._isActive = true;
		});
	}
	
	//Where NULL = clicked outside / did ESC
	async selectAction(selectedActionOrNull)
	{
		//Just some validation, shouldn't happen though
		if (!this.isActive)       { B_REST_Vuetify_Prompt._throwEx(`Trying to perform action when dialog is inactive`);                      }
		if (this._isIntercepting) { B_REST_Vuetify_Prompt._throwEx(`Already intercepting; can't try to perform 2 actions at the same time`); }
		
		/*
		If we have to wait for the user to do something about the action, that could either take time or fail, mention that we're waiting for an external response.
		Then, only "finish" the action if all was right. If user code decided that the action can't be performed or got an err, we'll just leave the dialog opened, to let the user try another action.
		In the interceptor, the user could also call .cancel() to just stop all.
		*/
		if (this._interceptor && selectedActionOrNull!==null)
		{
			this._isIntercepting = true;
			const interceptionResultInfo = await this._doInterception(selectedActionOrNull);
			this._isIntercepting = false;
			
			if      (interceptionResultInfo.success)     { this._done(selectedActionOrNull,/*bResolve*/true); }
			else if (!interceptionResultInfo.keepOpened) { this._done(null,                /*bResolve*/true); } //NOTE: Here, we could throw (bResolve=false), but since it comes from user's decision, it's prolly not an exception / we shouldn't re-throw what he might already have used an error toast notif for
			//Else it failed and we want to leave it opened so we can try again
		}
		else { this._done(selectedActionOrNull,/*bResolve*/true); }
	}
		async _doInterception(selectedAction)
		{
			return new Promise((outer_s,outer_f) =>
			{
				const innerPromise = new Promise((inner_s,inner_f)=>{ this._interceptor(selectedAction,inner_s,inner_f); });
				
				innerPromise
					.then(retMsgOrNull                   => { return {success:true, retMsgOrNull,               keepOpened:false}; })
					.catch(({inlineErrorMsg,keepOpened}) => { return {success:false,retMsgOrNull:inlineErrorMsg,keepOpened      }; })
					.then(async(infos) =>
					{
						if (infos.retMsgOrNull!==null)
						{
							this._interceptor_retDialog = new B_REST_Vuetify_Prompt("",false);
							this._interceptor_retDialog.body = infos.retMsgOrNull;
							this._interceptor_retDialog.actions = [
								new B_REST_Vuetify_Prompt_Action(null, "OK", null),
							];
							this._interceptor_retDialog.color = infos.success ? "success" : "error";
							await this._interceptor_retDialog.show();
							this._interceptor_retDialog = null;
						}
						
						outer_s(infos);
					});
			});
		}
		_done(data, bResolve)
		{
			this._isActive = false;
			
			if (bResolve) { this._shownPromise_resolver(data); }
			else          { this._shownPromise_rejecter(data); }
			
			this._shownPromise_resolver = null;
			this._shownPromise_rejecter = null;
			this._interceptor           = null;
		}
	
	//Can be called anytime to force closing of the dialog, either because we used a certain NULL action / pressed ESC, or just because external code decided to forget about it
	async cancel() { await this.selectAction(null); }
	
	
	
	
	/*
	Helpers to setup common prompts
	Options:
		locBasePath:  Where in custom/core loc to find {title, body, actions:{<cancel>,someAction,someOtherAction,...}}. Body can contain \n and will be split in multiple lines, but formatting/HTML isn't supported yet
		isCorePrompt: If true, locBasePath will point in core loc instead of custom loc
		htmlBody:     If passed, instead of finding body through locBasePath, will just take this as final HTML content to use
		actions:      Piped action names w colors, w common names wrapped in <> (see COMMON_ACTIONS_x), and optional separators as "-". Ex "<cancel>:error|print:blue|sendEmail:orange" or "<yes>:success|-|<no>:error".
		              	Since it's modal, always resolve in one of these
		              	For loc, if they're common like <yes>, <no>, <cancel>, <ok>, it's gonna be read under core's "app.booter.prompts.common.actions.x" by default, but can be overriden if present under locBasePath
		locDetails:   If we have replacement tags to add to locs. Ex {body:{a:1,b:2}, actions:{someAction:{c:3,d:4}}}, when translations are like "Some\nbody {a} {b}" or "Send {c} stuff"
		color:        Prompt's bg color. NULL by default
		interceptor:  Check B_REST_Vuetify_Prompt::show() docs; allows doing async thing upon making a selection, before the prompt would close
	Variations:
		helper_alert()   One intended to block UI to mention something that has to be acknowledged, w a "ok" action
		helper_confirm() A confirm one w "yes" vs "cancel" actions
		For both, must at least provide {title,body} in loc, and since they sets useCoreCommonLoc=true by default, then no need to make loc for actions, but if we do, will be used instead of core ones
	*/
	static get COMMON_ACTIONS_YES()    { return "<yes>";    }
	static get COMMON_ACTIONS_NO()     { return "<no>";     }
	static get COMMON_ACTIONS_CANCEL() { return "<cancel>"; }
	static get COMMON_ACTIONS_OK()     { return "<ok>";     }
	static helper(options)
	{
		options = B_REST_Utils.object_hasValidStruct(options, {
			locBasePath:      {accept:[String],   required:true},
			isCorePrompt:     {accept:[Boolean],  default:false},
			htmlBody:         {accept:[String],   default:null},
			actions:          {accept:[String],   required:true},
			locDetails:       {accept:[Object],   default:{}}, //To KISS
			color:            {accept:[String],   default:null},
			interceptor:      {accept:[Function], default:null},
		}, "B_REST_Vuetify_Prompt::helper");
		const options_locBasePath  = options.locBasePath;
		const options_isCorePrompt = options.isCorePrompt;
		const options_htmlBody     = options.htmlBody;
		const options_locDetails   = options.locDetails;
		
		const MyApp = B_REST_VueApp_base.instance;
		
		const title_locPath    = `${options_locBasePath}.title`;
		const title_locDetails = options_locDetails.title ?? null;
		const title            = options_isCorePrompt ? MyApp.t_core(title_locPath,title_locDetails) : MyApp.t_custom(title_locPath,title_locDetails);
		
		const prompt = new B_REST_Vuetify_Prompt(title, /*isModal*/true);
		prompt.color = options.color;
		
		if (options_htmlBody===null)
		{	
			const body_locPath    = `${options_locBasePath}.body`;
			const body_locDetails = options_locDetails.body ?? null;
			prompt.body = options_isCorePrompt ? MyApp.t_core(body_locPath, body_locDetails)  : MyApp.t_custom(body_locPath, body_locDetails);
		}
		else
		{
			prompt.body_isHTML = true;
			prompt.body        = options_htmlBody;
		}
		
		for (const loop_actionInfo of options.actions.split("|"))
		{
			if (loop_actionInfo==="-") { prompt.actions.push(null); continue; } //Spacer
			
			const loop_actionInfoParts = loop_actionInfo.split(":");
			if (loop_actionInfoParts.length!==2) { B_REST_Vuetify_Prompt._throwEx(`Malformed action "${loop_actionInfo}" found`,options); }
			const [loop_action_tag,loop_action_color] = loop_actionInfoParts;
			
			let loop_label = null;
			{
				const loop_action_isCommon      = loop_action_tag[0]==="<";
				const loop_action_coreLocPath   = `app.booter.prompts.common.actions.${loop_action_tag}`;
				const loop_action_customLocPath = `${options_locBasePath}.actions.${loop_action_tag}`;
				const loop_action_locDetails    = options_locDetails?.actions?.[loop_action_tag] ?? null;
				
				if (options_isCorePrompt)      { loop_label=MyApp.t_core(         loop_action_coreLocPath,  loop_action_locDetails); }
				else if (loop_action_isCommon) { loop_label=MyApp.t_custom_orNULL(loop_action_customLocPath,loop_action_locDetails) ?? MyApp.t_core(loop_action_coreLocPath,loop_action_locDetails); }
				else                           { loop_label=MyApp.t_custom(       loop_action_customLocPath,loop_action_locDetails); }
			}
			
			prompt.actions.push( new B_REST_Vuetify_Prompt_Action(loop_action_tag,loop_label,loop_action_color) );
		}
		
		return prompt;
	}
		static helper_alert(options)
		{
			B_REST_Utils.object_assert(options);
			options.actions = `${B_REST_Vuetify_Prompt.COMMON_ACTIONS_OK}:success`;
			return this.helper(options);
		}
		static helper_confirm(options)
		{
			B_REST_Utils.object_assert(options);
			options.actions = `${B_REST_Vuetify_Prompt.COMMON_ACTIONS_CANCEL}:error|-|${B_REST_Vuetify_Prompt.COMMON_ACTIONS_YES}:success`;
			return this.helper(options);
		}
};



export class B_REST_Vuetify_Prompt_Action
{
	_tag     = null; //Can be something like NULL | "OK" | "cancel" | "saveStuff"...
	_label   = null;
	_color   = null;
	_enabled = true;
	
	
	constructor(tag, label, color)
	{
		this._tag   = tag;
		this._label = label;
		this._color = color;
	}
	
	
	get tag()     { return this._tag;     }
	get label()   { return this._label;   }
	get color()   { return this._color;   }
	get enabled() { return this._enabled; }
	
	set enabled(val) { this._enabled = val; }
};
