<template>
	<div>
		<br-generic-list-base :derived-component="_self" ref="list" />
		
		<v-card class="my-10" flat>
			<v-card-title class="grey--text text--darken-3">{{ t("add.label") }}</v-card-title>
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" sm="auto"> <v-text-field v-model="newNumber" /> </v-col>
					<v-col cols="12" sm="auto"> <v-text-field v-model="newEmissionDate" type="date" /> </v-col>
					<v-col cols="12" sm="auto"> <v-btn :block="$bREST.uiBreakpoint.mobile" :disabled="!canSubmit" @click="submit">{{ t("add.btn") }}</v-btn> </v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";

	export default {
		name: "medalList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			modelName: "Medal",
			fromLoader: { }, //Note: specs defined in route def in App.js
			cols: {
				number:        {fieldNamePaths:"number",        style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				emission_date: {fieldNamePaths:"emission_date", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_RIGHT}},
			},
		}),
		data()
		{
			return {
				newNumber: null,
				newEmissionDate: null,
				isSaving: false,
			};
		},
		computed: {
			canSubmit() { return this.newNumber && this.newEmissionDate && !this.isSaving; },
		},
		methods: {
			async submit()
			{
				this.isSaving = true;
				
				try
				{
					const request = new this.$bREST.POST("/animals/{animal}/medals", {animal:this.$bREST.routes_current_pathVars.animal});
					request.data_set('number', this.newNumber);
					request.data_set('emission_date', this.newEmissionDate);
					const response = await this.$bREST.call(request);  //Might throw
					const result   = response.data.result;
					
					switch (result)
					{
						case "OK":
							this.$bREST.notifs_tmp({
								msg:   this.t("add.success"),
								color: "success",
							});
							this.newNumber = null;
							this.newEmissionDate = null;
							this.$refs.list.derivedComponent.reloadList();
						break;
						case "alreadyUsed":
							this.$bREST.notifs_tmp({
								msg:   this.t("add.alreadyUsed"),
								color: "error",
							});
						break;
						default:
							this.$bREST.throwEx(`Unknown result "${result}"`);
						break;
					}
				}
				catch (e)
				{
					this.$bREST.notifs_tmp({
						msg:   this.t("add.failure"),
						color: "error",
					});
				}
				
				this.isSaving = false;
			},
		},
	};
	
</script>