
import B_REST_VueApp_base            from "./B_REST_VueApp_base.js";
import B_REST_VueApp_RouteDef        from "./B_REST_VueApp_RouteDef.js";
import B_REST_VueApp_RouteInfo       from "./B_REST_VueApp_RouteInfo.js";
import B_REST_VueApp_CreateCoreMixin from "./B_REST_VueApp_CreateCoreMixin.js";
import B_REST_Vuetify_PickerDef      from "./vuetifyComponents/picker/B_REST_Vuetify_PickerDef.js";
import { B_REST_Vuetify_GenericFormBase_createMixin, B_REST_Vuetify_GenericFormBase_isDerivedComponentImport, B_REST_Vuetify_GenericFormBase_getMixinOptions } from "./vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
import { B_REST_Vuetify_GenericListBase_createMixin, B_REST_Vuetify_GenericListBase_isDerivedComponentImport, B_REST_Vuetify_GenericListBase_getMixinOptions, B_REST_Vuetify_GenericList_Col, B_REST_Vuetify_GenericList_GlobalAction, B_REST_Vuetify_GenericList_RowAction, FILTER_OPS, GENERIC_LIST_CONSTS } from "./vuetifyComponents/genericModules/list/BrGenericListBase.vue";
import { B_REST_Vuetify_RightDrawer, B_REST_Vuetify_RightDrawerContent, DESKTOP_WIDTH as RIGHT_DRAWER_DESKTOP_WIDTH } from "./vuetifyComponents/rightDrawer/BrRightDrawer.vue";
import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action } from "./vuetifyComponents/prompt/B_REST_Vuetify_Prompt.js";



const B_REST_Vuetify_GenericForm_obj = {
	createMixin:              B_REST_Vuetify_GenericFormBase_createMixin,
	isDerivedComponentImport: B_REST_Vuetify_GenericFormBase_isDerivedComponentImport,
	getMixinOptions:          B_REST_Vuetify_GenericFormBase_getMixinOptions,
};

const B_REST_Vuetify_GenericList_obj = {
	createMixin:              B_REST_Vuetify_GenericListBase_createMixin,
	isDerivedComponentImport: B_REST_Vuetify_GenericListBase_isDerivedComponentImport,
	getMixinOptions:          B_REST_Vuetify_GenericListBase_getMixinOptions,
	Col:                      B_REST_Vuetify_GenericList_Col,
	GlobalAction:             B_REST_Vuetify_GenericList_GlobalAction,
	RowAction:                B_REST_Vuetify_GenericList_RowAction,
	FILTER_OPS,
	CONSTS: GENERIC_LIST_CONSTS,
};

const B_REST_Vuetify_RightDrawer_obj = {
	RightDrawer:        B_REST_Vuetify_RightDrawer,
	RightDrawerContent: B_REST_Vuetify_RightDrawerContent,
	DESKTOP_WIDTH:      RIGHT_DRAWER_DESKTOP_WIDTH,
};

const B_REST_Vuetify_Prompt_obj = {
	Prompt:        B_REST_Vuetify_Prompt,
	Prompt_Action: B_REST_Vuetify_Prompt_Action,
};



export {
	B_REST_VueApp_base,
	B_REST_VueApp_RouteDef,
	B_REST_VueApp_RouteInfo,
	B_REST_VueApp_CreateCoreMixin,
	B_REST_Vuetify_PickerDef,
	B_REST_Vuetify_GenericForm_obj as B_REST_Vuetify_GenericForm,
	B_REST_Vuetify_GenericList_obj as B_REST_Vuetify_GenericList,
	B_REST_Vuetify_RightDrawer_obj as B_REST_Vuetify_RightDrawer,
	B_REST_Vuetify_Prompt_obj      as B_REST_Vuetify_Prompt,
};
