
import { B_REST_Utils, B_REST_FieldDescriptors, B_REST_ModelFields, B_REST_Model_Load_SearchOptions_Filters } from "../../../../../classes";
import B_REST_VueApp_base                                                                                     from "../../../B_REST_VueApp_base.js";
const FieldDescriptor_DB   = B_REST_FieldDescriptors.DB;
const B_REST_ModelField_DB = B_REST_ModelFields.DB;



const FILTER_OPS       = B_REST_Model_Load_SearchOptions_Filters.base; //Not actually a list of const, just a ptr to the B_REST_Model_Load_SearchOptions_Filter_base class
const FILTER_OPS_BOOLS = [FILTER_OPS.OP_NULL, FILTER_OPS.OP_N_NULL, FILTER_OPS.OP_0_EMPTY_STR, FILTER_OPS.OP_N_0_EMPTY_STR];
const FILTER_OPS_BTWS  = [FILTER_OPS.OP_BTW, FILTER_OPS.OP_N_BTW];

const DB_TYPE_OP_MAP = {};
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_STRING]              = FILTER_OPS.OP_P_LIKE_P;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_INT]                 = FILTER_OPS.OP_BTW;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_DECIMAL]             = FILTER_OPS.OP_BTW;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_BOOL]                = FILTER_OPS.OP_EQ_IN;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_JSON]                = FILTER_OPS.OP_EQ_IN; //Must be OP_EQ_IN, because we'll send whatever as an arr of ints or tags
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_DT]                  = FILTER_OPS.OP_BTW;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_D]                   = FILTER_OPS.OP_BTW;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_T]                   = FILTER_OPS.OP_BTW;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_C_STAMP]             = FILTER_OPS.OP_BTW;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_U_STAMP]             = FILTER_OPS.OP_BTW;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_ENUM]                = FILTER_OPS.OP_EQ_IN;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_PHONE]               = FILTER_OPS.OP_EQ_IN;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_EMAIL]               = FILTER_OPS.OP_EQ_IN;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_PWD]                 = FILTER_OPS.OP_EQ_IN;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH]    = FILTER_OPS.OP_P_LIKE_P;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_ARR]                 = FILTER_OPS.OP_EQ_IN;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING] = FILTER_OPS.OP_P_LIKE_P;
DB_TYPE_OP_MAP[FieldDescriptor_DB.TYPE_CUSTOM]              = FILTER_OPS.OP_EQ_IN;

const MULTIPLE_DEFAULT = null; //IMPORTANT: Must match BrFieldDb's multiple prop default & generator's Generator_Worker_Parser_Model::UI_LIST_FILTER_MULTIPLE_DEFAULT

/*
About having extra "IS NULL" or "IS NOT NULL" in filter :items:
	-A DB field w a NULL val is just "undefined", so if we want to search for something that's NULL, we should either:
		-Add a calc in DB saying if it's NULL or not, and search by that w a diff filter
		-Activate a second bool B_REST_CustomFilterDescriptor just for that
	-To search for things that aren't NULL, we should either:
		-Tick all :items for a massive IN()
		-Activate a second bool B_REST_CustomFilterDescriptor just for that
*/

export default class B_REST_Vuetify_GenericList_Filter
{
	_listComponent    = null;  //BrGenericListBase der this belongs to
	_name             = null;  //Name of the filter for frontend use; not sent to the server
	_isCustom         = null;  //Controls whether it'll end in server's ModelOptions_Load::field_filters_dynamic() or ModelOptions_Load::custom_filters_dynamic()
	_fieldNamePath    = null;  //Only if !isCustom; must end up pointing to a DB field, ex "citizen.animals.breed.loc.name" to search for citizen having a "Berger"
	_customFilterName = null;  //Only if isCustom; must match a custom filter defined in server via Descriptor_base::_def_customFilter()
	_modelFieldOrArr  = null;  //B_REST_ModelField_x instances we'll CREATE just for the <BrGenericListBaseFilters>, based on a fieldDescriptor. Either a single instance or arr[2], depending on the wanted operator (ex a OP_BTW)
	_fieldDescriptor  = null;  //To make the _modelFieldOrArr works, they must be attached to a B_REST_FieldDescriptor_DB we'll ALWAYS create here. IMPORTANT: Even if it points to a known B_REST_FieldDescriptor_DB, we'll create a copy of it, since we might need diff {required, min, max, label, etc}
	_op               = null;  //One of FILTER_OPS.x
	_op_is_between    = null;  //Helper to know if we wanted to have a OP_BTW or OP_N_BTW
	_brFieldDbProps   = null;  //Required map of optional attrs like {items, multiple:<bool>, as:<string>, ...} (check BrFieldDb docs), to pass down to a <br-field-db>
	_label            = null;  //Either the one of the B_REST_FieldDescriptor_DB we're refering to, or something else custom to the generic list
	_soFilter         = null;  //Ptr on a B_REST_Model_Load_SearchOptions_Filter_x instance within the B_REST_ModelList of the generic list, where we'll put its val
	_hidden           = false; //We could decide to hide them, say when we use the list as a picker and we want to force (and hide) some filters w a certain val
		/*
		IMPORTANT:
			-Check notes above class about IS NULL / IS NOT NULL
			-If we add stuff, also add in toConstructorOptions()
		*/
	
	
	/*
	Options as:
		{
			fieldNamePath:  NULL or ex "citizen.animals.breed.loc.name" to search for citizen having a "Berger". Must end up pointing on a DB field (either fieldNamePath or custom must be set)
			custom:         NULL or the following (either fieldNamePath or custom must be set)
			                {
			                    customFilterName: ex "someCitizenCustomFilterName" defined in the model in the server
			                    fieldDescriptor:  We must either provide our own fake B_REST_FieldDescriptor_DB instance
			                    type:             ... or at least specify a B_REST_FieldDescriptor_DB::TYPE_x
			                },
			op:             Auto calculated against field descriptor, or OP_AUTO by default. Specify when we want to force it to one of FILTER_OPS.OP_x
			optionalVal:    For edge cases like when we've got a TYPE_BOOL and we want it to be always "filled" as false when not checked for ex
			brFieldDbProps: Optional map of optional attrs like {items, multiple:<bool>, as:<string>, ...} (check BrFieldDb docs), to pass down to a <br-field-db>. NOTE: We can also set some out of this obj; check below (ex {as,items,picker})
			multiple:       NOTE: We can define multiple in brFieldDbProps, but also here too, which might make more sense when we're doing something custom; both will be taken in together
			as:             Shortcut to setting brFieldDbProps:{as}
			items:          Shortcut to setting brFieldDbProps:{items}
			picker:         Shortcut to setting brFieldDbProps:{picker}
		}
	IMPORTANT:
		-Check notes above class about IS NULL / IS NOT NULL
		-If we add more props, we'll need to add them in toConstructorOptions() below too
	*/
	constructor(listComponent, name, options={})
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			fieldNamePath:  {accept:[String],  default:null},
			custom:         {accept:[Object],  default:null},
			op:             {accept:[String],  default:undefined},
			optionalVal:    {accept:undefined, default:null},
			brFieldDbProps: {accept:[Object],  default:{}},        //Things to pass to a <br-field-db>
			multiple:       {accept:[Boolean], default:MULTIPLE_DEFAULT},
			as:             {accept:undefined, default:undefined}, //Shortcut to setting brFieldDbProps:{as}
			items:          {accept:undefined, default:undefined}, //Shortcut to setting brFieldDbProps:{items}
			picker:         {accept:undefined, default:undefined}, //Shortcut to setting brFieldDbProps:{picker}
		}, "Generic list filter");
		
		this._listComponent = listComponent;
		this._name          = name;
		
		if (!(!!options.fieldNamePath^!!options.custom)) { this._throwEx(`Must receive either fieldNamePath or custom`,options); }
		
		this._isCustom       = !!options.custom;
		this._fieldNamePath  = options.fieldNamePath;
		this._brFieldDbProps = options.brFieldDbProps;
		
		const descriptor        = this._listComponent.descriptor;
		const customLocBasePath = `${this._listComponent.t_baseLocPath}.filters.${this._name}`; //Ex "components.contactList.filters.firstName"
		const customLabel       = B_REST_VueApp_base.instance.t_custom_orNULL(`${customLocBasePath}.${B_REST_VueApp_base.LOC_KEY_SHORT_LABEL}`) //Ex "components.contactList.filters.firstName.shortLabel"
		                       || B_REST_VueApp_base.instance.t_custom_orNULL(`${customLocBasePath}.${B_REST_VueApp_base.LOC_KEY_LABEL}`);      //Ex "components.contactList.filters.firstName.label"
		
		/*
		Since we can pass "multiple" directly in the options + in brFieldDbProps, make sure it's always around in the brFieldDbProps, or <br-field-db> won't know.
		We need this for B_REST_CustomFilterDescriptor, but if it produces side effects, we should prolly not...
		*/
		this._brFieldDbProps.multiple = this._brFieldDbProps.multiple ?? options.multiple ?? MULTIPLE_DEFAULT;
		
		//Check if we have to add more brFieldDbProps props that were shortcuted out of that obj
		{
			if (options.as    !==undefined) { this._brFieldDbProps.as    =options.as;     }
			if (options.items !==undefined) { this._brFieldDbProps.items =options.items;  }
			if (options.picker!==undefined) { this._brFieldDbProps.picker=options.picker; }
		}
		
		/*
		To make the _modelFieldOrArr works, they must be attached to a B_REST_FieldDescriptor_DB we'll ALWAYS create here
		IMPORTANT:
			Even if it's is a known B_REST_FieldDescriptor_DB, we'll create a copy of it, since we might need diff {required, min, max, label, etc}
		*/ 
		{
			let fieldDescriptor = null;
			let mustClone       = null;
			
			if (this._isCustom)
			{
				const customOptions = B_REST_Utils.object_hasValidStruct_assert(options.custom, {
					customFilterName: {accept:[String],             required:true},
					fieldDescriptor:  {accept:[FieldDescriptor_DB], default:null},
					type:             {accept:[String],             default:null}, //A const of B_REST_FieldDescriptor_DB.TYPE_x
				}, "Generic list filter custom options");
				
				this._customFilterName = customOptions.customFilterName;
				
				//Validation
				if (!descriptor.customFilters_find(this._customFilterName,/*throwIfNotFound*/false)) { this._throwEx(`Must match a B_REST_CustomFilterDescriptor directly in "${descriptor.name}"`); }
				if (!(!!customOptions.fieldDescriptor^!!customOptions.type))                         { this._throwEx(`Custom filters must pass the fieldDescriptor or type hint`);                   }
				
				if (customOptions.fieldDescriptor)
				{
					fieldDescriptor = customOptions.fieldDescriptor;
					mustClone       = true; //Because we could get something where validation specs (req, min, max, etc) doesn't fit w what we want for the filter
				}
				else if (customOptions.type)
				{
					fieldDescriptor = new FieldDescriptor_DB(this._name, customOptions.type, {
							isRequired:    false,
							isNullable:    true,
							wCustomSetter: false,
							setOnce:       FieldDescriptor_DB.SET_ONCE_OFF,
							locBasePath:   customLocBasePath,
							optionalVal:   options.optionalVal,
						});
					mustClone = false;
				}
				else { this._throwEx(`Should never happen`); }
			}
			else
			{
				fieldDescriptor = descriptor.allFields_find_byFieldNamePath(this._fieldNamePath);
				mustClone       = true; //IMPORTANT: Even if it's a known B_REST_FieldDescriptor_DB, we'll create a copy of it, since we might need diff {required, min, max, label, etc}
			}
			
			//Now finalize assignation of a B_REST_FieldDescriptor_DB
			{
				//Catchall code to make sure that all paths end up w a valid B_REST_FieldDescriptor_DB
				if (!(fieldDescriptor instanceof FieldDescriptor_DB)) { this._throwEx(`For now, can only use B_REST_FieldDescriptor_DB instances`); }
				
				//For now, it doesn't make sense to want to have a multiple on something that only has 2 choices + since we'd convert to TYPE_ARR, final_items() will not go in the branch for TYPE_BOOL, so we'd also need to provide :items ourselves
				if (this._brFieldDbProps.multiple && fieldDescriptor.type_is_bool) { this._throwEx(`For now, can't put a TYPE_BOOL multiple`); }
				
				//Then clone. If we don't want to clone, the later else will make sure we're not trying to use multiple=true if type hasn't become TYPE_ARR
				if (mustClone)
				{
					let finalType = fieldDescriptor.type;
					if      (this._brFieldDbProps.multiple)                           { finalType=FieldDescriptor_DB.TYPE_ARR;    }
					else if (FILTER_OPS_BOOLS.includes(options.op))                   { finalType=FieldDescriptor_DB.TYPE_BOOL;   }
					else if (finalType===FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING) { finalType=FieldDescriptor_DB.TYPE_STRING; }
					
					const isBool = finalType===FieldDescriptor_DB.TYPE_BOOL;
					
					//When we're reusing field descriptors, it's better not to take their optionalVal prop, otherwise ex int BTW will have 0z all the time
					const optionalVal = options.optionalVal; //NOTE: We could also go furthermore with: !this._brFieldDbProps.multiple&&fieldDescriptor.type_is_bool ? fieldDescriptor.optionalVal : null
					
					const fieldDescriptorOptions = {
						isRequired:    false,
						isNullable:    true, //IMPORTANT: Should leave this to true, otherwise we can't clear inputs
						wCustomSetter: fieldDescriptor.wCustomSetter,
						setOnce:       fieldDescriptor.setOnce,
						loc:           fieldDescriptor.loc,
						isPKField:     false, //NOTE: Don't pass fieldDescriptor.isPKField here, or when we clear the filters it'll throw saying "Trying to unset PK" in a context that doesn't make sense
						optionalVal,
					};
					
					if (!isBool)
					{
						fieldDescriptorOptions.enum_members = fieldDescriptor.enum_members;
						
						if (!this._brFieldDbProps.multiple)
						{
							fieldDescriptorOptions.min      = fieldDescriptor.min;
							fieldDescriptorOptions.max      = fieldDescriptor.max;
							fieldDescriptorOptions.decimals = fieldDescriptor.decimals;
						}
						
						if (fieldDescriptor.lookup_is) { fieldDescriptorOptions.lookupInfo = {modelName:fieldDescriptor.lookup_modelName,fieldName:fieldDescriptor.lookup_fieldName}; } //NOTE: This used to be within the if !multiple cond above, but don't remember why. Made filters crash when they're on a picker w multiple:true
					}
					
					fieldDescriptor = new FieldDescriptor_DB(this._name, finalType, fieldDescriptorOptions);
				}
				//If we don't want to clone, make sure we're not trying to use multiple=true if type hasn't become TYPE_ARR
				else if (this._brFieldDbProps.multiple && !fieldDescriptor.type_is_arr) { this._throwEx(`For now, can only work in multiple mode w TYPE_ARR DB fields`); }
				
				this._fieldDescriptor = fieldDescriptor;
			}
		}
		
		/*
		Even if fieldDescriptor usually holds a label, have one ready here so we can decide whether we want to use a custom one or either of fieldDescriptor's label vs shortLabel
		We could even check if the brFieldDbProps specified one
		Then, we can pass this down to <br-field-db :label="...">
		*/
		{
			this._label = customLabel ?? this._brFieldDbProps.label ?? this._fieldDescriptor.shortLabel;
			
			//If still NULL or reported as not found via the field descriptor (prolly because we've created a fake one)
			if (this._label===null || this._label.match(/^%.+%$/))
			{
				this._label = `%${customLocBasePath}%`;
				B_REST_VueApp_base.instance.t_custom_warnNotFound(customLocBasePath);
			}
		}
		
		//Figure out the op to use
		{
			//If we specified it. Note that OP_AUTO=null, so we must check against undefined
			if (options.op!==undefined) { this._op=options.op; }
			//If we want a picker opening a generic list, we'll have to use OP_EQ_IN
			else if (B_REST_Utils.object_hasPropName(this._brFieldDbProps,"picker")) { this._op=FILTER_OPS.OP_EQ_IN; }
			//Same for when we define :items to use
			else if (B_REST_Utils.object_hasPropName(this._brFieldDbProps,"items")) { this._op=FILTER_OPS.OP_EQ_IN; }
			//Otherwise, guess against the field's type
			else
			{
				const type = this._fieldDescriptor.type;
				
				if (!B_REST_Utils.object_hasPropName(DB_TYPE_OP_MAP,type)) { this._throwEx(`Unhandled DB field type "${type}"`); }
				
				this._op = DB_TYPE_OP_MAP[type];
			}
			
			//Setup a helper
			this._op_is_between = FILTER_OPS_BTWS.includes(this._op);
		}
		
		//Now we can create the modelfield(s) based on the fieldDescriptor and wanted op
		{
			if (this._op_is_between) { this._modelFieldOrArr = [new B_REST_ModelField_DB(this._fieldDescriptor),new B_REST_ModelField_DB(this._fieldDescriptor)]; }
			else                     { this._modelFieldOrArr =  new B_REST_ModelField_DB(this._fieldDescriptor);                                                  }	
		}
		
		//Will then need to call updateModelListBinding() to make it usable
	}
		//Do this when list's modelList is set / changes
		updateModelListBinding()
		{
			//Properly rem link w previous modelList's searchOptions, if any
			if (this._soFilter) { this._soFilter.modelFieldOrArr_reAssign(/*modelFieldOrArrOrNULL*/null,/*keepPreviousVals*/false); }
			
			const soFilter = this._listComponent.modelList.searchOptions.f_specifyOp(this._fieldNamePath||this._customFilterName, this._op, /*throwIfExists*/false);
			soFilter.modelFieldOrArr_reAssign(this._modelFieldOrArr, /*keepPreviousVals*/false);
			this._soFilter = soFilter;
		}
	
	_throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`,details); }
	
	
	
	get listComponent()   { return this._listComponent;                                                                     }
	get debugName()       { return `B_REST_Vuetify_GenericList_Filter<${this._name}@${this._listComponent.componentName}>`; }
	get name()            { return this._name;                                                                              }
	get slotName()        { return `uiFilter.${this._name}`;                                                                } //To do something like <template #uiFilter.xxx>
	get fieldDescriptor() { return this._fieldDescriptor;                                                                   }
	get brFieldDbProps()  { return this._brFieldDbProps;                                                                    }
	get label()           { return this._label;                                                                             }
	get soFilter()        { return this._soFilter;                                                                          }
	get isSet()           { return this._soFilter?.isSet ?? false;                                                          }
	get op()              { return this._op;                                                                                }
	get op_is_between()   { return this._op_is_between;                                                                     }
	
	get modelField()
	{
		if (this._modelFieldOrArr===null) { this._throwEx(`Not using model fields`);                                  }
		if (this._op_is_between)          { this._throwEx(`For OP_BTW & OP_N_BTW, use modelField_x/y props instead`); }
		
		return this._modelFieldOrArr;
	}
	get modelField_x() { return this._modelField_xy(0); }
	get modelField_y() { return this._modelField_xy(1); }
		_modelField_xy(idx)
		{
			if (this._modelFieldOrArr===null)  { this._throwEx(`Not using model fields`);                                                   }
			if (!this._op_is_between)          { this._throwEx(`For non OP_BTW & OP_N_BTW, use modelField prop instead of modelField_x/y`); }
			
			return this._modelFieldOrArr[idx];
		}
	
	get hidden()    { return this._hidden; }
	set hidden(val) { this._hidden=val;    }
};
