
import version                from "../version.js";
import B_REST_Utils           from "./B_REST_Utils.js";
import B_REST_Error           from "./B_REST_Error.js";
import B_REST_CallStats       from "./api/B_REST_CallStats.js";
import B_REST_Response        from "./api/B_REST_Response.js";
import B_REST_API             from "./api/B_REST_API.js";
import B_REST_App_base        from "./app/B_REST_App_base.js";
import B_REST_DOMFilePtr      from "./files/B_REST_DOMFilePtr.js";
import B_REST_FileControl     from "./files/B_REST_FileControl.js";
import B_REST_FileControlItem from "./files/B_REST_FileControlItem.js";

import {B_REST_Request_base, B_REST_Request_GET,B_REST_Request_GET_File,B_REST_Request_POST,B_REST_Request_POST_File,B_REST_Request_POST_Multipart,B_REST_Request_PUT,B_REST_Request_PUT_Multipart,B_REST_Request_PATCH,B_REST_Request_PATCH_Multipart,B_REST_Request_DELETE} from "./api/B_REST_Request.js";
const B_REST_Requests = {
	base:            B_REST_Request_base,
	GET:             B_REST_Request_GET,
	GET_File:        B_REST_Request_GET_File,
	POST:            B_REST_Request_POST,
	POST_File:       B_REST_Request_POST_File,
	POST_Multipart:  B_REST_Request_POST_Multipart,
	PUT:             B_REST_Request_PUT,
	PUT_Multipart:   B_REST_Request_PUT_Multipart,
	PATCH:           B_REST_Request_PATCH,
	PATCH_Multipart: B_REST_Request_PATCH_Multipart,
	DELETE:          B_REST_Request_DELETE,
};

import B_REST_Descriptor from "./descriptors/B_REST_Descriptor.js";

import B_REST_FieldDescriptors              from "./descriptors/B_REST_FieldDescriptors.js";
import B_REST_FieldDescriptor_DB_EnumMember from "./descriptors/B_REST_FieldDescriptor_DB_EnumMember.js";

import B_REST_CustomFilterDescriptor from "./descriptors/B_REST_CustomFilterDescriptor.js";

import B_REST_Model                           from "./models/B_REST_Model.js";
import B_REST_ModelList                       from "./models/B_REST_ModelList.js";
import B_REST_Model_ValidationError           from "./models/B_REST_Model_ValidationError.js";
import B_REST_Model_CustomValidationErrorList from "./models/B_REST_Model_CustomValidationErrorList.js";
import B_REST_ModelFields                     from "./models/B_REST_ModelFields.js";

import B_REST_Model_Load_RequiredFields from "./models/B_REST_Model_Load_RequiredFields.js";

import {B_REST_Model_Load_SearchOptions, B_REST_Model_Load_SearchOptions_Filter_base,B_REST_Model_Load_SearchOptions_Filter_ValOrArr,B_REST_Model_Load_SearchOptions_Filter_On,B_REST_Model_Load_SearchOptions_Filter_NumberOrString,B_REST_Model_Load_SearchOptions_Filter_String,B_REST_Model_Load_SearchOptions_Filter_Between, B_REST_Model_Load_SearchOptions_OrderByItem} from "./models/B_REST_Model_Load_SearchOptions.js";
const B_REST_Model_Load_SearchOptions_Filters = {
	base:           B_REST_Model_Load_SearchOptions_Filter_base,
	ValOrArr:       B_REST_Model_Load_SearchOptions_Filter_ValOrArr,
	On:             B_REST_Model_Load_SearchOptions_Filter_On,
	NumberOrString: B_REST_Model_Load_SearchOptions_Filter_NumberOrString,
	String:         B_REST_Model_Load_SearchOptions_Filter_String,
	Between:        B_REST_Model_Load_SearchOptions_Filter_Between,
};



export {
	version,
	B_REST_Utils,
	B_REST_Error,
	B_REST_DOMFilePtr,
	B_REST_CallStats,
	B_REST_Requests,
	B_REST_Response,
	B_REST_API,
	B_REST_App_base,
	B_REST_FileControl,
	B_REST_FileControlItem,
	B_REST_Descriptor,
	B_REST_FieldDescriptors,
	B_REST_FieldDescriptor_DB_EnumMember,
	B_REST_CustomFilterDescriptor,
	B_REST_Model,
	B_REST_ModelList,
	B_REST_Model_ValidationError,
	B_REST_Model_CustomValidationErrorList,
	B_REST_ModelFields,
	B_REST_Model_Load_RequiredFields,
	B_REST_Model_Load_SearchOptions,
	B_REST_Model_Load_SearchOptions_Filters,
	B_REST_Model_Load_SearchOptions_OrderByItem,
};
