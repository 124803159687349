
import B_REST_Utils    from "../B_REST_Utils.js";
import B_REST_App_base from "../../classes/app/B_REST_App_base.js";



export default class B_REST_App_RouteDef_base
{
	static get NAME_LANDPAGE()  { return "_landpage_"; } //Usually "/", but could be diff for each lang
	static get NAME_LOGIN()     { return "_login_";    }
	static get NAME_RESET_PWD() { return "_resetPwd_"; }
	static get NAME_404()       { return "_404_";      } //To catch all and redirect somewhere else (in that case, we can leave langUrls to NULL)
	static get NAME_403()       { return "_403_";      } //Perms errs page
	static get NAME_PROFILE()   { return "_profile_";  }
		/*
		IMPORTANT:
			If we add new ones:
				Also add B_REST_App_base::routeDefs_x() & B_REST_App_base::routes_go_x() + maybe alias in B_REST_VueApp_RouteDef::convertToVueRouteDefObj()
				Also in server's RouteParser_base::UI_ROUTE_NAMES_x & RouteParser_base::output_json_injectCore_redirect_x()
				Also change keys in _SPECIAL_NAMES_EXPECTED_TYPES below
		*/
	static _SPECIAL_NAMES = [
		B_REST_App_RouteDef_base.NAME_LANDPAGE,
		B_REST_App_RouteDef_base.NAME_LOGIN,
		B_REST_App_RouteDef_base.NAME_RESET_PWD,
		B_REST_App_RouteDef_base.NAME_404,
		B_REST_App_RouteDef_base.NAME_403,
		B_REST_App_RouteDef_base.NAME_PROFILE,
	];
	static _SPECIAL_NAMES_EXPECTED_TYPES = {
		_landpage_: B_REST_App_RouteDef_base.TYPE_PUBLIC_LANDPAGE,
		_login_:    B_REST_App_RouteDef_base.TYPE_PUBLIC_LOGIN,
		_resetPwd_: B_REST_App_RouteDef_base.TYPE_PUBLIC_RESET_PWD,
		_404_:      B_REST_App_RouteDef_base.TYPE_PUBLIC_404,
		_403_:      B_REST_App_RouteDef_base.TYPE_PUBLIC_403,
		_profile_:  B_REST_App_RouteDef_base.TYPE_AUTH_PROFILE,
	};
	
	static get TYPE_PUBLIC_MISC()          { return "publicMisc";         }
	static get TYPE_PUBLIC_LANDPAGE()      { return "publicLandpage";     }
	static get TYPE_PUBLIC_LOGIN()         { return "publicLogin";        }
	static get TYPE_PUBLIC_RESET_PWD()     { return "publicResetPwd";     }
	static get TYPE_PUBLIC_404()           { return "public404";          }
	static get TYPE_PUBLIC_403()           { return "public403";          }
	static get TYPE_AUTH_MISC()            { return "authMisc";           }
	static get TYPE_AUTH_PROFILE()         { return "authProfile";        }
	static get TYPE_AUTH_MODULE_LIST()     { return "authModuleList";     }
	static get TYPE_AUTH_MODULE_FORM()     { return "authModuleForm";     }
	static get TYPE_AUTH_SUB_MODULE_LIST() { return "authSubModuleList";  }
	static get TYPE_AUTH_SUB_MODULE_FORM() { return "authSubModuleForm";  }
		static _TYPES = [
			B_REST_App_RouteDef_base.TYPE_PUBLIC_MISC,
			B_REST_App_RouteDef_base.TYPE_PUBLIC_LANDPAGE,
			B_REST_App_RouteDef_base.TYPE_PUBLIC_LOGIN,
			B_REST_App_RouteDef_base.TYPE_PUBLIC_RESET_PWD,
			B_REST_App_RouteDef_base.TYPE_PUBLIC_404,
			B_REST_App_RouteDef_base.TYPE_PUBLIC_403,
			B_REST_App_RouteDef_base.TYPE_AUTH_MISC,
			B_REST_App_RouteDef_base.TYPE_AUTH_PROFILE,
			B_REST_App_RouteDef_base.TYPE_AUTH_MODULE_LIST,
			B_REST_App_RouteDef_base.TYPE_AUTH_MODULE_FORM,
			B_REST_App_RouteDef_base.TYPE_AUTH_SUB_MODULE_LIST,
			B_REST_App_RouteDef_base.TYPE_AUTH_SUB_MODULE_FORM,
		];
	
	
	_name                      = null;  //Unique route name throughout all route defs. Could be a const of NAME_x
	_type                      = null;  //One of TYPE_x
	_langUrls                  = null;  //Map of <lang>:url, ex {fr:"/some/fr/clients/:idClient/factures/:idInvoice/stuff"}
	_layoutComponent           = null;  //Depending on used framework, either an import or something else for the layout (component) around the view/form/sheet that is that route
	_viewComponent             = null;  //Same as for the layout, but for the actual component of that route
	_needsAuth                 = null;  //Whether we need an access token to go to that route or not
	//Vars only when TYPE_AUTH_MODULE_FORM. Ex when route is like /citizens/:pkTag
	_pathVarNames_pkTag        = null;  //Against the above ex, here would be "pkTag"
	//Vars only when TYPE_AUTH_SUB_MODULE_x. Ex when route is like /citizens/:citizen/animals/:pkTag
	_pathVarNames_parent_pkTag = null;  //Against the above ex, here would be "citizen"
	_pathVarNames_sub_pkTag    = null;  //Against the above ex, here would be "pkTag"
	
	
	constructor(options)
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			name:                      {accept:[String],  required:true}, //Name, or const of NAME_x
			type:                      {accept:[String],  required:true}, //One of TYPE_x
			langUrls:                  {accept:[Object],  required:true},
			layoutComponent:           {accept:undefined, required:true},
			viewComponent:             {accept:undefined, required:true},
			needsAuth:                 {accept:[Boolean], default:false},
			pathVarNames_pkTag:        {accept:[String],  default:null},
			pathVarNames_parent_pkTag: {accept:[String],  default:null},
			pathVarNames_sub_pkTag:    {accept:[String],  default:null},
		}, "Route def");
		
		//Validation
		{
			if (!B_REST_App_RouteDef_base._TYPES.includes(options.type)) { B_REST_Utils.throwEx(`Type must be one of B_REST_App_RouteDef_base::TYPE_x`,options); }
			
			const ifSpecialName_expectedType = B_REST_App_RouteDef_base._SPECIAL_NAMES_EXPECTED_TYPES[options.name]; //Yields undefined in most cases
			if (ifSpecialName_expectedType && options.type!==ifSpecialName_expectedType) { B_REST_Utils.throwEx(`Special route "${options.name}" not matching expected type "${ifSpecialName_expectedType}"`); }
			
			//Req stuff when TYPE_AUTH_MODULE_FORM
			if (options.type===B_REST_App_RouteDef_base.TYPE_AUTH_MODULE_FORM)
			{
				if (options.pathVarNames_pkTag!==B_REST_App_base.ROUTES_PATH_VARS_PK_TAG) { B_REST_Utils.throwEx(`TYPE_AUTH_MODULE_FORM routes must define pathVarNames_pkTag prop and it must always be "${B_REST_App_base.ROUTES_PATH_VARS_PK_TAG}"`,options); } //WARNING: If that should ever change, then will have impacts ex in B_REST_App_base::_routes_goX_module_x() & B_REST_App_base::_routes_goX_subModule_x()
			}
			
			//Req stuff when TYPE_AUTH_SUB_MODULE_x
			if (options.type===B_REST_App_RouteDef_base.TYPE_AUTH_SUB_MODULE_LIST || options.type===B_REST_App_RouteDef_base.TYPE_AUTH_SUB_MODULE_FORM)
			{
				if (!options.pathVarNames_parent_pkTag) { B_REST_Utils.throwEx(`TYPE_AUTH_SUB_MODULE_x routes must define pathVarNames_parent_pkTag & pathVarNames_sub_pkTag props`,options); }
			}
			
			//Req stuff when TYPE_AUTH_SUB_MODULE_FORM
			if (options.type===B_REST_App_RouteDef_base.TYPE_AUTH_SUB_MODULE_FORM)
			{
				if (options.pathVarNames_sub_pkTag!==B_REST_App_base.ROUTES_PATH_VARS_PK_TAG) { B_REST_Utils.throwEx(`TYPE_AUTH_SUB_MODULE_FORM must define pathVarNames_sub_pkTag prop, and for now must always be "${B_REST_App_base.ROUTES_PATH_VARS_PK_TAG}"`,options); } //WARNING: If that should ever change, then will have impacts ex in B_REST_VueApp_base::_routes_define_genericListFormSubModule(), BrGenericFormBase::afterSave_updateUrl() and prolly at other places we use B_REST_VueApp_base.ROUTES_PATH_VARS_PK_TAG
			}
			
			/*
			Make sure we have at least an URL in 1 lang, and that all routes start with "/".
			We don't allow "*" either (if we want a catch-all route, use NAME_404 for ex)
			If routes need to have special path vars like for pkTag ex "/citizens/:citizen/animals/:pkTag", then we'll make sure we did define :citizen & :pkTag,
				so that "/citizens/:bob/animals/:hey" would fail
				We use regexes to search for an exact match of the path var, either at the middle of the string or right at the end
					Ex if we search for "test" it'll find "/a:test" and "/a/:test/b" but not "/a:teste" and "/a/:teste/b"
			NOTE: When we define urls we can specify a string instead of a lang map, but ex if using Vue, B_REST_VueApp_base::_routes_define_langUrlsToObj() takes care of converting strings to maps
			*/
			{
				if (B_REST_Utils.object_isEmpty(options.langUrls)) { B_REST_Utils.throwEx(`Must have at least one lang URL`,options); }
				
				const pathVarNames_toCheck = [];
					if (options.pathVarNames_pkTag)        { pathVarNames_toCheck.push("pathVarNames_pkTag");        }
					if (options.pathVarNames_parent_pkTag) { pathVarNames_toCheck.push("pathVarNames_parent_pkTag"); }
					if (options.pathVarNames_sub_pkTag)    { pathVarNames_toCheck.push("pathVarNames_sub_pkTag");    }
				const pathVarNames_validators = pathVarNames_toCheck.map(loop_pathVarName => { return {pathVarName:loop_pathVarName, regex:new RegExp(`:(${loop_pathVarName}\W|${loop_pathVarName}$)`)} });
				
				for (const loop_lang in options.langUrls)
				{
					const loop_url = options.langUrls[loop_lang];
					if (!loop_url || loop_url[0]!=="/") { B_REST_Utils.throwEx(`Lang URLs must start with "/"`,options); }
					
					for (const loop_pathVarName_validator of pathVarNames_validators)
					{
						if (loop_url.match(loop_pathVarName_validator.regex)) { B_REST_Utils.throwEx(`Lang URLs for that route must contain a :${loop_pathVarName_validator.pathVarName} path var`) }
					}
				}
			}
		}
		
		this._name                      = options.name;
		this._type                      = options.type;
		this._langUrls                  = options.langUrls;
		this._layoutComponent           = options.layoutComponent;
		this._viewComponent             = options.viewComponent;
		this._needsAuth                 = options.needsAuth;
		this._pathVarNames_pkTag        = options.pathVarNames_pkTag;
		this._pathVarNames_parent_pkTag = options.pathVarNames_parent_pkTag;
		this._pathVarNames_sub_pkTag    = options.pathVarNames_sub_pkTag;
	}
	
	
	
	get name()                      { return this._name;                      }
	get type()                      { return this._type;                      }
	get langUrls()                  { return this._langUrls;                  }
	get layoutComponent()           { return this._layoutComponent;           }
	get viewComponent()             { return this._viewComponent;             }
	get needsAuth()                 { return this._needsAuth;                 }
	get pathVarNames_pkTag()        { return this._pathVarNames_pkTag;        }
	get pathVarNames_parent_pkTag() { return this._pathVarNames_parent_pkTag; }
	get pathVarNames_sub_pkTag()    { return this._pathVarNames_sub_pkTag;    }
	
	get type_isPublicMisc()        { return this._type===B_REST_App_RouteDef_base.TYPE_PUBLIC_MISC;          }
	get type_isPublicLandpage()    { return this._type===B_REST_App_RouteDef_base.TYPE_PUBLIC_LANDPAGE;      }
	get type_isPublicLogin()       { return this._type===B_REST_App_RouteDef_base.TYPE_PUBLIC_LOGIN;         }
	get type_isPublicResetPwd()    { return this._type===B_REST_App_RouteDef_base.TYPE_PUBLIC_RESET_PWD;     }
	get type_isPublic404()         { return this._type===B_REST_App_RouteDef_base.TYPE_PUBLIC_404;           }
	get type_isPublic403()         { return this._type===B_REST_App_RouteDef_base.TYPE_PUBLIC_403;           }
	get type_isAuthMisc()          { return this._type===B_REST_App_RouteDef_base.TYPE_AUTH_MISC;            }
	get type_isAuthProfile()       { return this._type===B_REST_App_RouteDef_base.TYPE_AUTH_PROFILE;         }
	get type_isAuthModuleList()    { return this._type===B_REST_App_RouteDef_base.TYPE_AUTH_MODULE_LIST;     }
	get type_isAuthModuleForm()    { return this._type===B_REST_App_RouteDef_base.TYPE_AUTH_MODULE_FORM;     }
	get type_isAuthSubModuleList() { return this._type===B_REST_App_RouteDef_base.TYPE_AUTH_SUB_MODULE_LIST; }
	get type_isAuthSubModuleForm() { return this._type===B_REST_App_RouteDef_base.TYPE_AUTH_SUB_MODULE_FORM; }
	
	
	
	//Yields a full URL (from app's base dir)
	toFullPath(pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		B_REST_Utils.object_assert(pathVars);
		B_REST_Utils.object_assert(qsa);
		if (!lang) { lang=B_REST_App_base.instance.locale_lang; }
		
		if (!B_REST_Utils.object_hasPropName(this._langUrls,lang)) { B_REST_Utils.throwEx(`Not defining langUrl for lang "${lang}"`); }
		
		const splittedPath = B_REST_App_RouteDef_base.splitPath(this._langUrls[lang]);
		
		for (let i=0; i<splittedPath.length; i++)
		{
			const loop_currentPart = splittedPath[i];
			
			//Check to replace path vars
			if (loop_currentPart[0]===":")
			{
				const loop_paramName = loop_currentPart.match(/^:(\w+)/)[1];
				
				splittedPath[i] = pathVars[loop_paramName];
			}
		}
		
		let fullPath = "/" + splittedPath.join("/");
		
		return B_REST_Utils.url_addQSAAndHashTag(fullPath, qsa, hashTag);
	}
	
	//Converts to a B_REST_RouteInfo_base der instance
	toRouteInfo(pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		if (pathVars===null) { pathVars={};                                   }
		if (qsa     ===null) { qsa     ={};                                   }
		if (lang    ===null) { lang    =B_REST_App_base.instance.locale_lang; }
		
		return this._abstract_toRouteInfo(pathVars, qsa, hashTag, lang);
	}
		//Must ret a B_REST_RouteInfo_base der instance
		_abstract_toRouteInfo(pathVars={}, qsa={}, hashTag=null, lang=null) { B_REST_Utils.throwEx(`Must override base method`); }
	
	/*
	Rets {lang,pathVars} or false if no lang matches, receiving either a path, or the result of a previous call to splitPath()
	Usage ex:
		routeDef = new B_REST_App_RouteDef_base("test", {
			fr:"/some/fr/clients/:idClient/factures/:idInvoice/stuff",
			en:"/some/en/clients/:idClient/invoices/:idInvoice/stuff",
		});
		
		const splittedPath = B_REST_App_RouteDef_base.splitPath("/some/fr/clients/123/factures/456/stuff");
		
		routeDef.checkPathMatch(splittedPath);
			-> {lang:"fr", pathVars:{idClient:123,idInvoice:456}}
	IMPORTANT:
		If multiple langs have the same URL, then lang will equal NULL
	*/
	checkPathMatch(pathOrSplittedPath)
	{
		const splittedPath = B_REST_Utils.array_is(pathOrSplittedPath) ? pathOrSplittedPath : B_REST_App_RouteDef_base.splitPath(pathOrSplittedPath);
		const partsCount   = splittedPath.length;
		
		for (let loop_lang in this._langUrls)
		{
			const loop_langUrl       = this._langUrls[loop_lang];
			const loop_langUrl_parts = B_REST_App_RouteDef_base.splitPath(loop_langUrl);
			
			if (loop_langUrl_parts.length!==partsCount) { break; }
			
			const loop_pathVars = {};
			let loop_langMatching = true;
			for (let i=0; i<partsCount; i++)
			{
				const loop_currentPart_langUrl      = loop_langUrl_parts[i];
				const loop_currentPart_splittedPath = splittedPath[i];
				
				//Check if the part was a dynamic param, ex ":id(\\*|[\\w-]+)" in "/someModule/:id(\\*|[\\w-]+)"
				if (loop_currentPart_langUrl[0]===":")
				{
					const loop_paramName = loop_currentPart_langUrl.match(/^:(\w+)/)[1];
					
					loop_pathVars[loop_paramName] = loop_currentPart_splittedPath;
				}
				//Continue checking more parts as long as they remain equal
				else if (loop_currentPart_langUrl!==loop_currentPart_splittedPath)
				{
					loop_langMatching = false;
					break;
				}
			}
			
			if (loop_langMatching)
			{
				//Before indicating in which lang it matches, check if that lang's URL is identical to those in other langs
				for (const loop_otherLangs in this._langUrls)
				{
					if (loop_otherLangs!==loop_lang && this._langUrls[loop_otherLangs]===loop_langUrl)
					{
						loop_lang = null;
						break;
					}
				}
				
				return {lang:loop_lang, pathVars:loop_pathVars};
			}
		}
		
		return false;
	}
	
	/*
	Helper for checkPathMatch() & B_REST_App_base::routes_getRouteInfo_from_x()
	Expects path wo QSA
	Usage ex:
		"/a/b/c/" -> ["a","b","c"]
		"a/b"     -> ["a","b"]
	*/
	static splitPath(path)
	{
		return path.replaceAll(/^\/|\/$/g,"").split("/");
	}
};
