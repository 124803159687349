
import { B_REST_Utils, B_REST_ModelFields } from "../../classes";
import B_REST_VueApp_base from "./B_REST_VueApp_base.js";



/*
Allows implementing multiple things that would be redundant to code in all components:
	-$bREST.t_x() loc funcs w a given base locPath, wo having to re-code loc funcs in all components, w diff base loc path
	-Helper for component's name, alias of $options.name
	-More to come later maybe, ex error management / notifs..
Usage ex:
	MyComponent.vue:
		export default {
			name: "myComponent",
			mixins: [
				B_REST_VueApp_CreateCoreMixin({
					coreAltBaseLocPath: "app.someCoreFeatureNode", //Only if we want to use t_alt()
				}),
			],
			methods: {
				test()
				{
					this.t("some.thing");     //Instead of doing this.$bREST.t_custom("components.myComponent.some.thing")
					this.t_alt("some.thing"); //Instead of doing this.$bREST.t_custom_alt("components.myComponent.some.thing", "app.someCoreFeatureNode.some.thing")
				},
			},
		};
	<lang>.json:
		{
			components: {
				myComponent: {
					some: {thing:"hey"}
				}
			}
		}
IMPORTANT:
	-Must define component's name w the "name" Vue prop
	-If we want to use t_alt(), we must define {coreAltBaseLocPath} in the mixin options, and custom + core loc sub trees struct must be identical
		In the ex above, we'd need to have identical sub trees like:
			/core/<lang>.js:
				app.someCoreFeatureNode.some.thing
			/custom/<lang>.js:
				components.myComponent.some.thing
NOTE:
	-If multiple components should reuse the same base locPath, then they could have the same component name, or we could later implement mixin options for a baseLocPath
*/

export default function B_REST_VueApp_CreateCoreMixin(mixinOptions={})
{
	mixinOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions, {
		coreAltBaseLocPath: {accept:[String], required:false}, //For if we use t_alt()
	}, "Mixin");
	
	return {
		data()
		{
			return {
				coreMixin_timeoutsAndIntervals: [], //Arr of {type,handle [, cancel]}, where type is one of s|t|i and cancel is an opt Promise rejector
			};
		},
		created()
		{
			if (!this.$options.name) { B_REST_Utils.throwEx(`Component's name must be defined to use bREST core mixin`,this); }
		},
		beforeDestroy()
		{
			this.coreMixin_clearTimeoutsAndIntervals();
		},
		computed: {
			//WARNING: Avoid using these in usage's data(); $options.name & methods will be available, but data() & computed will be still undefined
			componentName() { return this.$options.name; },
			t_baseLocPath() { return `components.${this.$options.name}`; }, //WARNING: Don't use in the methods below; read warning above
		},
		methods: {
			throwEx(msg, details=null) { B_REST_Utils.throwEx(`Component <${this.$options.name}>: ${msg}`,details); },
			//For these methods docs, check the funcs w the same name in B_REST_App_base
			t(       subLocPath,details=null,lang=null) { return B_REST_VueApp_base.instance.t_custom(       `components.${this.$options.name}.${subLocPath}`, details,lang); },
			t_orNULL(subLocPath,details=null,lang=null) { return B_REST_VueApp_base.instance.t_custom_orNULL(`components.${this.$options.name}.${subLocPath}`, details,lang); },
			t_alt(   subLocPath,details=null,lang=null)
			{
				if (!mixinOptions.coreAltBaseLocPath) { B_REST_Utils.throwEx(`To use bREST core mixin's t_alt(), must define mixin's coreAltBaseLocPath prop`,this); }
				
				const coreAltLocPath = `${mixinOptions.coreAltBaseLocPath}.${subLocPath}`;
				return B_REST_VueApp_base.instance.t_custom_alt(`components.${this.$options.name}.${subLocPath}`, coreAltLocPath, details,lang);
			},
			/*
			Helpers for creating standalone fields
			Ex if component's name is "eventForm" and locRelBasePath is "occurrences.fields.alt_staff_fk",
				then final locBasePath will be "components.eventForm.occurrences.fields.alt_staff_fk" and fieldName will be "alt_staff_fk"
			Can let locRelBasePath NULL if either we already defined {locBasePath} or {loc:{label,shortLabel}}.
			*/
				coreMixin_createStandalone_lookup_req(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("int",               true, locRelBasePathOrNULL,options); }, //NOTE: The diff w coreMixin_createStandalone_int() is not having a min, otherwise in BrFieldDb we'd see a "0 and more" when opening the dropdown :D
				coreMixin_createStandalone_int_req(               locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("int",               true, locRelBasePathOrNULL,options, (options)=>{if(options.min===undefined){options.min=0;}}); }, //IMPORTANT: If we change callback, do it for both req/opt funcs
				coreMixin_createStandalone_decimal_req(           locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("decimal",           true, locRelBasePathOrNULL,options, (options)=>{if(options.min===undefined){options.min=0;}}); }, //IMPORTANT: If we change callback, do it for both req/opt funcs
				coreMixin_createStandalone_string_req(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("string",            true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_bool_req(              locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("bool",              true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_json_req(              locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("json",              true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_dt_req(                locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("dt",                true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_d_req(                 locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("d",                 true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_t_req(                 locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("t",                 true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_cStamp_req(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("cStamp",            true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_uStamp_req(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("uStamp",            true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_enum_req(              locRelBasePathOrNULL,pipedEnumMembers,options={}) { return this._coreMixin_createStandalone_x_common("enum",              true, locRelBasePathOrNULL,options, (options)=>{options.enum_members=pipedEnumMembers;}); }, //IMPORTANT: If we change callback, do it for both req/opt funcs
				coreMixin_createStandalone_phone_req(             locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("phone",             true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_email_req(             locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("email",             true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_pwd_req(               locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("pwd",               true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_arr_req(               locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("arr",               true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_multilingualString_req(locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("multilingualString",true, locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_custom_req(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("custom",            true, locRelBasePathOrNULL,options); },
				
				coreMixin_createStandalone_lookup_opt(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("int",               false,locRelBasePathOrNULL,options); }, //NOTE: The diff w coreMixin_createStandalone_int() is not having a min, otherwise in BrFieldDb we'd see a "0 and more" when opening the dropdown :D
				coreMixin_createStandalone_int_opt(               locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("int",               false,locRelBasePathOrNULL,options, (options)=>{if(options.min===undefined){options.min=0;}}); }, //IMPORTANT: If we change callback, do it for both req/opt funcs
				coreMixin_createStandalone_decimal_opt(           locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("decimal",           false,locRelBasePathOrNULL,options, (options)=>{if(options.min===undefined){options.min=0;}}); }, //IMPORTANT: If we change callback, do it for both req/opt funcs
				coreMixin_createStandalone_string_opt(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("string",            false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_bool_opt(              locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("bool",              false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_json_opt(              locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("json",              false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_dt_opt(                locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("dt",                false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_d_opt(                 locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("d",                 false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_t_opt(                 locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("t",                 false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_cStamp_opt(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("cStamp",            false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_uStamp_opt(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("uStamp",            false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_enum_opt(              locRelBasePathOrNULL,pipedEnumMembers,options={}) { return this._coreMixin_createStandalone_x_common("enum",              false,locRelBasePathOrNULL,options, (options)=>{options.enum_members=pipedEnumMembers;}); }, //IMPORTANT: If we change callback, do it for both req/opt funcs
				coreMixin_createStandalone_phone_opt(             locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("phone",             false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_email_opt(             locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("email",             false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_pwd_opt(               locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("pwd",               false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_arr_opt(               locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("arr",               false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_multilingualString_opt(locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("multilingualString",false,locRelBasePathOrNULL,options); },
				coreMixin_createStandalone_custom_opt(            locRelBasePathOrNULL,options={})                  { return this._coreMixin_createStandalone_x_common("custom",            false,locRelBasePathOrNULL,options); },
					_coreMixin_createStandalone_x_common(methodSuffix, isReq, locRelBasePathOrNULL, options, otherOptionsDefaultCallback=null)
					{
						B_REST_Utils.object_assert(options);
						
						if (!B_REST_Utils.object_hasPropName(options,"locBasePath") && !B_REST_Utils.object_hasPropName(options,"loc"))
						{
							if (locRelBasePathOrNULL) { options.locBasePath = `components.${this.$options.name}.${locRelBasePathOrNULL}`; }
							else                      { options.loc         = {label:null,shortLabel:null};                               }
						}
						
						options.isRequired = isReq;
						if (!B_REST_Utils.object_hasPropName(options,"isNullable")) { options.isNullable=!isReq; } //Don't force overriding against isReq, in case we want to make a distinction between "" and NULL
						
						if (otherOptionsDefaultCallback) { otherOptionsDefaultCallback(options); }
						
						const fieldName  = options.locBasePath ? options.locBasePath.match(/[^.]+$/)[0] : "noname"; //Keep only the last part
						const methodName = `createStandalone_${methodSuffix}`;
						return B_REST_ModelFields.DB[methodName](fieldName, options); //Ex B_REST_ModelFields.DB.createStandalone_int()
					},
			//Delayed funcs that (for most) will get canceled upon beforeDestroy(), to prevent wrong code from ever happening
				//Called in beforeDestroy(), but can also be called anytime
				coreMixin_clearTimeoutsAndIntervals()
				{
					const length = this.coreMixin_timeoutsAndIntervals.length;
					if (length===0) { return; }
					
					for (let i=0; i<length; i++)
					{
						const loop_obj = this.coreMixin_timeoutsAndIntervals[i];
						
						switch (loop_obj.type)
						{
							case "s":
								clearTimeout(loop_obj.handle);
								if (loop_obj.cancel) { loop_obj.cancel(`coreMixin_clearTimeoutsAndIntervals() throwing for a coreMixin_sleep() w rejectOnCancel=true`); }
							break;
							case "t":
								clearTimeout(loop_obj.handle);
							break;
							case "i":
								clearInterval(loop_obj.handle);
							break;
							default: B_REST_Utils.throwEx(`Unexpected timeout type "${loop_obj.type}"`);
						}
					}
					
					this.coreMixin_timeoutsAndIntervals = [];
				},
				//Instant, so no need to add to queue
				async coreMixin_sleep_nextFrame() { return B_REST_Utils.sleep(0); },
				//Rets Promise. When component destroyed, either never ends or throws (rejects)
				async coreMixin_sleep(msecs,rejectOnCancel=false)
				{
					const promise = new Promise((s,r) =>
					{
						let obj = null;
						
						const handle = setTimeout(() =>
						{
							B_REST_Utils.array_remove_byVal(this.coreMixin_timeoutsAndIntervals,obj); //Rem it when it's done
							s();
						}, msecs);
						
						obj = {type:"s",handle};
						if (rejectOnCancel) { obj.cancel=r; }
						this.coreMixin_timeoutsAndIntervals.push(obj);
					});
					
					return promise;
				},
				//Rets Promise. When component destroyed, either never ends or throws
				async coreMixin_sleep_vuetifyAnimationDuration(rejectOnCancel=false) { return this.coreMixin_sleep(B_REST_VueApp_base.VUETIFY_ANIMATION_DURATION,rejectOnCancel); },
				//Rets native handle. When component destroyed, never ends. If we want to stop timeout manually, don't use native clearTimeout(), use coreMixin_clearTimeout()
				coreMixin_setTimeout(callback,msecs)
				{
					let handle = null;
					
					handle = setTimeout(() =>
					{
						this.coreMixin_clearTimeout(handle); //Do this to rem from arr, now that it's happening
						callback();
					}, msecs);
					
					this.coreMixin_timeoutsAndIntervals.push({type:"t",handle});
					
					return handle;
				},
					coreMixin_clearTimeout(handle) { this._coreMixin_clearXByHandle(handle,clearTimeout); },
						_coreMixin_clearXByHandle(handle, clearXMethod)
						{
							const length = this.coreMixin_timeoutsAndIntervals.length;
							
							for (let i=0; i<length; i++)
							{
								if (this.coreMixin_timeoutsAndIntervals[i].handle===handle)
								{
									clearXMethod(handle);
									B_REST_Utils.array_remove_byIdx(this.coreMixin_timeoutsAndIntervals,i);
									return;
								}
							}
							
							//NOTE: If we get there, the handle wasn't found, but we should maybe not care about it, in case we get a timing prob
						},
				//Rets native handle. When component destroyed, never ends. If we want to stop timeout manually, don't use native clearInterval(), use coreMixin_clearInterval()
				coreMixin_setInterval(callback,msecs)
				{
					const handle = setInterval(callback,msecs); //NOTE: We can't auto remove like we do w coreMixin_setTimeout(), since it's looping forever
					
					this.coreMixin_timeoutsAndIntervals.push({type:"i",handle});
					
					return handle;
				},
					coreMixin_clearInterval(handle) { this._coreMixin_clearXByHandle(handle,clearInterval); },
		},
	};
};
