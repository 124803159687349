
//Check docs in BrRightDrawer.vue



import { B_REST_Utils } from "@/bREST/core/classes";
import B_REST_Vuetify_RightDrawerContent from "./B_REST_Vuetify_RightDrawerContent.js";



export default class B_REST_Vuetify_RightDrawer
{
	static get DISAPPEAR_FLUSH_PROPS_DELAY() { return 500; }
	static get CLOSE_REASON_CLICK_OUTSIDE() { return "clickOutside"; }
	static get CLOSE_REASON_CLICK_CLOSE()   { return "clickClose";   }
	
	static _instance = null; //Singleton
	
	_activeContent      = null;   //Instance of B_REST_Vuetify_RightDrawerContent or NULL. To prevent animation hell, must remain there for a while even after _visible flipped to false
	_visible            = false;  //If _activeContent & want to show it
	_aboveAll           = null;   //If it should appear above all with a grey overlay to the left, or push the <v-main> to the left ?
	_aboveAll_permanent = null;   //Usually when it's aboveAll it should auto close when we click outside, but we could want it to stay there if we have something even higher above, like if we have a drawer for BrGenericListBaseFilters and we want to open a picker. Check usage ex in BrFieldDb::_final_slotConfig_x()
	_closePromise       = null;   //Instance of Promise to wait until we close it. Resolves as async(closeData)
	_closeResolver      = null;   //The resolver of the close promise
	_lastDisappearTimer = null;   //Check docs in close()
	
	constructor() {}
	
	
	static init()
	{
		if (B_REST_Vuetify_RightDrawer._instance) { B_REST_Utils.throwEx(`Right drawer already inited`); }
		
		B_REST_Vuetify_RightDrawer._instance = new B_REST_Vuetify_RightDrawer();
	}
	static get instance()
	{
		if (!B_REST_Vuetify_RightDrawer._instance) { B_REST_Utils.throwEx(`Right drawer not yet inited`); }
		
		return B_REST_Vuetify_RightDrawer._instance;
	}
	
	
	get activeContent()         { return this._activeContent;      }
	get visible()               { return this._visible;            }
	get aboveAll()              { return this._aboveAll;           }
	get aboveAll_permanent()    { return this._aboveAll_permanent; }
	set aboveAll_permanent(val) { this._aboveAll_permanent=val;    }
	
	
	/*
	Usage ex:
			const content = new B_REST_Vuetify_RightDrawerContent("Filters");
			const closeData = await rightDrawer.open_side(content);
		Or:
			const content = new B_REST_Vuetify_RightDrawerContent("Filters");
			rightDrawer.open_side(content);
			const closeData = await rightDrawer.waitClose();
	If it gets closed because the user clicks the close btn or outside of it, then closeData will be like {coreReason} and one of CLOSE_REASON_x
	*/
	async open_side( rightDrawerContent) { return this._open_x(rightDrawerContent,false); }
	async open_modal(rightDrawerContent) { return this._open_x(rightDrawerContent,true);  }
		async _open_x(rightDrawerContent, aboveAll)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Vuetify_RightDrawerContent, rightDrawerContent);
			
			B_REST_Utils.console_todo([
				`If 2 things want to open it at the same time, the first one should get its promise canceled`,
			]);
			
			/*
			Start by making sure old stuff is removed
			We also prevent stuff from never appearing because we tried to open something new too fast
			We also don't unset aboveAll here, to avoid flicker
			*/
			if (this._activeContent)
			{
				//If we already did close() but it's not done yet, just don't make it disappear	
				if (this._lastDisappearTimer)
				{
					clearTimeout(this._lastDisappearTimer);
					this._lastDisappearTimer = null;
				}
				//If it was still opened, leave it opened but mention it got canceled
				else if (this._closeResolver)
				{
					this._closeResolver(/*closeData*/null);
					this._closeResolver = this._closePromise = null;
				}
				
				this._activeContent = null;
			}
			
			//Put them back in the best order possible
			this._activeContent      = rightDrawerContent;
			this._aboveAll           = aboveAll;
			this._aboveAll_permanent = aboveAll ? false : null; //When aboveAll, start not permanent, and if not aboveAll, NULL means N/A
			this._visible            = true;
			
			//Setup a promise so user can wait until it's done
			this._closePromise = new Promise((s,f) =>
			{
				this._closeResolver = s;
			});
			
			return this._closePromise;
		}
	
	async close(closeData=null)
	{
		//Ignore this being called twice by @input when user closes it by himself or that we click on the [x] btn
		if (!this._closePromise) { return; }
		
		this._visible = false;
		
		//Do this to prevent stuff from disappearing while it's sliding out
		this._lastDisappearTimer = setTimeout(() =>
		{
			this._activeContent = null;
			this._aboveAll      = null;
		}, B_REST_Vuetify_RightDrawer.DISAPPEAR_FLUSH_PROPS_DELAY);
		
		//WARNING: Don't put this in the setTimeout above, as it can get canceled before it fires
		this._closeResolver(closeData);
		this._closeResolver = this._closePromise = null;
	}
	
	/*
	So we can do:
		rightDrawer.open_x();
		const closeData = await rightDrawer.waitClose();
	*/
	async waitClose() { return this._closePromise; }
};
