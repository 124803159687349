<!--
	Auto handles email ownership verification process w server's RouteParser_base::requestMatches_coreCalls_verifyX_x()
	NOTE: Can force recoveryEmail_isVerified=true auto by using the B_REST_VueApp_base::autoVerifyRecoveryEmail flag
-->
<template>
	<div>
		<br-field-db :field="recoveryEmail" :tooltip="main_tooltip" @change="onRecoveryEmailChange" :icon4="main_icon" @[canStart?`click:icon4`:null]="startVerificationProcess" :lazy="lazy" />
		
		<v-dialog :value="showDialog" max-width="500px" @input="onCancelDialog">
			<v-card :loading="confirm_loading" :disabled="confirm_loading">
				<v-card-title class="text-h5">{{ t_alt("prompt.title") }}</v-card-title>
				
				<template v-if="notPossibleNow">
					<v-card-text>{{ t_alt("prompt.notPossibleNow") }}</v-card-text>
				</template>
				
				<template v-else>
					<v-card-text>{{ t_alt("prompt.body") }}</v-card-text>
					<v-card-actions>
						<v-row justify="space-between" align="center" no-gutters>
							<v-col cols="auto" style="width:100px">
								<v-text-field v-model="verificationCode" :label="t_alt('prompt.verificationCode')" />
							</v-col>
							<v-col cols="auto">
								<v-btn v-if="!!verificationCode" @[confirm_can?`click`:null]="confirm" color="grey darken-3" class="white--text">
									<v-icon :color="confirm_color" v-text="confirm_icon"/>
									{{ t_alt("prompt.confirm") }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-actions>
					<v-expand-transition>
						<v-card-text v-if="confirm_isWrongCode" class="error--text">{{ t_alt("prompt.wrongCode") }}</v-card-text>
					</v-expand-transition>
				</template>
				
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	
	import { B_REST_Model }              from "../../../../classes";
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	
	const COMPONENT_NAME         = "BrFieldDbRecoveryEmail";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	const STATE_IDLE_UNVERIFIED    = "idleUnverified";
	const STATE_SENDING_EMAIL      = "sendingEmail";
	const STATE_SENT_EMAIL         = "sentEmail";
	const STATE_COULDNT_SEND_EMAIL = "couldntSendEmail";
	const STATE_CONFIRMING         = "confirming";
	const STATE_CONFIRMED          = "confirmed";
	const STATE_CONFIRM_FAILED     = "confirmFailed";
	
	const KEEP_DIALOG_OPEN_AFTER_SUCCESS_TIME = 500; //Not related to a Vuetify animation duration
	
	
	
	export default {
		name: COMPONENT_NAME,
		//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			model: {type:B_REST_Model, required:true}, //Must point to Model_User
		},
		data()
		{
			if (this.model.descriptor.name!=="User") { this.$bREST.throwEx(`Expected model to be Model_User`,this.model); }
			
			const recoveryEmail            = this.model.select("recoveryEmail");
			const recoveryEmail_isVerified = this.model.select("recoveryEmail_isVerified");
			
			return {
				recoveryEmail,
				recoveryEmail_isVerified,
				lazy:             true, //IMPORTANT: Leave this like that, otherwise email field's validation_custom_fastFunc_x() will cause lag
				state:            STATE_IDLE_UNVERIFIED, //One of STATE_x
				verificationCode: null,
				showDialog:       false,
			};
		},
		created()
		{
			this._recoveryEmail_isVerified_onChange();
		},
		computed: {
			main_tooltip()
			{
				if (this.$bREST.autoVerifyRecoveryEmail) { return null; }
				return this.recoveryEmail_isVerified.val ? this.t_alt('tooltip.ok') : this.t_alt('tooltip.bad');
			},
			main_icon()
			{
				if (this.$bREST.autoVerifyRecoveryEmail) { return null; }
				if (this.showDialog) { return "mdi-loading mdi-spin|error"; }
				if (!this.recoveryEmail.isNotEmpty) { return null; } //IMPORTANT: Here, we have to use isNotEmpty and not isNotEmpty_andValid
				return this.recoveryEmail_isVerified.val ? "mdi-check|success" : "mdi-message-alert|error";
			},
			canStart() { return this.state===STATE_IDLE_UNVERIFIED && this.recoveryEmail.isNotEmpty; }, //IMPORTANT: Here, we have to use isNotEmpty and not isNotEmpty_andValid, otherwise what we do in created() will prevent from ever starting
			notPossibleNow() { return this.state===STATE_COULDNT_SEND_EMAIL; },
			//Stuff for confirmation
			confirm_loading() { return this.state===STATE_SENDING_EMAIL||this.state===STATE_CONFIRMING; },
			confirm_can() { return (this.state===STATE_SENT_EMAIL||this.state===STATE_CONFIRM_FAILED) && !!this.verificationCode; },
			confirm_color()
			{
				switch (this.state)
				{
					case STATE_CONFIRMED:      return "success";
					case STATE_CONFIRM_FAILED: return "error";
					default:                   return "black";
				}
			},
			confirm_icon()
			{
				switch (this.state)
				{
					case STATE_CONFIRMED:      return "mdi-check";
					case STATE_CONFIRM_FAILED: return "mdi-alert-circle";
					default:                   return null;
				}
			},
			confirm_isWrongCode() { return this.state===STATE_CONFIRM_FAILED; },
		},
		methods: {
			_recoveryEmail_isVerified_set(val)
			{
				this.recoveryEmail_isVerified.val = val;
				this._recoveryEmail_isVerified_onChange();
			},
				_recoveryEmail_isVerified_onChange()
				{
					this._setState(this.recoveryEmail_isVerified.val ? STATE_CONFIRMED : STATE_IDLE_UNVERIFIED);
				},
			_setState(val)
			{
				this.state = val;
				this.$emit("change:state", val);
			},
			onRecoveryEmailChange()
			{
				this.$emit("change", this.recoveryEmail.val);
				this._recoveryEmail_isVerified_set(this.$bREST.autoVerifyRecoveryEmail);
			},
			async startVerificationProcess()
			{
				this._setState(STATE_SENDING_EMAIL);
				this.verificationCode = null;
				this.showDialog       = true;
				
				const response = await this.$bREST.verifyRecoveryEmail_sendCode(this.recoveryEmail.val);
				if (response.isSuccess) { this._setState(STATE_SENT_EMAIL);         }
				else                    { this._setState(STATE_COULDNT_SEND_EMAIL); }
			},
			async confirm()
			{
				this._setState(STATE_CONFIRMING);
				
				const response = await this.$bREST.verifyRecoveryEmail_confirm(this.recoveryEmail.val, this.verificationCode);
				if (response.isSuccess)
				{
					this._recoveryEmail_isVerified_set(true);
					await this.coreMixin_sleep(KEEP_DIALOG_OPEN_AFTER_SUCCESS_TIME);
					this.showDialog = false;
				}
				else { this._setState(STATE_CONFIRM_FAILED); }
			},
			onCancelDialog()
			{
				this._recoveryEmail_isVerified_onChange();
				this.showDialog = false;
			},
		},
	};
	
</script>