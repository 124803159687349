<template>
	<v-main class="main-layout">
		<v-app-bar app clipped-left :shrink-on-scroll="shrinkAppBarOnScroll" height="64px" color="primary" dark> <!-- Put dark, so icons are easier to see on imgs -->
			<template #img="{ props:imgProps }">
				<v-img src="/_businessFiles/logo.png" class="mt-2" style="height:50px" contain v-bind="imgProps" />
			</template>
			
			<!-- Hamburger -->
			<v-app-bar-nav-icon @click="showLeftDrawer = !showLeftDrawer" />
			
			<v-btn v-if="$bREST.shouldShowBackNavBtn" @click="$bREST.routes_go_back(false)" icon><v-icon>mdi-keyboard-backspace</v-icon></v-btn>
			
			<v-spacer /> <!-- Puts up to 300px(?) of space between title and btns to the right -->
			
			<v-menu bottom left>
				<template #activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-if="$bREST.user_isSudoing" @click="$bREST.sudoOut()">
						<v-list-item-title>{{ _loc("menus.sudoOut") }}</v-list-item-title>
						<v-list-item-action> <v-icon color="deep-purple accent-4">mdi-logout</v-icon> </v-list-item-action>
					</v-list-item>
					<v-list-item v-else @click="$bREST.logout()">
						<v-list-item-title>{{ _loc("menus.logout") }}</v-list-item-title>
						<v-list-item-action> <v-icon color="deep-purple accent-4">mdi-logout</v-icon> </v-list-item-action>
					</v-list-item>
				</v-list>
			</v-menu>
			
		</v-app-bar>
		
		<left-drawer v-model="showLeftDrawer" />
		<v-container>
			<!-- v-xxx-transition. Could improve with https://vuetifyjs.com/en/styles/transitions/#create-your-own -->
			<component :is="routeTransition_componentName" mode="out-in" origin="">
				<router-view />
			</component>
		</v-container>
	</v-main>
</template>

<script>
	
	import {VFadeTransition, VScrollXTransition, VScrollXReverseTransition} from "vuetify/lib";
	const locBasePath = "layouts.modules";
	
	export default {
		components: {
			LeftDrawer: () => import("./LeftDrawer.vue"),
			//For router transition
			VFadeTransition,
			VScrollXTransition,
			VScrollXReverseTransition,
		},
		data()
		{
			return {
				showLeftDrawer: false, //!this.$bREST.uiBreakpoint.mobile, //Or NULL = hidden by default on mobile / We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
			};
		},
		computed: {
			shrinkAppBarOnScroll() { return !this.$bREST.uiBreakpoint.mobile; }, //NOTE: We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
			routeTransition_componentName()
			{
				if      (this.$bREST.routes_current_travelDir_isToChild)  { return "v-scroll-x-reverse-transition"; }
				else if (this.$bREST.routes_current_travelDir_isToParent) { return "v-scroll-x-transition";         }
				return "v-fade-transition";
			},
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
		},
	};
	
</script>