<template>
	<v-dialog :value="pickerHandle.isPrompting" :persistent="pickerHandle.isModal" @input="pickerHandle.cancel()" :max-width="pickerHandle.maxWidth" :fullscreen="isFullscreen">
		<component :is="pickerHandle.def.component" ref="instance" :picker-handle="pickerHandle" v-bind="pickerHandle.vBind" v-on="pickerHandle.vOn" />
	</v-dialog>
</template>

<script>
	
	import B_REST_Vuetify_PickerHandle from "./B_REST_Vuetify_PickerHandle.js";
	
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	
	const COMPONENT_NAME         = "BrPicker";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	
	
	export default {
		name: COMPONENT_NAME,
		//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			pickerHandle: {type:B_REST_Vuetify_PickerHandle, required:true},
		},
		computed: {
			isFullscreen() { return this.$bREST.uiBreakpoint.mobile; }, //NOTE: We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
		},
		async mounted()
		{
			await this.coreMixin_sleep_nextFrame(); //NOTE: Need this because v-dialog's inner <slot> will still not be ready yet
			this.pickerHandle.component_set(this.$refs.instance);
		},
		beforeDestroy() { this.pickerHandle.component_set(null); },
	};
	
</script>