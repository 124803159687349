
import B_REST_Utils             from "../../classes/B_REST_Utils.js";
import B_REST_App_RouteDef_base from "../../classes/app/B_REST_App_RouteDef_base.js";
import B_REST_VueApp_base       from "./B_REST_VueApp_base.js";
import B_REST_VueApp_RouteInfo  from "./B_REST_VueApp_RouteInfo.js";



export default class B_REST_VueApp_RouteDef extends B_REST_App_RouteDef_base
{
	_meta                                = null;  //Obj of custom stuff; stuff will get added to it in the actual Vue Route instances
	_transferComponentProps_forRouteInfo = null;  //Callback as (<B_REST_VueApp_RouteInfo>routeInfo) to transfer props to the viewComponent. Ex (routeInfo) => { return {showTitle:false,fromRouteInfo:routeInfo,somethingElse:routeInfo.pathVars.bob}; }
	
	
	
	constructor(options)
	{
		super(options);
		
		//Make sure that route urls pathVars are as "/something/:someVar/else" and not "/something/{someVar}/else"
		for (const loop_lang in this._langUrls)
		{
			const loop_langUrl = this._langUrls[loop_lang];
			if (loop_langUrl.indexOf("{")!==-1) { B_REST_Utils.throwEx(`Vue Router routes pathVars must be like :someVar and not {someVar}. Got "${loop_langUrl}"`); }
		}
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			meta:                                {accept:[Object],   required:false},
			transferComponentProps_forRouteInfo: {accept:[Function], required:false},
		}, "Vue route def");
		
		this._meta                                = options.meta;
		this._transferComponentProps_forRouteInfo = options.transferComponentProps_forRouteInfo;
	}
	
	
	
	get meta()                                { return this._meta;                                }
	get transferComponentProps_forRouteInfo() { return this._transferComponentProps_forRouteInfo; }
	
	
	_abstract_toRouteInfo(pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		const fullPath = this.toFullPath(pathVars, qsa, hashTag, lang);
		
		return new B_REST_VueApp_RouteInfo(fullPath, this, pathVars, qsa, hashTag, lang);
	}
	
	/*
	For B_REST_VueApp_base's constructor
	WARNING:
		<v-btn> and such "to" prop matches a path by default but not a route name, so we should do:
			<v-btn :to="{name:'someRouteName'}" />
	*/
	convertToVueRouteDefObj(lang)
	{
		const langUrl = this._langUrls[lang];
		if (!langUrl) { B_REST_Utils.throwEx(`Not defining lang URL for lang "${lang}"`,this); }
		
		const alias = [];
		if      (this.type_isPublicLandpage) { alias.push("/"); }
		else if (this.type_isPublic404)      { alias.push("*"); }
		
		const defaultChild = {
			name:       this._name,
			path:       "",
			component:  this._viewComponent,
			props:      (vueRouterObj) =>
						{
							/*
							Called on target for each route change, even if we stay on the same component (ex silently replacing from "/clients/*" to "/clients/123")
							Also, can be called 3x in a row for no reason, so B_REST_VueApp_base::_routerHack_transferComponentPropsToVueRouterObj_last_x() tries to speed things up
							*/
							const props = B_REST_VueApp_base.instance.routes_transferComponentPropsToVueRouterObj_forRouteDef(this,vueRouterObj);
							return props;
						},
			alias,
			meta: {
				bREST_routeDef:this, //Add a way to go back to the B_REST_App_RouteDef_base from the Vue Route
				...this._meta        //Other usage stuff
			},
		};
		
		return {
			path:      langUrl,
			component: this._layoutComponent,
			meta:      {children_bREST_routeDef:this}, //Helper so we can access what's in the children's meta. If needed, could refactor so we just point on the child itself
			children:  [defaultChild],
		};
	}
	
	//Can ret NULL
	static getRouteDefFromVueRouterObj(vueRouterObj)
	{
		B_REST_Utils.object_assert(vueRouterObj);
		
		const meta = vueRouterObj.meta || null;
		
		return meta?.bREST_routeDef || meta?.children_bREST_routeDef || vueRouterObj.parent?.meta?.children_bREST_routeDef || null;
	}
};
