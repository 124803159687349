/*
Check BrFieldDb::_picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK
NOTE: Check note in B_REST_VueApp_base::pickers_getHandle() about pre-filtering todo
*/

import { B_REST_Utils, B_REST_App_base } from "../../../../classes";



export default class B_REST_Vuetify_PickerDef
{
	/*
	For a given picker def intended to use between multiple B_REST_Vuetify_PickerHandle:
		REUSE_MODE_OFF:              Always use independent handles and release them when we're done (best for <BrGenericList> that aren't used in pickers). We will end up with 0 or more active handles for that def at the same time
		REUSE_MODE_IF_NOT_PROMPTING: If we find a handle that's not being used, use it, otherwise temporarily create a second one (best for <BrGenericList> that are used in pickers). We'll end up with 1 or more at the same time. Extra ones are released after
		REUSE_MODE_CANCEL_PREVIOUS:  If we find a handle, use it no matter what we were doing with it, canceling prev action (whether canceling previous usage be a good thing or not depends on the situation). Here, we'll always have exactly 1 allocated
	WARNING:
		By "used in pickers", means in <br-field-db picker="SomePickerDefName" /> and also in generic lists filters like:
			export default {
				name: "TestLeadList",
				mixins: B_REST_Vuetify_GenericListBase_createMixin({
					...
					filters: {
						some_fk: {fieldNamePath:"some_fk", brFieldDbProps:{picker:"SomePickerDefName"}},
							  or {fieldNamePath:"some_fk", picker:"SomePickerDefName"},
					},
				}),
			};
	*/
	static get REUSE_MODE_OFF()              { return "off";            }
	static get REUSE_MODE_IF_NOT_PROMPTING() { return "ifNotPrompting"; }
	static get REUSE_MODE_CANCEL_PREVIOUS()  { return "cancelPrevious"; }
		static _REUSE_MODES = [B_REST_Vuetify_PickerDef.REUSE_MODE_OFF, B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING, B_REST_Vuetify_PickerDef.REUSE_MODE_CANCEL_PREVIOUS];
	
	
	_name                               = null;  //Unique tag, ex "clientList"
	_component                          = null;  //What should be inside the <v-modal>, so a ()=>import("./SomeComponent.vue") or direct component like "import SomeComponent from "./SomeComponent.vue"
	_component_ifGenericList_moduleName = null;  //If component happens to be a BrGenericListBase der and that it has a frontend route def via B_REST_VueApp_base::_routes_define_genericListFormModule(), we can also just provide its module name, ex "client" (not "clientList" nor "client-list")
	_reuseMode                          = null;  //One of REUSE_MODE_x
	//Things that can get overriden in B_REST_Vuetify_pickerHandle that use it
	_default_isModal   = false; //If we are allowed to close it by clicking out of the popup, or if we must make a choice first
	_default_maxWidth  = null;  //Leave NULL to let Vuetify open it the right dimensions (close to 100%, or 100% in mobile mode)
	_default_vBind     = null;  //Stuff to pass as props to the component
	_default_vOn       = null;  //Events to pass to the component
	
	
	constructor(name, options)
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			//Must pass one of the following
			component:                          {accept:undefined, default:null}, //Check var docs
			component_ifGenericList_moduleName: {accept:[String],  default:null}, //Check var docs
			//Other stuff
			reuseMode:        {accept:[String],  required:true}, //One of REUSE_MODE_x
			default_isModal:  {accept:[Boolean], default:false},
			default_maxWidth: {accept:[Number],  default:null},
			default_vBind:    {accept:[Object],  default:null},
			default_vOn:      {accept:[Object],  default:null},
		}, "Picker def");
		
		if (!!options.component === !!options.component_ifGenericList_moduleName) { B_REST_Utils.throwEx(`Must pass either component or component_ifGenericList_moduleName`); }
		
		if (!B_REST_Vuetify_PickerDef._REUSE_MODES.includes(options.reuseMode)) { B_REST_Utils.throwEx(`Reuse mode must be one of REUSE_MODE_x`); }
		
		this._name                               = name;
		this._component                          = options.component;
		this._component_ifGenericList_moduleName = options.component_ifGenericList_moduleName;
		this._reuseMode                          = options.reuseMode;
		this._default_isModal                    = options.default_isModal;
		this._default_maxWidth                   = options.default_maxWidth;
		this._default_vBind                      = options.default_vBind;
		this._default_vOn                        = options.default_vOn;
		
		//NOTE: Doing this 100 times won't slow down the app or cause mem leak. Vue/Webpack will ret the same obj ptr
		if (this._component_ifGenericList_moduleName)
		{
			const moduleList_routeDef = B_REST_App_base.instance.routeDefs_get_moduleList(this._component_ifGenericList_moduleName); //Throws
			this._component           = moduleList_routeDef.viewComponent;
		}
	}
	
	
	get name()                               { return this._name;                                      }
	get component()                          { return this._component;                                 }
	get component_ifGenericList_moduleName() { return this._component_ifGenericList_moduleName;        }
	get component_isGenericList()            { return this._component_ifGenericList_moduleName!==null; }
	get reuseMode()                          { return this._reuseMode;                                 }
	get default_isModal()                    { return this._default_isModal;                           }
	get default_maxWidth()                   { return this._default_maxWidth;                          }
	get default_vBind()                      { return this._default_vBind;                             }
	get default_vOn()                        { return this._default_vOn;                               }
};
