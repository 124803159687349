
import B_REST_Utils                           from "../B_REST_Utils.js";
import B_REST_App_base                        from "../app/B_REST_App_base.js";
import B_REST_Model                           from "./B_REST_Model.js";
import B_REST_Model_ValidationError           from "./B_REST_Model_ValidationError.js";
import B_REST_Model_CustomValidationErrorList from "./B_REST_Model_CustomValidationErrorList.js";
import B_REST_FieldDescriptors                from "../descriptors/B_REST_FieldDescriptors.js";
import B_REST_Descriptor                      from "../descriptors/B_REST_Descriptor.js";
import B_REST_ModelList                       from "./B_REST_ModelList.js";
import B_REST_FileControl                     from "../files/B_REST_FileControl.js";



class B_REST_ModelField_base
{
	_fieldDescriptor             = null;       //Instance of B_REST_FieldDescriptors.x of a type matching the tpye of this field
	_parentModel                 = null;       //Optional; linked B_REST_Model, in case we want to know if the parent model has a PK or not
	_isReadOnly                  = false;      //For that field, but note that we also have parentModel.isReadOnly. In the end, both are used in isMutable()
	_forceIsRequired             = false;      //In usage, sometimes we want the field to be req, even if the main descriptor shouldn't, for edge cases
	_data                        = undefined;  //Where NULL is considered a "set" val, and undefined "not set". What we have here depends on the derived type
	_validation_custom_errorList = null;       //Instance of B_REST_Model_CustomValidationErrorList where the user can toggle errs by himself
	_onChange_hooks              = [];         //Arr of callbacks to trigger when data changes, as (<B_REST_ModelField_base>modelField, newVal,oldVal), where newVal/oldVal makes sense only w B_REST_ModelField_DB
	
	
	constructor(expectedClassName, fieldDescriptor, parentModel=null)
	{
		B_REST_Utils.instance_isOfClass_assert(expectedClassName, fieldDescriptor);
		if (parentModel) { B_REST_Utils.instance_isOfClass_assert(B_REST_Model,parentModel); }
		
		const fieldName = fieldDescriptor.name;
		
		this._fieldDescriptor             = fieldDescriptor;
		this._parentModel                 = parentModel;
		this._validation_custom_errorList = new B_REST_Model_CustomValidationErrorList(this._parentModel ? `models.${this._parentModel.descriptor.name}.fields.${fieldName}.customValidationErrors` : `standaloneFields.${fieldName}.customValidationErrors`);
	}
	
	
	_throwField(msg, details=null) { B_REST_Utils.throwEx(`B_REST_ModelField_base<${this.debugFieldNamePath()}>: ${msg}`, details); }
	
	
	get fieldDescriptor() { return this._fieldDescriptor; } //To know stuff like if it's req or nullable
	get parentModel()     { return this._parentModel;     } //To be able to travel up from sub models to their host field, then to their parent model, etc
	get isSet()           { return this._data!==null;     }
	get isReadOnly()      { return this._isReadOnly;      }
	set isReadOnly(val)   { this._isReadOnly=val;         }
	get forceIsRequired() { return this._forceIsRequired; }
	set forceIsRequired(val)
	{
		this._forceIsRequired = val;
		
		//Update validation
		this._setVal(this._data, /*assertMutable*/false, /*isFromConstructor*/false, /*ignoreIdenticals*/false); //IMPORTANT: Don't set ignoreIdenticals to true, since we're not changing val; we just want to recalc validation
	}
	
	get parentModel_isSaving()
	{
		if (!this._parentModel) { return null; }
		
		return this._parentModel.isSaving||this._parentModel.isSaving_hostModelField||false; //Convert NULL into false
	}
	//Only for self
	get hasAsyncTasks() { return this._abstract_hasAsyncTasks; }
		//Must ret bool
		get _abstract_hasAsyncTasks() { this._throwField(`Must override base method`); }
	//NOTE: Includes self
	get hasAsyncTasks_siblingFields()
	{
		if (!this._parentModel) { return null; }
		
		return this._parentModel.fields_haveAsyncTasks;
	}
	//Only for self
	async awaitAsyncTasks() { return this._abstract_awaitAsyncTasks(); }
		async _abstract_awaitAsyncTasks() { this._throwField(`Must override base method`); }
	
	//Even if it's not read only, it also depends about other things in the model
	get isMutable()
	{
		if (this._isReadOnly) { return false; }
		
		//If we've got no parent model, then we don't know if this field exists in the DB or not, so we can't know if it's a new field or existing field, so ignore its setOnce config
		if (!this._parentModel) { return true; }
		
		//If model doesn't want to be mutable at all, then child shouldn't
		if (!this._parentModel.isMutable) { return false; }
		
		const setOnce = this._fieldDescriptor.setOnce; //One of B_REST_FieldDescriptors.base.SET_ONCE_x
		
		//If we always allow mod
		if (setOnce===B_REST_FieldDescriptors.base.SET_ONCE_OFF) { return true; }
		
		//Without a AUTO_INC, even if PK is set, we won't be able to tell if record already exists in DB or not, so always allow
		if (!this._fieldDescriptor.descriptor?.isAutoInc) { return true; } //NOTE: If this B_REST_FieldDescriptors.base had no B_REST_Descriptor ptr, then it'll eval to undefined, so would also ret true
		
		//No matter what we want to limitate, if it's an AUTO_INC that's not yet saved, then it's always OK
		if (!this._parentModel.pk_isSet) { return true; }
		
		//NOTE: We're not checking for this._fieldDescriptor.wCustomSetter. Check its docs for why
		
		//If we get there, it means we're with a AUTO_INC model that already exists in DB, and we want to limit when we can set
		{
			//If it's too late
			if (setOnce===B_REST_FieldDescriptors.base.SET_ONCE_NOW) { return false; }
			
			//Else it's SET_ONCE_LATER, so it'll depend on if it was already set at the moment of last save. Let derived handle that
			return this._abstract_isMutable_checkWhenIsExistingModelWithSetOnceLater();
		}
	}
		//Must ret bool
		_abstract_isMutable_checkWhenIsExistingModelWithSetOnceLater() { this._throwField(`Must override base method`); }
	
	
	
	//Do this to reduce risk that people try to do B_REST_Model::select(<fieldName>).val on fields other than B_REST_ModelField_DB
	get val()    { this._throwField("Can only use that in B_REST_ModelField_DB fields"); }
	set val(val) { this._throwField("Can only use that in B_REST_ModelField_DB fields"); }
	
	
	
	//ON CHANGE HOOKS RELATED
		onChange_hooks_add(callback)    { this._onChange_hooks.push(callback);                            }
		onChange_hooks_remove(callback) { B_REST_Utils.array_remove_byVal(this._onChange_hooks,callback); }
	
	
	
	//LOC RELATED
		get label()      { return this._fieldDescriptor.label;      }
		get shortLabel() { return this._fieldDescriptor.shortLabel; } //NOTE: If not set, actually points to "label"
		//Rets something like: "<Lead>/mainUser<User>/coords<Coordinate>/address"
		debugFieldNamePath()
		{
			return this._parentModel ? `${this._parentModel.debugFieldNamePath()}/${this._fieldDescriptor.name}` : this._fieldDescriptor.name;
		}
	
	
	
	//VALIDATION RELATED
		get validation_custom_errorList() { return this._validation_custom_errorList; }
		//Whether we have no error msgs nowhere at all
		get validation_isValid()          { return this.validation_getErrors(/*detailed*/false,/*onlyOne*/true,/*includeAsyncCustomErrors*/true).length ===0; }
		get validation_isValid_fastOnly() { return this.validation_getErrors(/*detailed*/false,/*onlyOne*/true,/*includeAsyncCustomErrors*/false).length===0; }
			//Always ret an arr, either of B_REST_Model_ValidationError instances or just the err msgs
			validation_getErrors(detailed=true, onlyOne=false, includeAsyncCustomErrors=true)
			{
				//First start w custom errors in validation_custom_errorList
				const errors = B_REST_Model_ValidationError.getCustomValidationErrors(this, detailed,onlyOne,includeAsyncCustomErrors);
				
				return this._abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors);
			}
				//Must continue algo the same way as B_REST_Model::validation_getErrors()
				_abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors) { this._throwField(`Must override base method`); }
	
	
	
	//UNSAVED CHANGES RELATED
		//Check docs in B_REST_Model::unsavedChanges_x()
		get unsavedChanges_has()              { return this._abstract_unsavedChanges_has;      }
			get _abstract_unsavedChanges_has(){ this._throwField(`Must override base method`); }
		unsavedChanges_unflag(options)
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				cleanupDeletions: {accept:[Boolean], required:true},
				filesOnly:        {accept:[Boolean], default:false}, //A bit like toObj()
			}, "B_REST_ModelField_base::unsavedChanges_unflag()");
			
			return this._abstract_unsavedChanges_unflag(options);
		}
			_abstract_unsavedChanges_unflag(options) { this._throwField(`Must override base method`); }
		unsavedChanges_flag()                        { return this._abstract_unsavedChanges_flag();   }
			_abstract_unsavedChanges_flag()          { this._throwField(`Must override base method`); }
	
	
	
	//USER TOUCH RELATED
		//Check docs in B_REST_Model::userTouch_x()
		get userTouch_has()                     { return this._abstract_userTouch_has;             }
			get _abstract_userTouch_has()       { this._throwField(`Must override base method`);   }
		userTouch_toggle(touched)               { return this._abstract_userTouch_toggle(touched); }
			_abstract_userTouch_toggle(touched) { this._throwField(`Must override base method`);   }
	
	
	
	//FROM / TO OBJ RELATED
		//Note: Depending on derived class, we might want to receive or output an obj, an arr, primitive, NULL, etc
		fromObj(obj,skipIllegalChanges) { this._abstract_fromObj(obj,skipIllegalChanges); }
			_abstract_fromObj(obj,skipIllegalChanges) { this._throwField(`Must override base method`); }
		//Check B_REST_Model::toObj() for options
		toObj(options)
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				onlyWithUnsavedChanges: {accept:[Boolean], required:true}, //Check B_REST_Model::toObj() docs
				forAPICall:             {accept:[Boolean], required:true}, //Check B_REST_Model::toObj() docs
				filesOnly:              {accept:[Boolean], default:false}, //For when we have a !isNew model w B_REST_ModelField_File that has pendingUploads or files to delete we'd want to commit to the server, without commiting other fields changes. Should put onlyWithUnsavedChanges=true
			}, "B_REST_ModelField_base::toObj()");
			
			if (!this.isSet) { this._throwField(`Can't do toObj() because field isn't set`); }
			return this._abstract_toObj(options); //For sub models, if we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
		}
			_abstract_toObj(options) { this._throwField(`Must override base method`); }
	
	
	
	//MISC
		//NOTE: To cascade wipe w/o marking unsaved changes, so equal to unSet() and not clear() (for DB fields)
		nullify() { this._abstract_nullify(); }
			_abstract_nullify() { this._throwField(`Must override base method`); }
};






class B_REST_ModelField_WithFuncs_base extends B_REST_ModelField_base
{
	constructor(expectedClassName, fieldDescriptor, parentModel=null) { super(expectedClassName,fieldDescriptor,parentModel); }
	
	
	
	_abstract_isMutable_checkWhenIsExistingModelWithSetOnceLater() { return true; } //For now we only care if B_REST_FieldDescriptors.DB
};






class B_REST_ModelField_WithFuncs_WithModels_base extends B_REST_ModelField_WithFuncs_base
{
	constructor(expectedClassName, fieldDescriptor, parentModel=null) { super(expectedClassName,fieldDescriptor,parentModel); }
	
	
	get subModel_canBeMutable() { return this._abstract_subModel_canBeMutable; }
		_abstract_subModel_canBeMutable() { this._throwField(`Must override base method`); }
	
	//VALIDATION RELATED
		//Check B_REST_Model funcs with the same names
		validation_custom_recalc_fast(recurseUp=false, recurseDown=true)          { this._abstract_validation_custom_recalc_fast(recurseUp,recurseDown);        }
			_abstract_validation_custom_recalc_fast(recurseUp, recurseDown)       { this._throwField(`Must override base method`);                              }
		async validation_custom_recalc_wait(recurseUp=false, recurseDown=true)    { return this._abstract_validation_custom_recalc_wait(recurseUp,recurseDown); }
			async _abstract_validation_custom_recalc_wait(recurseUp, recurseDown) { this._throwField(`Must override base method`);                              } //Must ret a Promise
};






class B_REST_ModelField_SubModel_base extends B_REST_ModelField_WithFuncs_WithModels_base
{
	_fkFieldVal = null; //Val of a parent model (even if we want to work with it here)'s PK that is used as an FK in this model/list
	
	constructor(expectedClassName, fieldDescriptor, parentModel=null)
	{
		super(expectedClassName, fieldDescriptor, parentModel);
		
		//NOTE: Here, if we've got parentModel set, we could crawl it to try to set _fkFieldVal, but if the parent model's got 100 subModel fields and they all try to crawl it, it'd be too slow
	}
	
	
	
	get fkFieldVal() { return this._fkFieldVal; }
	set fkFieldVal(val)
	{
		this._fkFieldVal = val;
		this._abstract_fkFieldVal_onSet();
	}
		_abstract_fkFieldVal_onSet() { B_REST_Utils.throwEx(`Must override base method`); }
		_fkFieldVal_propagate(model)
		{
			const fkField = model.select(this._fieldDescriptor.subModel_fkFieldName);
			
			fkField.val = this._fkFieldVal;
			
			/*
			If we don't want to bloat DB everytime we alloc a single sub model or add to a list, w/o setting other fields,
			then unflag unsaved changes that setting the fkField triggered.
			This is useful ex in a treeview where we want to create "fake" entries at every leaf point and only save the ones we did fill more than just the FK
			*/
			if (!B_REST_Model.PROPAGATE_PK_FLAG_UNSAVED_CHANGES) { fkField.unsavedChanges_unflag({cleanupDeletions:false}); } //Actually cleanupDeletions has nothing to do here
		}
	
	get _abstract_subModel_canBeMutable()
	{
		if (this._fieldDescriptor.isShared) { return false; }
		
		/*
		WARNING:
			We might not want to be able to change -which- model we're referring to, but we still normally want to be able to alter its inner fields vals.
			However if the PARENT model is RO, then every nested field should turn RO as well, which is what we do here
			If we want to review behavior, make sure it doesn't break when we have subModel & private lookups where we need to be able to mutate inner fields.
		*/
		if (this._parentModel && !this._parentModel.isMutable) { return false; }
		
		return true;
	}
};






class B_REST_ModelField_DB extends B_REST_ModelField_base
{
	static get FROM_OBJ_ASSERT_MUTABLE() { return true; } //Concern was trying to set PK for setOnce fields, but it's just gonna turn immutable after we've set PK, so all cases should be fine
	static get UNSET_VAL() { return null; } //Could be undefined, but otherwise all fields that are not TYPE_STRING are NULL when "not set", instead of TYPE_STRING that has the transition between "" and NULL
	static get UPDATE_OLD_VAL_ON_IDX_OR_KEY_CHANGE() { return false; } //For now shouldn't, as otherwise _oldVal would be updated when we change inner data in a regular obj but ex not in nested TYPE_JSON or TYPE_CUSTOM. Also, could slow down a lot when replacing lots of idx/key in batch
	
	
	_unsavedChanges_has       = false;
	_userTouch_has            = false;
	_validation_type_errorMsg = null; //Type related error msg, in addition to the base _validation_custom_errorList. NOTE: Parsed via B_REST_Model_ValidationError::getCustomValidationErrors()
	_min                      = null; //For numeric, string length & Date ranges. Overrides B_REST_FieldDescriptor_DB's one
	_max                      = null; //For numeric, string length & Date ranges. Overrides B_REST_FieldDescriptor_DB's one
	//Helper for isMutable
	_isFKInSubModel = false; //When we have a parent model w a sub model (single/list), we propagete the parent PK into the sub model's FK; is this such a field ?
	
	_data_proxy = null; //For TYPE_ARR & TYPE_MULTILINGUAL_STRING. Puts reactivity into trying to alter directly inner data that wouldn't trigger _setVal() directly
	_oldVal     = null; //Each time _data is changed, we also update this. WARNING: Check status of UPDATE_OLD_VAL_ON_IDX_OR_KEY_CHANGE for behavior w arr & obj key changes
	
	
	constructor(fieldDescriptor, parentModel=null)
	{
		super(B_REST_FieldDescriptors.DB, fieldDescriptor, parentModel);
		
		//For isMutable()
		this._isFKInSubModel = this._parentModel?.hostModelField && this._parentModel.hostModelField instanceof B_REST_ModelField_SubModel_base;
		
		/*
		Don't leave val undefined; either switch it to NULL or "", against the TYPE_x of the field descriptor
		Will also trigger validation error to calculate properly, in advance of touching field later
		*/
		this._setVal(B_REST_ModelField_DB.UNSET_VAL, /*assertMutable*/false, /*isFromConstructor*/true, /*ignoreIdenticals*/true);
	}
		/*
		Ex for Vue implementation, to allow using <br-field-db> easily when we have no model / field and we want to make ex a custom dropdown / email / pwd field bound to nothing
		Options:
			{
				isRequired:       bool | NULL
				isNullable:       bool | NULL
				wCustomSetter:    bool | NULL
				setOnce:          One of B_REST_FieldDescriptor_base::SET_ONCE_x. Default B_REST_FieldDescriptor_base.SET_ONCE_OFF
				locBasePath:      We can define loc either w a loc path for B_REST_App_base::t_custom(), or w a an obj like {label, shortLabel:NULL, enum:NULL|{a,b,c}} for the "loc" prop below
				loc:              Check docs for locBasePath
				isPKField:        bool | false
				optionalVal:      NULL
				min:              For numeric, string length & Date ranges | NULL
				max:              For numeric, string length & Date ranges | NULL
				decimals:         Only for TYPE_DECIMAL | 2
				enum_members:     Either a piped string, or arr of B_REST_FieldDescriptor_DB_EnumMember instances
				pwd_evalStrength: Bool saying if for TYPE_PWD we want a strength bar meter to validate it's strong enough
				lookupInfo:       If ex we want this to be a picker on some other model. As {modelName,fieldName:null}. Check below for where to find docs
			}
			For docs, check B_REST_FieldDescriptor_DB constructor docs + base ones
		*/
			static createStandalone_string(            fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_STRING,             options); }
			static createStandalone_int(               fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_INT,                options); }
			static createStandalone_decimal(           fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_DECIMAL,            options); }
			static createStandalone_bool(              fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_BOOL,               options); }
			static createStandalone_json(              fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_JSON,               options); }
			static createStandalone_dt(                fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_DT,                 options); }
			static createStandalone_d(                 fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_D,                  options); }
			static createStandalone_t(                 fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_T,                  options); }
			static createStandalone_cStamp(            fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_C_STAMP,            options); }
			static createStandalone_uStamp(            fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_U_STAMP,            options); }
			static createStandalone_enum(              fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_ENUM,               options); }
			static createStandalone_phone(             fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_PHONE,              options); }
			static createStandalone_email(             fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_EMAIL,              options); }
			static createStandalone_pwd(               fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_PWD,                options); }
			static createStandalone_arr(               fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_ARR,                options); }
			static createStandalone_multilingualString(fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_MULTILINGUAL_STRING,options); }
			static createStandalone_custom(            fieldName, options) { return B_REST_ModelField_DB.createStandalone_x(fieldName,B_REST_FieldDescriptors.DB.TYPE_CUSTOM,             options); }
				static createStandalone_x(fieldName, type, options)
				{
					const fieldDescriptor = new B_REST_FieldDescriptors.DB(fieldName, type, options);
					return new B_REST_ModelField_DB(fieldDescriptor);
				}
	
	
	
	/*
	Override of the base one, because we need to add exceptions:
		1) When we have a parent model w a sub model (single/list), when we later propagete the parent PK into the sub models FKs, it should never break on these FKs
			-> Taken care here
		2) If we have a lookup field and we're trying to set the FK field, it shouldn't matter if we're setting it to the same val of the lookup's PK
			-> Taken care in _setVal()
	*/
	get isMutable() { return this._isFKInSubModel || super.isMutable; }
	
	//WARNING: Check status of UPDATE_OLD_VAL_ON_IDX_OR_KEY_CHANGE for behavior w arr & obj key changes
	get oldVal() { return this._oldVal; }
	/*
	Helper to set back val to oldVal, not bypassing validation nor reverting unsaved changes
	WARNING: Check status of UPDATE_OLD_VAL_ON_IDX_OR_KEY_CHANGE for behavior w arr & obj key changes
	*/
	revertVal() { this.val=this._oldVal; }
	
	//May ret undefined
	get val()
	{
		/*
		Special cases to be able to deep track if we have changes, essentially a copy of related things in _setVal()
		We also have val_currentLang(), val_<lang>(), val_get_inXLang(), val_set_inXLang() etc helpers for TYPE_MULTILINGUAL_STRING
		*/
		if (this._fieldDescriptor.type_is_arr || this._fieldDescriptor.type_is_multilingualString) { return this._data_proxy; }
		if (this._fieldDescriptor.type_is_custom && this._data_proxy!==null)                       { return this._data_proxy; }
		
		return this._data;
	}
		//Helpers for TYPE_MULTILINGUAL_STRING. Ret NULL when that lang isn't set / whole field is NULL
			get val_currentLang() { return this.val_get_inXLang(B_REST_App_base.instance.locale_lang); }
			get val_fr()          { return this.val_get_inXLang("fr");                                 }
			get val_en()          { return this.val_get_inXLang("en");                                 }
			get val_es()          { return this.val_get_inXLang("es");                                 }
			val_get_inXLang(lang) //If we want to iterate on all langs, use B_REST_App_base::CORE_LANGS
			{
				if (!this._fieldDescriptor.type_is_multilingualString) { this._throwField(`Can only do that on TYPE_MULTILINGUAL_STRING`); }
				
				return this._data[lang]; //Could be NULL, but at least the obj should always contain that lang
			}
	/*
	Changes the val, even if it's invalid
	Validation notes:
		Check B_REST_FieldDescriptors.DB::validation_type_errorMsg_eval() for what to do with NULL vs empty strings
		For TYPE_CUSTOM, validation will have to be done in B_REST_Descriptor::validation_custom_xFunc() or B_REST_Model::validation_custom_xFunc()
	Unsaved changes notes:
		Each time we change val, it'll be flagged as having unsaved changes.
		After a successful API call, we should unflag the changes
	User touches notes:
		Even if we change the val programmatically, it doesn't count as user touches; status must be set via UI with userTouch_toggle()
	WARNING FOR NULL VS "" and textual numbers vs actual numbers:
		Check notes in B_REST_FieldDescriptor_DB::validation_type_normalizeVal()
	*/
	set val(val) { this._setVal(val,/*assertMutable*/true,/*isFromConstructor*/false,/*ignoreIdenticals*/true); }
		//Helpers for TYPE_MULTILINGUAL_STRING
			set val_currentLang(val) { return this.val_set_inXLang(B_REST_App_base.instance.locale_lang,val); }
			set val_fr(val)          { return this.val_set_inXLang("fr",val);                                 }
			set val_en(val)          { return this.val_set_inXLang("en",val);                                 }
			set val_es(val)          { return this.val_set_inXLang("es",val);                                 }
			val_set_inXLang(lang, val) //If we want to iterate on all langs, use B_REST_App_base::CORE_LANGS
			{
				if (!this._fieldDescriptor.type_is_multilingualString) { this._throwField(`Can only do that on TYPE_MULTILINGUAL_STRING`); }
				if (val==="") { val=null; } //WARNING: Conversion of ""->NULL in B_REST_ModelField_DB::val_set_inXLang(), B_REST_ModelField_DB::_val_arrObjProxy_onAfterChanged() & B_REST_FieldDescriptor_DB::validation_type_normalizeVal()
				const oldVal     = this._data[lang];
				this._data[lang] = val;
				this._val_arrObjProxy_onAfterChanged("set", lang, oldVal, val); //Do this, to recalc validation etc
			}
		//NOTE: For TYPE_MULTILINGUAL_STRING, setting to NULL will convert into {fr:null, en:null, es:null}
		_setVal(val, assertMutable, isFromConstructor=false, ignoreIdenticals=true)
		{
			//WARNING: Any change here could have impacts in _val_arrObjProxy_onAfterChanged()
			
			//Check notes in B_REST_FieldDescriptor_DB::validation_type_normalizeVal(). Converts NULL into "" or vice versa depending on type
			val = this._fieldDescriptor.validation_type_normalizeVal(val);
			
			//Ignore identicals, to speed up
			if (ignoreIdenticals && this._data===val) { return; }
			
			/*
			NOTE:
				After an API call, it's possible we have to alter some data, including AUTO_INC PK
				Setting PK shouldn't be a prob even if we have mutability concerns, because that status will only change AFTER the PK is set
			*/
			if (assertMutable && !this.isMutable)
			{
				let ignoreErr = false;
				
				/*
				We need to add exceptions:
					1) When we have a parent model w a sub model (single/list), when we later propagete the parent PK into the sub models FKs, it should never break on these FKs
						-> Taken care in isMutable() override
					2) If we have a lookup field and we're trying to set the FK field, it shouldn't matter if we're setting it to the same val of the lookup's PK
						-> Taken care here
				*/
				if (this._fieldDescriptor.lookup_fieldName && this._parentModel.select_isUsed(this._fieldDescriptor.lookup_fieldName))
				{
					const modelOrNULL = this._parentModel.select(this._fieldDescriptor.lookup_fieldName);
					ignoreErr = val=== (modelOrNULL?.pk ?? null);
				}
				
				if (!ignoreErr) { this._throwField(`Can't change val because it's not mutable`); }
			}
			
			//Update type related error msg, but continue even if we've got errs
			if (!this._isFKInSubModel)
			{
				this._validation_type_errorMsg = this._fieldDescriptor.validation_type_errorMsg_eval(val, this, this._forceIsRequired);
			}
			
			/*
			Special cases to be able to deep track if we have changes, essentially a copy of related things in _setVal()
			We also have val_currentLang(), val_<lang>(), val_get_inXLang(), val_set_inXLang() etc helpers for TYPE_MULTILINGUAL_STRING
			WARNING:
				Used to have a bug where as soon as we set up the proxy, _val_arrObjProxy_onAfterChanged() was called right away, so validation fucked.
					Ex:
						A req field w proxy starts as NULL, and isn't in the UI, so we can POST the record wo that field, and we get [1,2,3] back from the server
							(ex Model_Event::occurrences_dates in CPA, being calc in server on creation).
						Save gets server response and calls toObj() to pass back ex [1,2,3] to that field, calling _setVal().
						So we'd receive val=[1,2,3] here, then the above "this._validation_type_errorMsg=this._fieldDescriptor.validation_type_errorMsg_eval(val,...)" would run,
							so validation err msg would become NULL.
						But then, right away the data proxy would be re-configurated and fired w the old NULL val, putting back _validation_type_errorMsg to "X is req"
						Figured out that when we setup proxy, it's fired once and we get "__proto__" in the idx param, so we tweaked B_REST_Utils::array_setupWatchProxy()
							to skip firing listener when it's for "__proto__"... which does seem to mean "now". If it doesn't always match, then we'll need to add a prop
							in B_REST_ModelField_DB to say to "return;" at the beginning of _val_arrObjProxy_onAfterChanged() when it's fired right away...
			*/
			{
				if (val===null) { this._data_proxy=null; }
				else if (this._fieldDescriptor.type_is_arr || (this._fieldDescriptor.type_is_custom && B_REST_Utils.array_is(val)))
				{
					this._data_proxy = B_REST_Utils.array_setupWatchProxy(val, (action,idx,oldVal,newVal)=>this._val_arrObjProxy_onAfterChanged(action,idx,oldVal,newVal));
				}
				else if (this._fieldDescriptor.type_is_multilingualString || (this._fieldDescriptor.type_is_custom && B_REST_Utils.object_is(val)))
				{
					this._data_proxy = B_REST_Utils.object_setupWatchProxy(val, (action,key,oldVal,newVal)=>this._val_arrObjProxy_onAfterChanged(action,key,oldVal,newVal));
				}
			}
			
			this._oldVal = this._data;
			this._data   = val;
			
			//Stuff to do after having set the field's data - might throw
			{
				if (!this._unsavedChanges_has && !isFromConstructor) { this._unsavedChanges_has=true; } //NOTE: Do this way to help reduce reactivity payload in modern frameworks
				
				if (this._fieldDescriptor.isPKField && !isFromConstructor)
				{
					if (val===undefined || val===null) { this._throwField(`Trying to unset PK`); } //However, maybe NULL could be a valid PK val in some rare cases...
					
					if (this._parentModel) { this._parentModel.pk_propagateSingleSetValToSubModels(); }
				}
				
				//If we're a FK for a lookup, check to set/unset its model, if possible
				if (this._fieldDescriptor.lookup_fieldName) { this.lookup_updateBoundField(); }
				
				//If we've got a parent model, check to recalc extra validation (even when isFromConstructor)
				if (this._parentModel) { this._parentModel.validation_custom_recalc_fast(/*recurseUp*/true, /*recurseDown*/false); }
			}
			
			/*
			If external stuff is trying to listen to field's val changing, fire their callbacks now
			WARNING:
				This won't be done if we throw an error in the above block
				However we shouldn't call the hooks directly after this._data=val, because we still have things to do like PK propagation and updating validation msgs
			*/
			for (const loop_onChange_hooks of this._onChange_hooks) { loop_onChange_hooks(this,this._data,this._oldVal); }
		}
			//Essentially a copy of related things in _setVal()
			_val_arrObjProxy_onAfterChanged(action, idxOrKey, oldVal=undefined, newVal=undefined)
			{
				//WARNING: Any change here could have impacts in _setVal()
				
				if (!this.isMutable) { this._throwField(`Can't change val because it's not mutable`); }
				
				if (B_REST_ModelField_DB.UPDATE_OLD_VAL_ON_IDX_OR_KEY_CHANGE)
				{
					if (this._data!==null)
					{
						if      (B_REST_Utils.array_is(this._data))  { this._oldVal = [...this._data];                                    }
						else if (B_REST_Utils.object_is(this._data)) { this._oldVal = B_REST_Utils.object_copy(this._data,/*bDeep*/true); }
						else                                         { this._throwField(`Unexpected data type`);                          }
						
						this._oldVal[idxOrKey] = oldVal;
					}
					else { this._oldVal=null; }
				}
				
				//Check notes in B_REST_FieldDescriptor_DB::validation_type_normalizeVal(). Converts NULL into "" or vice versa depending on type
				if (this._fieldDescriptor.type_is_multilingualString && action==="set" && newVal==="") { this._data[idxOrKey]=null; } //WARNING: Conversion of ""->NULL in B_REST_ModelField_DB::val_set_inXLang(), B_REST_ModelField_DB::_val_arrObjProxy_onAfterChanged() & B_REST_FieldDescriptor_DB::validation_type_normalizeVal()
				
				this._validation_type_errorMsg = this._fieldDescriptor.validation_type_errorMsg_eval(this._data, this, this._forceIsRequired);
				
				//If we've got a parent model, check to recalc extra validation (even when isFromConstructor)
				if (this._parentModel) { this._parentModel.validation_custom_recalc_fast(/*recurseUp*/true, /*recurseDown*/false); }
				
				if (!this._unsavedChanges_has) { this._unsavedChanges_has=true; } //NOTE: Do this way to help reduce reactivity payload in modern frameworks
			}
	/*
	Shortcuts to setting to NULL (and maybe switching back to "" for strings), no matter if isRequired or !isNullable.
	Doesn't removing unsaved changes, user touches or put back to undefined (if we wanted to, use unSet() instead)
	Can throw if we're not supposed to be able to alter field anymore
	NOTE: For TYPE_MULTILINGUAL_STRING, setting to NULL will convert into {fr:null, en:null, es:null}
	*/
	clear() { this._setVal(B_REST_ModelField_DB.UNSET_VAL,/*assertMutable*/true,/*isFromConstructor*/false,/*ignoreIdenticals*/true); }
		//Helpers for TYPE_MULTILINGUAL_STRING
			clear_currentLang() { return this.val_set_inXLang(B_REST_App_base.instance.locale_lang,null); }
			clear_fr()          { return this.val_set_inXLang("fr",null);                                 }
			clear_en()          { return this.val_set_inXLang("en",null);                                 }
			clear_es()          { return this.val_set_inXLang("es",null);                                 }
			clear_inXLang(lang) { return this.val_set_inXLang(lang,null);                                 } //If we want to iterate on all langs, use B_REST_App_base::CORE_LANGS
	//Override base method, otherwise all fields that are not TYPE_STRING are NULL when "not set", instead of TYPE_STRING that has the transition between "" and NULL
	get isSet() { return this._data!==B_REST_ModelField_DB.UNSET_VAL; }
	//Funcs including being NULL & "". For TYPE_MULTILINGUAL_STRING, is empty if not ALL langs are set
	get isEmpty()
	{
		//Optimization notes: not calling a getter from another getter (isEmpty vs isNotEmpty)
		
		if (this._data===undefined||this._data===B_REST_ModelField_DB.UNSET_VAL||this._data==="") { return true; }
		
		if (this._fieldDescriptor.type_is_arr) { return this._data.length===0; }
		else if (this._fieldDescriptor.type_is_multilingualString)
		{
			for (const loop_lang of B_REST_App_base.instance.appLangs)
			{
				const loop_langVal = this._data[loop_lang] ?? null;
				if (loop_langVal===null || loop_langVal==="") { return true; }
			}
		}
		
		return false;
	}
	//For TYPE_MULTILINGUAL_STRING, is not empty if ALL langs are set
	get isNotEmpty()
	{
		//Optimization notes: not calling a getter from another getter (isEmpty vs isNotEmpty)
		
		if (this._data===undefined||this._data===B_REST_ModelField_DB.UNSET_VAL||this._data==="") { return false; }
		
		if (this._fieldDescriptor.type_is_arr) { return this._data.length>0; }
		else if (this._fieldDescriptor.type_is_multilingualString)
		{
			for (const loop_lang of B_REST_App_base.instance.appLangs)
			{
				const loop_langVal = this._data[loop_lang] ?? null;
				if (loop_langVal===null || loop_langVal==="") { return false; }
			}
		}
		
		return true;
	}
	get isNotEmpty_andValid() { return this.isNotEmpty && this.validation_isValid; }
	/*
	Removes unsavedChanges flag + touchs as well. If we just wanted to put back to NULL, use clear() instead
	Contrary to clear, doesn't throw when we're not supposed to be able to alter field anymore
	*/
	unSet()
	{
		this._unsavedChanges_has = false;
		this.userTouch_toggle(false);
		
		this._oldVal = this._data;
		this._data   = B_REST_ModelField_DB.UNSET_VAL;
		
		//If we're a FK for a lookup, check to unset its model
		if (this._fieldDescriptor.lookup_fieldName) { this.lookup_updateBoundField(); }
	}
	
	/*
	For cases where we got a string like "2022-01-18T13:35:57.083Z" or anything else, try to convert it
	Throws if field type isn't date/time, rets NULL if not set
	*/
	get dtVal_asDate() { return this._fieldDescriptor.dtVal_toDate(this._data); }
	//Depending on the TYPE_D/T, either returns as Y-m-dTH:i(:s), Y-m-d or H:i(:s) strings
	get dtVal_asHTML5InputVal() { return this._fieldDescriptor.dtVal_toHTML5InputVal(this._data); }
	
	/*
	Outputs the val for human readability contexts
	We can pass options like the following, to either put new vals to them or make them "":
		{
			bool_true:  "✔",
			bool_false: "✖",
			pwd_format: "******",
			custom_func(val) {}
		}
	*/
	valToText(options=null) { return this._fieldDescriptor.valToText(this._data,options); }
	//Only for TYPE_INT & TYPE_DECIMAL, converts string/numeric val to number, and if it's empty/NaN, converts it to a fallback val
	valToNumber(valIfNaN=0) { return this._fieldDescriptor.valToNumber(this._data,valIfNaN); }
	
	/*
	We do [raw pwd] > [frontend to backend encryption] > [db encryption]
	So API calls never show raw pwd
	Ex "pwd" -> "<intermediate>6a4b49f07b599056dc1dc08d2c68afd8c2dd49af1c346fb51c7d8d56576354a6e2608e8e161151cb92886e4fbde45ac9e4c1a69bbcf0566cce108abc0200e60a"
	For more info, check server's CryptoUtils
	NOTE:
		There's also a new native API, but the prob is that it's async: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
	*/
	pwdVal_toFrontendHash() { return this._fieldDescriptor.pwdVal_toFrontendHash(this._data); }
	
	/*
	Let's say this DB field has an arr of enum_members like:
		[
			new B_REST_FieldDescriptors.DB_EnumMember("f", extraData=null),
			new B_REST_FieldDescriptors.DB_EnumMember("m", extraData=null),
		]
	If current val is "f", then it'd return the 1st instance
	Rets null if current val isn't valid
	Throws if field not set
	*/
	get enum_member()
	{
		if (!this.isSet)                         { this._throwField(`Field not set`);                          }
		if (!this._fieldDescriptor.type_is_enum) { this._throwField(`Can only do that with TYPE_ENUM fields`); }
		
		return this._fieldDescriptor.enum_getMember_fromTag(this._data);
	}
	//Throws if field not set
	get enum_label() { return this.enum_member?.label || null; }
	
	
	get min()    { return this._min; }
	set min(val) { this._min=val;    }
	
	get max()    { return this._max; }
	set max(val) { this._max=val;    }
	
	
	
	//LOOKUP RELATED
		/*
		When a FK bound to some other model's PK, we might want to get its bound B_REST_Model instance (ex to get its label)
		We'll first check if this field is child of a parent model, so we might be able to get val directly (self > parent model > B_REST_ModelField_ModelLookupRef sibling)
		Or we'll check in the known cached shared models
		Yields NULL if the info isn't available
		Throws if field not set
		WARNING:
			When we use cache, we can't guarantee we'll end up with the req fields we wanted
		*/
		lookup_getModel()
		{
			if (!this._fieldDescriptor.lookup_is) { this._throwField(`Can only try to get lookup model on fields where lookup_modelName was defined`); }
			if (!this.isSet)                      { this._throwField(`Field not set`); }
			
			//Check if we can, via the parent model. Field must already be allocated
			const lookup_model = this._lookup_tryGetParentModelField(/*retModel*/true);
			if (lookup_model) { return lookup_model; }
			
			//Then check in cached shared models
			return B_REST_Model.cachedShare_get(this._fieldDescriptor.lookup_modelName, /*pkTag*/this._data); //Can ret NULL if not cached
		}
		//NOTE: In B_REST_Model::fromObj(), we always set lookups before FKs, so we can auto update the FK if it's not shared
		lookup_updateBoundField()
		{
			//Try to get the bound B_REST_ModelField_ModelLookupRef of the same parent model, if available
			const lookup_modelField = this._lookup_tryGetParentModelField(/*retModel*/false);
			if (!lookup_modelField) { return; } //Should never happen, since it's called when we have a lookup_fieldName
			
			if (!this.isSet) { lookup_modelField.model=null; return; }
			
			const lookup_currentPK = lookup_modelField.model?.pk ?? null;
			
			//If we've got a new PK that's diff from the one we had
			if (this._data!==lookup_currentPK)
			{
				const lookup_modelClassName = lookup_modelField._fieldDescriptor.modelClassName;
				
				//When shared, use cache
				if (lookup_modelField._fieldDescriptor.isShared)
				{
					//Check if we already have one cached
					const lookup_cachedModel = B_REST_Model.cachedShare_get(lookup_modelClassName, /*pkTag*/this._data);
					
					if (lookup_cachedModel) { lookup_modelField.model = lookup_cachedModel; }
					//When we don't have in cache, create an empty one now, if we want to
					else if (B_REST_Descriptor.MODEL_LOOKUP_REF_CREATE_EMPTY_CACHE)
					{
						B_REST_Utils.console_warn(`Updating bound lookup field to a new B_REST_Model instance, therefore no field won't be filled, and ::toLabel() will only show #<pk>. Consider booting a B_REST_ModelList with useCachedShare`);
						
						const lookup_newModel = B_REST_Model.commonDefs_make(lookup_modelClassName);
						lookup_newModel.pk    = this._data;
						
						B_REST_Model.cachedShare_put(lookup_newModel, B_REST_Descriptor.FROM_OBJ_OVERWRITE_CACHE);
						
						lookup_modelField.model = lookup_newModel;
					}
				}
				//Else, just rebuild the model in place, unless its PK was just not set (because we put data and we've just did a create call and waiting to put back data. Should never happen though)
				else if (lookup_currentPK)
				{
					B_REST_Utils.console_warn(`Updating bound lookup field to a new B_REST_Model instance, therefore no field won't be filled, and ::toLabel() will only show #<pk>. Consider booting a B_REST_ModelList with useCachedShare`);
					
					const lookup_newModel = B_REST_Model.commonDefs_make(lookup_modelClassName);
					lookup_newModel.pk    = this._data;
					
					lookup_modelField.model = lookup_newModel;
				}
			}
			
			//Else prevent endless loops
		}
			_lookup_tryGetParentModelField(retModel) //VS returning the B_REST_ModelField_ModelLookupRef
			{
				const lookup_fieldName = this._fieldDescriptor.lookup_fieldName;
				
				if (lookup_fieldName && this._parentModel?.select_isUsed(lookup_fieldName))
				{
					return this._parentModel.select(lookup_fieldName, retModel);
				}
				
				return null;
			}
	
	
	
	//VALIDATION RELATED
		get validation_type_errorMsg()    { return this._validation_type_errorMsg; }
		set validation_type_errorMsg(val) { this._validation_type_errorMsg=val;    }
		_abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors)
		{
			if (this._validation_type_errorMsg)
			{
				const self_error = detailed ? new B_REST_Model_ValidationError(this,this._validation_type_errorMsg) : this._validation_type_errorMsg;
				
				errors.push(self_error);
				if (onlyOne) { return errors; }
			}
			
			return errors;
		}
		/*
		Rets 0 to 4. Should allow between 3-4
		https://github.com/dropbox/zxcvbn
		npm install zxcvbn
		*/
		get validation_pwdStrengthLvl() { return B_REST_FieldDescriptors.DB.validation_getPwdStrengthLvl(this._data); }
		_abstract_isMutable_checkWhenIsExistingModelWithSetOnceLater() { return this._data===null; }
	
	
	
	//UNSAVED CHANGES RELATED
		get _abstract_unsavedChanges_has()       { return this._unsavedChanges_has; }
		_abstract_unsavedChanges_unflag(options) { this._unsavedChanges_has=false;  }
		_abstract_unsavedChanges_flag()          { this._unsavedChanges_has=true;   }
	
	
	
	//USER TOUCH RELATED
		get _abstract_userTouch_has()       { return this._userTouch_has;  }
		_abstract_userTouch_toggle(touched) { this._userTouch_has=touched; }
	
	
	
	//FROM / TO OBJ RELATED
		/*
		In most case for this kind of field, we expect primitives, but if it's a JSON field we might actually need an obj,
		so just pass it directly to the setter to trigger validation checks etc, except for mutability
		*/
		_abstract_fromObj(obj,skipIllegalChanges) { this._setVal(obj, !skipIllegalChanges&&B_REST_ModelField_DB.FROM_OBJ_ASSERT_MUTABLE, /*isFromConstructor*/false, /*ignoreIdenticals*/true); }
		/*
		For most fields, give exactly the way they are; for dates we'll let the server convert them
		For pwds, apply frontend hash
		If field val was undefined, it actually won't be added to output
		It's possible that it *could* ret NULL, but not supposed if B_REST_ModelField_DB::UNSET_VAL===null, since base class throws if !isSet
		*/
		_abstract_toObj(options)
		{
			if (this._fieldDescriptor.type_is_pwd) { return this.pwdVal_toFrontendHash(); }
			
			return this._data;
		}
	
	
	
	//MISC
		//NOTE: To cascade wipe w/o marking unsaved changes, so equal to unSet() and not clear() (for DB fields)
		_abstract_nullify() { this.unSet(); }
		
		get _abstract_hasAsyncTasks() { return false; }
		async _abstract_awaitAsyncTasks() {}
};






class B_REST_ModelField_ModelLookupRef extends B_REST_ModelField_WithFuncs_WithModels_base
{
	static get API_DIRECTIVE_REMOVE() { return "<remove>"; }
	static get API_DIRECTIVE_DELETE() { return "<delete>"; }
	
	
	constructor(fieldDescriptor, parentModel=null)
	{
		super(B_REST_FieldDescriptors.ModelLookupRef, fieldDescriptor, parentModel);
		
		//If we get here, it means something is trying to access this field, so we should prolly instanciate the lookup model (if not shared)
		if (!this._fieldDescriptor.isShared)
		{
			//use setter to automate things correctly
			this.model = B_REST_Model.commonDefs_make(this._fieldDescriptor.modelClassName);
		}
	}
	
	
	
	get model_has() { return !!this._data; }
	get model()
	{
		if (!this._data) { B_REST_Utils.console_warn(`B_REST_ModelField_base<${this.debugFieldNamePath()}>: Trying to access .model when it's NULL. Might cause probs in code. Consider using model_has first`); }
		
		return this._data;
	}
	//Make a real link to this field, only if it's not shared
	set model(model)
	{
		//Don't cause an infinite loop
		if (this._data===model) { return; }
		
		if (model===null)
		{
			if (!this._fieldDescriptor.isShared) { this._throwField(`For now, can't unset a B_REST_ModelField_ModelLookupRef that isn't shared`); }
			
			this._data = null;
			
			//Also check if we should unbound FK
			if (this._parentModel && this._fieldDescriptor.fk_dbFieldName)
			{
				const fkField = this._parentModel.select(this._fieldDescriptor.fk_dbFieldName);
				
				//Don't cause an infinite loop
				if (fkField.val!==null) { fkField.val=null; }
			}
		}
		else
		{
			if (!(model instanceof B_REST_Model))                             { this._throwField(`Expected a B_REST_Model of descriptor "${this._fieldDescriptor.modelClassName}`);                                  }
			if (model.descriptor.name!==this._fieldDescriptor.modelClassName) { this._throwField(`Expected a B_REST_Model of descriptor "${this._fieldDescriptor.modelClassName}"; got "${model.descriptor.name}"`); }
			
			if (!this._fieldDescriptor.isShared) { model.hostModelField = this; }
			
			this._data = model;
			
			//Also check if we should change the bound FK. WARNING: We should do that too when model gets its PK set for the 1st time, but we're not doing that yet
			if (this._parentModel && this._fieldDescriptor.fk_dbFieldName)
			{
				const fkField  = this._parentModel.select(this._fieldDescriptor.fk_dbFieldName);
				const lookupPK = this._data.pk;
				
				//Don't cause an infinite loop
				if (fkField.val!==lookupPK) { fkField.val=lookupPK; }
			}
		}
	}
	
	
	
	get _abstract_subModel_canBeMutable()
	{
		if (this._fieldDescriptor.isShared) { return this.isMutable; }
		
		/*
		WARNING:
			We might not want to be able to change -which- model we're referring to, but we still normally want to be able to alter its inner fields vals.
			However if the PARENT model is RO, then every nested field should turn RO as well, which is what we do here
			If we want to review behavior, make sure it doesn't break when we have subModel & private lookups where we need to be able to mutate inner fields.
		*/
		if (this._parentModel && !this._parentModel.isMutable) { return false; }
		
		return true;
	}
	
	
	
	//VALIDATION RELATED (If not shared)
		_abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors)
		{
			if (this._fieldDescriptor.isShared) { return []; }
			
			const self_errors = this._data.validation_getErrors(detailed,onlyOne,includeAsyncCustomErrors);
			if (self_errors.length>0) { errors.push(...self_errors); } //ES6 to concat faster than Array.concat()
			
			return errors;
		}
		_abstract_validation_custom_recalc_fast(recurseUp, recurseDown)
		{
			if (this._fieldDescriptor.isShared) { return; }
			
			this._data.validation_custom_recalc_fast(recurseUp,recurseDown);
		}
		async _abstract_validation_custom_recalc_wait(recurseUp, recurseDown)
		{
			if (this._fieldDescriptor.isShared) { return; }
			
			return this._data.validation_custom_recalc_wait(recurseUp,recurseDown);
		}
	
	
	
	//UNSAVED CHANGES RELATED (If not shared)
		get _abstract_unsavedChanges_has()
		{
			if (this._fieldDescriptor.isShared) { return false; }
			
			return this._data.unsavedChanges_has;
		}
		_abstract_unsavedChanges_unflag(options)
		{
			if (this._fieldDescriptor.isShared) { return; }
			
			this._data.unsavedChanges_unflagAllFields(options);
		}
		_abstract_unsavedChanges_flag()
		{
			if (this._fieldDescriptor.isShared) { return; }
			
			this._data.unsavedChanges_flagAllFields();
		}
	
	
	
	//USER TOUCH RELATED (If not shared)
		get _abstract_userTouch_has()
		{
			if (this._fieldDescriptor.isShared) { return false; }
			
			return this._data.userTouch_has;
		}
		_abstract_userTouch_toggle(touched)
		{
			if (this._fieldDescriptor.isShared) { return; }
			
			this._data.userTouch_toggleAllFields(touched);
		}
	
	
	
	//FROM / TO OBJ RELATED
		//If shared, then we'll put it in cache right away
		_abstract_fromObj(obj,skipIllegalChanges)
		{
			if (this._fieldDescriptor.isShared)
			{
				if (obj===null) { this._data=null; }
				else
				{
					const model = B_REST_Model.commonDefs_make(this._fieldDescriptor.modelClassName);
					model.fromObj(obj,skipIllegalChanges);
					
					if (model.pk_isSet) { B_REST_Model.cachedShare_put(model,B_REST_Descriptor.FROM_OBJ_OVERWRITE_CACHE); }
					
					this._data = model;
				}
			}
			else
			{
				//In !shared, model is always allocated, so if we get NULL, we'll just empty the inner fields
				this._data.fromObj(obj,skipIllegalChanges);
			}
		}
		_abstract_toObj(options)
		{
			//Check B_REST_Model::toObj() docs
			if (options.forAPICall)
			{
				/*
				If shared, skip by ret undefined (because otherwise we'd put "toRemove" directly on the shared model, so all usages would think it had to remove it.
					So if we wanted to get rid of the lookup, then we should unset the FK field instead
				NOTE:
					This used to be before the above if, but it had the consequence that B_REST_Model::cachedShare_put() doing fromObj(toObj()) wouldn't get lookup infos,
					and then B_REST_Model::toLabel() would always ret NULL when we were calling it on a sub field.
					Ex in SPAD, where we have the struct Citizen>MunicipalityStreet>Municipality, and MunicipalityStreet's toLabel was "municipality(name)+name":
						-If we F5 in a citizen form we could see the street's label correctly, doing citizen.select("municipalityStreet").toLabel()
						-If we started in citizen list and then open a form it would fail, because the list happened to load streets, but without their municipalities,
							so they were put in cache in B_REST_Model::cachedShare_put() w less info, and then when we would switch to some form and do
							citizen.select("municipalityStreet").toLabel() again, it would reuse the cache w no info and fuck.
				*/
				if (this._fieldDescriptor.isShared) { return undefined; }
				
				if (this._data.toRemove) { return B_REST_ModelField_ModelLookupRef.API_DIRECTIVE_REMOVE; }
				if (this._data.toDelete) { return B_REST_ModelField_ModelLookupRef.API_DIRECTIVE_DELETE; }
			}
			
			return this._data.toObj(options); //If we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
		}
	
	
	
	//MISC
		_abstract_nullify()
		{
			if (this._data) { this._data.fields_nullify_all(); }
		}
		
		get _abstract_hasAsyncTasks()
		{
			if (!this._data) { return false; }
			
			return this._data.isSaving || this._data.fields_haveAsyncTasks;
		}
		async _abstract_awaitAsyncTasks()
		{
			return this._data?.fields_awaitAsyncTasks() ?? null; //WARNING: Inconsistency here between _abstract_hasAsyncTasks() & _abstract_awaitAsyncTasks(); we don't take into account isSaving here
		}
};






class B_REST_ModelField_SubModel extends B_REST_ModelField_SubModel_base
{
	static get API_DIRECTIVE_REMOVE() { return "<remove>"; }
	static get API_DIRECTIVE_DELETE() { return "<delete>"; }
	
	
	constructor(fieldDescriptor, parentModel=null)
	{
		super(B_REST_FieldDescriptors.SubModel, fieldDescriptor, parentModel);
		
		//If we get here, it means something is trying to access this field, so we should prolly instanciate the sub model
		this._data = B_REST_Model.commonDefs_make(this._fieldDescriptor.modelClassName); //NOTE: If we want to change so we can allocate data later, will have to check if this._fkFieldVal is already known
		this._data.hostModelField = this;
		
		//Check notes in B_REST_ModelField_SubModel_base about not trying to set fkFieldVal here
	}
	
	
	
	get model_has() { return !!this._data; }
	get model()
	{
		if (!this._data) { B_REST_Utils.console_warn(`B_REST_ModelField_base<${this.debugFieldNamePath()}>: Trying to access .model when it's NULL. Might cause probs in code. Consider using model_has first`); }
		
		return this._data;
	}
	
	
	
	_abstract_fkFieldVal_onSet() { this._fkFieldVal_propagate(this._data); }
	
	
	
	//VALIDATION RELATED
		_abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors)
		{
			const self_errors = this._data.validation_getErrors(detailed,onlyOne,includeAsyncCustomErrors);
			if (self_errors.length>0) { errors.push(...self_errors); } //ES6 to concat faster than Array.concat()
			
			return errors;
		}
		_abstract_validation_custom_recalc_fast(recurseUp, recurseDown)       { this._data.validation_custom_recalc_fast(recurseUp,recurseDown);        }
		async _abstract_validation_custom_recalc_wait(recurseUp, recurseDown) { return this._data.validation_custom_recalc_wait(recurseUp,recurseDown); }
	
	
	
	//UNSAVED CHANGES RELATED
		get _abstract_unsavedChanges_has()       { return this._data.unsavedChanges_has;               }
		_abstract_unsavedChanges_unflag(options) { this._data.unsavedChanges_unflagAllFields(options); }
		_abstract_unsavedChanges_flag()          { this._data.unsavedChanges_flagAllFields();          }
	
	
	
	//USER TOUCH RELATED
		get _abstract_userTouch_has()       { return this._data.userTouch_has;               }
		_abstract_userTouch_toggle(touched) { this._data.userTouch_toggleAllFields(touched); }
	
	
	
	//FROM / TO OBJ RELATED
		_abstract_fromObj(obj,skipIllegalChanges) { this._data.fromObj(obj,skipIllegalChanges); }
		_abstract_toObj(options)
		{
			//Check B_REST_Model::toObj() docs
			if (options.forAPICall)
			{
				if (this._data.toRemove) { return B_REST_ModelField_ModelLookupRef.API_DIRECTIVE_REMOVE; }
				if (this._data.toDelete) { return B_REST_ModelField_ModelLookupRef.API_DIRECTIVE_DELETE; }
			}
			
			return this._data.toObj(options); //If we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
		}
	
	
	
	//MISC
		_abstract_nullify()
		{
			if (this._data) { this._data.fields_nullify_all(); }
		}
		
		get _abstract_hasAsyncTasks()
		{
			if (!this._data) { return false; }
			
			return this._data.isSaving || this._data.fields_haveAsyncTasks;
		}
		async _abstract_awaitAsyncTasks()
		{
			return this._data?.fields_awaitAsyncTasks() ?? null; //WARNING: Inconsistency here between _abstract_hasAsyncTasks() & _abstract_awaitAsyncTasks(); we don't take into account isSaving here
		}
};






class B_REST_ModelField_SubModelList extends B_REST_ModelField_SubModel_base
{
	static get FROM_OBJ_DESTROY_ALL() { return false; } //Let the user do it by himself, if req. Also, usually we do this on load, not after we've been using the instance for a while
	static get API_DIRECTIVE_REMOVE_ALL() { return "<removeAll>"; }
	static get API_DIRECTIVE_DELETE_ALL() { return "<deleteAll>"; }
	
	
	constructor(fieldDescriptor, parentModel=null)
	{
		super(B_REST_FieldDescriptors.SubModelList, fieldDescriptor, parentModel);
		
		//If we get here, it means something is trying to access this field, so we should instanciate the sub model list
		this._data = B_REST_ModelList.commonDefs_make_static(this._fieldDescriptor.modelClassName);
		
		/*
		Setup a hook, so everytime we add models, either from here or from calling this._data.load/nav() funcs (check B_REST_ModelList docs),
		we check to assign PK-FK and indicate to the host model, that this field has "more" changes to save
		*/
		this._data.hook_onAdd = (modelList, models) =>
		{
			//Do this, to catch JIT when ex we use this in a BrGenericListBase, that links directly to the B_REST_ModelList behind
			if (!this.isMutable) { this._throwField(`Can't add because field is immutable now`); }
			
			for (const loop_subModel of models)
			{
				//Check to assign PK-FK
				if (this._fkFieldVal) { this._fkFieldVal_propagate(loop_subModel); }
				
				loop_subModel.hostModelField = this;
			}
		};
		
		//Check notes in B_REST_ModelField_SubModel_base about not trying to set fkFieldVal here
	}
	
	
	
	//Overriding how it works in B_REST_ModelList, so the following only account for those not flagged as toRemove / toDelete
		get modelList() { return this._data;              }
		get subModels() { return this._data.models.filter(loop_subModel => !loop_subModel.toRemoveOrDelete); }
		get count()     { return this.subModels.length;   }
		get has()       { return this.subModels.length>0; }
		get_byIdx(idx)
		{
			const subModels = this.subModels; //Don't eval twice
			const count     = subModels.length;
			
			if (idx >= count) { this._throwField(`Index #${idx}/${count} out of bounds (non-marked as toRemove / toDelete`); }
			return subModels[idx];
		}
		//NOTE: For this one though, we won't filter out by not flagged toRemove / toDelete
		get_byPK(pkValOrFieldMap) { return this._data.get_byPK(pkValOrFieldMap); }
		//NOTE: Same here
		get_byFrontendUUID(frontendUUID)               { return this._data.get_byFrontendUUID(frontendUUID);               }
		get_byFieldNamePathVal(fieldNamePath,val)      { return this._data.get_byFieldNamePathVal(fieldNamePath,val);      }
		get_byFieldNamePathValMap(fieldNamePathValMap) { return this._data.get_byFieldNamePathValMap(fieldNamePathValMap); }
	//But we also have an alternative
		get subModels_includingToUnlinkOrDeleteOnes() { return this._data.models; }
	
	
	
	
	/*
	Either auto adds one, or specify one to add
	Thanks to doing this._data.hook_onAdd = function in constructor, adding here also triggers to correctly binding the sub model to the field itself
	Throws if immutable
	WARNING:
		If we directly access the B_REST_ModelList from external and either add stuff, manually push(), do reload/loadMore(), then the mutability check won't be done
	*/
	add(subModel=null)
	{
		if (!this.isMutable) { this._throwField(`Can't add because field is immutable now`); }
		return this._data.add(subModel);
	}
	//Like add, but at the beginning
	prepend(subModel=null)
	{
		if (!this.isMutable) { this._throwField(`Can't add because field is immutable now`); }
		return this._data.prepend(subModel);
	}
	/*
	This completely remove the sub model from the arr, without having toObj() / save() being able to know about it
	If we just wanted to flag it as toRemove or toDelete, then do subModel.toRemove=true or subModel.toDelete=true instead
	WARNING:
		-This doesn't make sure the passed sub model actually is part of this instance
		-Not affected by immutability
	*/
	destroy(subModel) { this._data.destroy(subModel); }
	destroy_all()     { this._data.destroy_all();     }
	//Check B_REST_Model::select_all() docs. Note that it'll just make sense for sub models that are already added, so might be incoherent if we add things later
	select_all()
	{
		for (const loop_subModel of this._data.models) { loop_subModel.select_all(); }
	}
	
	//Check B_REST_ModelList::sort_currentModels() docs; doesn't reload data; just sorts what we have now
	sort_currentSubModels(callback) { this._data.sort_currentModels(callback); }
	
	
	
	_abstract_fkFieldVal_onSet()
	{
		for (const loop_subModel of this._data.models) { this._fkFieldVal_propagate(loop_subModel); }
	}
	
	
	
	//VALIDATION RELATED
		//Ignore toRemove / toDelete ones
		_abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors)
		{
			for (const loop_subModel of this.subModels)
			{
				const loop_subModel_errors = loop_subModel.validation_getErrors(detailed,onlyOne,includeAsyncCustomErrors);
				
				if (loop_subModel_errors.length>0)
				{
					errors.push(...loop_subModel_errors); //ES6 to concat faster than Array.concat()
					if (onlyOne) { break; }
				}
			}
			
			return errors;
		}
		//Ignore toRemove / toDelete ones
		_abstract_validation_custom_recalc_fast(recurseUp, recurseDown)
		{
			for (const loop_subModel of this.subModels) { loop_subModel.validation_custom_recalc_fast(recurseUp,recurseDown); }
		}
		//Ignore toRemove / toDelete ones
		async _abstract_validation_custom_recalc_wait(recurseUp, recurseDown)
		{
			for (const loop_subModel of this.subModels) { await loop_subModel.validation_custom_recalc_wait(recurseUp,recurseDown); }
		}
	
	
	
	//UNSAVED CHANGES RELATED
		get _abstract_unsavedChanges_has() { return this._data.unsavedChanges_has; }
		//Like indicated in B_REST_Model::unsavedChanges_getFields(), doesn't unset toRemove / toDelete flag
		_abstract_unsavedChanges_unflag(options) { this._data.unsavedChanges_unflag_all(options); }
		_abstract_unsavedChanges_flag()          { this._data.unsavedChanges_flag_all();          }
		/*
		Same idea as in B_REST_Model::unsavedChanges_getFields()
		WARNING: Doesn't ret those to remove / delete
		*/
		unsavedChanges_getSubModels(ignoreToRemoveToDelete=true) { return this._data.unsavedChanges_getModels(ignoreToRemoveToDelete); }
	
	
	
	//USER TOUCH RELATED
		get _abstract_userTouch_has() { return this._data.userTouch_has; }
		//Ignore toRemove / toDelete ones
		_abstract_userTouch_toggle(touched) { this._data.userTouch_toggle_all(touched); }
		//Same idea as in B_REST_Model::userTouch_getFields()
		userTouch_getSubModels(ignoreToRemoveToDelete=true) { return this._data.userTouch_getModels(ignoreToRemoveToDelete); }
	
	
	
	//FROM / TO OBJ RELATED
		//For now, doing so we wipe out all previous sub models and recreate
		_abstract_fromObj(obj,skipIllegalChanges) { this._data.fromObj(obj,B_REST_ModelField_SubModelList.FROM_OBJ_DESTROY_ALL,skipIllegalChanges); }
		/*
		Each model can either output as an obj of all its used props, or obj like:
			{pk:456, _apiDirective_:"<remove>"}
			{pk:789, _apiDirective_:"<delete>"}
		For API directives, we also have the following, but it's annoying to use since they must all have the same tag:
			API_DIRECTIVE_REMOVE_ALL
			API_DIRECTIVE_DELETE_ALL
		If we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
		*/
		_abstract_toObj(options) { return this._data.toObj(options); }
	
	
	
	//MISC
		_abstract_nullify() { this.destroy_all(); }
		
		get _abstract_hasAsyncTasks()
		{
			for (const loop_subModel of this.subModels)
			{
				if (loop_subModel.isSaving || loop_subModel.fields_haveAsyncTasks) { return true; }
			}
			
			return false;
		}
		async _abstract_awaitAsyncTasks()
		{
			for (const loop_subModel of this.subModels) { await loop_subModel.fields_awaitAsyncTasks(); } //WARNING: Inconsistency here between _abstract_hasAsyncTasks() & _abstract_awaitAsyncTasks(); we don't take into account isSaving here
		}
};






class B_REST_ModelField_Other extends B_REST_ModelField_WithFuncs_base
{
	constructor(fieldDescriptor, parentModel=null) { super(B_REST_FieldDescriptors.Other,fieldDescriptor,parentModel); }
	
	
	
	
	//FOR NOW
		//ACCESSORS
			get data()    { return this._data; } //May ret undefined
			set data(val) { this._data=val;    }
		
		
		
		//VALIDATION RELATED
			_abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors) { return errors; }
		
		
		
		//UNSAVED CHANGES RELATED
			get _abstract_unsavedChanges_has() { return false; }
			_abstract_unsavedChanges_unflag(options) { }
			_abstract_unsavedChanges_flag() { }
		
		
		
		//USER TOUCH RELATED
			get _abstract_userTouch_has() { return false; }
			_abstract_userTouch_toggle(touched) { }
		
		
		
		//FROM / TO OBJ RELATED
			_abstract_fromObj(obj,skipIllegalChanges)
			{
				this._data = obj;
			}
			/*
			WARNING:
				Depending on data type, might not make sense. Was initially ret "undefined", but was confusing ex if in Model_User we had that kind of field,
				because it wouldn't show up in B_REST_App_base::user_ls_update() because we do B_REST_ModelField_Other::toObj()
			*/
			_abstract_toObj(options) { return this._data; }
		
		
		
		//MISC
			_abstract_nullify() { }
			
			get _abstract_hasAsyncTasks() { return false; }
			async _abstract_awaitAsyncTasks() {}
};






class B_REST_ModelField_File extends B_REST_ModelField_base
{
	static get AWAIT_DIE_ON_FAILED_TRANSFERS() { return true; }
	
	
	
	constructor(fieldDescriptor, parentModel=null)
	{
		super(B_REST_FieldDescriptors.File, fieldDescriptor, parentModel);
		
		this._data = new B_REST_FileControl({ifModelFiles_modelField:this});
	}
	
	
	
	//Alias to data
	get control() { return this._data; }
	//Helper for when it's a single file (and usually an img). Rets NULL if no file. Decide which behavior we want for when it's an img file
	get ifSingle_apiUrl_wDomainName()                        { return this._ifSingle_apiUrl_wDomainName_x("normal");                 }
	get ifSingle_apiUrl_wDomainName_resizedVersion()         { return this._ifSingle_apiUrl_wDomainName_x("resizedVersion");         }
	get ifSingle_apiUrl_wDomainName_resizedVersionOrNormal() { return this._ifSingle_apiUrl_wDomainName_x("resizedVersionOrNormal"); }
		_ifSingle_apiUrl_wDomainName_x(behavior)
		{
			if (this._fieldDescriptor.isMultiple) { this._throwField(`Can't do that on isMultiple files`); }
			
			if (!this._data.items_has) { return null; }
			const fileControlItem = this._data.items[0]; //Instance of B_REST_FileControlItem
			
			if (behavior!=="normal")
			{
				const resizedVersion_apiUrl_wDomainName = fileControlItem.ifStored_fileInfo_resizedVersion?.apiUrl_wDomainName ?? null; //NOTE: Even if ifStored_fileInfo_resizedVersion is set, apiUrl could be NULL; check its docs
				if      (resizedVersion_apiUrl_wDomainName) { return resizedVersion_apiUrl_wDomainName; }
				else if (behavior==="resizedVersion")       { return null;                              } //So if "resizedVersionOrNormal", will fall below
			}
			
			return fileControlItem.fileInfo.apiUrl_wDomainName;
		}
	
	
	_abstract_isMutable_checkWhenIsExistingModelWithSetOnceLater() { return true; } //For now we only care if B_REST_FieldDescriptors.DB
	
	
	
	//VALIDATION RELATED
		_abstract_validation_getErrors(errors, detailed, onlyOne, includeAsyncCustomErrors)
		{
			const self_errors = this._data.validation_getErrors(onlyOne,includeAsyncCustomErrors).map(loop_errorMsg => detailed?new B_REST_Model_ValidationError(this,loop_errorMsg):loop_errorMsg);
			
			errors.push(...self_errors);
			
			return errors;
		}
	
	
	
	//UNSAVED CHANGES RELATED
		get _abstract_unsavedChanges_has()       { return this._data.unsavedChanges_has;      }
		_abstract_unsavedChanges_unflag(options) { this._data.unsavedChanges_unflag(options); }
		_abstract_unsavedChanges_flag()          { this._data.unsavedChanges_flag();          }

	
	
	//USER TOUCH RELATED
		get _abstract_userTouch_has()       { return this._data.userTouch_has;      }
		_abstract_userTouch_toggle(touched) { this._data.userTouch_toggle(touched); }
	
	
	
	//FROM / TO OBJ RELATED
		_abstract_fromObj(obj,skipIllegalChanges)
		{
			if (obj===null) { return; } //NOTE: If we loaded model specifying we wanted to get files fields in the load options but we had no files yet, obj = NULL
			this._data.items_fromObj(obj);
		}
		_abstract_toObj(options) { return this._data.items_toObj(options); }
	
	
	
	//MISC
		_abstract_nullify() { }
		
		get _abstract_hasAsyncTasks() { return this._data.hasOngoingAsyncTasks; }
		async _abstract_awaitAsyncTasks() { return this._data.items_waitOngoingUploads(B_REST_ModelField_File.AWAIT_DIE_ON_FAILED_TRANSFERS); }
};






export default {
	base:                      B_REST_ModelField_base,
	WithFuncs_base:            B_REST_ModelField_WithFuncs_base,
	WithFuncs_WithModels_base: B_REST_ModelField_WithFuncs_WithModels_base,
	SubModel_base:             B_REST_ModelField_SubModel_base,
	DB:                        B_REST_ModelField_DB,
	ModelLookupRef:            B_REST_ModelField_ModelLookupRef,
	SubModel:                  B_REST_ModelField_SubModel,
	SubModelList:              B_REST_ModelField_SubModelList,
	Other:                     B_REST_ModelField_Other,
	File:                      B_REST_ModelField_File,
};
